import { Button } from '@/components/ui/button'

import { BankAccountTypes } from '../api/schema'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { InfoTooltip } from '../../components/info-tooltip'
import { capitalizeFirstLetter } from '@/utils/helpers'

export const BankAccountCard = ({
  account,
  handleDelete,
  investment_account_uuid,
}: {
  account: BankAccountTypes
  handleDelete: (uuid: string) => void
  investment_account_uuid?: string
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [showDialogMobile, setShowDialogMobile] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const nav = useNavigate()

  return isDeleting ? (
    <div className="w-full rounded border border-[#E7E7E7] flex justify-between items-center text-base">
      <div className="p-4 inline-flex gap-3 w-[25%]">
        <Skeleton className="h-6 w-full" />
      </div>
      <div className="p-4 w-[15%] capitalize">
        <Skeleton className="h-4 w-full" />
      </div>
      <div className="p-4 w-[45%] flex justify-end">
        <Skeleton className="h-4 w-full" />
      </div>
    </div>
  ) : (
    <div
      key={account.uuid}
      className="w-full rounded border border-[#E7E7E7] text-base"
    >
      <div className="hidden w-full md:flex justify-between items-center">
        <div className="p-4 inline-flex gap-3 w-[25%]">
          <div className="w-5 h-4 flex justify-center text-16">
            <i className="fa-light fa-bank" />
          </div>
          <div className="font-medium line-clamp-1">
            {account.account_name ? account.account_name : 'Bank Account'}
          </div>
        </div>
        <div className="p-4 w-[35%] line-clamp-1 first-letter:capitalize">
          {account.account_type && account.mask
            ? `${capitalizeFirstLetter(account.account_type)} - *${account.mask}`
            : account.account_description}
        </div>
        <div className="p-4 w-[35%] flex items-center justify-end">
          {account.number_of_associated_distribution_investments > 0 ? (
            <span>
              {account.number_of_associated_distribution_investments}{' '}
              {account.number_of_associated_distribution_investments > 1
                ? 'investments'
                : 'investment'}{' '}
              receiving{' '}
              <span
                className="underline cursor-pointer"
                onClick={() =>
                  nav(
                    `/dashboard/bank-accounts/distributions?investment_account_uuid=${investment_account_uuid}`
                  )
                }
              >
                distributions
              </span>
            </span>
          ) : account.is_funding_in_progress_on_investment ? (
            'Investment pending'
          ) : (
            <span className="text-content-light">No Investments</span>
          )}
          {account.is_funding_in_progress_on_investment && (
            <InfoTooltip className="ml-1">
              <div className="text-base font-medium mb-3">
                This account can't be removed.
              </div>
              <div>
                This bank account is currently in use for one or more pending
                investments.
              </div>
            </InfoTooltip>
          )}
        </div>
        <div className="p-4 w-fit flex justify-end">
          <Dialog open={showDialog} onOpenChange={setShowDialog}>
            <DialogTrigger asChild>
              <Button
                variant="RMSecondary"
                size="RM"
                disabled={account.is_funding_in_progress_on_investment}
              >
                Remove
              </Button>
            </DialogTrigger>
            <DialogContent
              aria-describedby={undefined}
              className="max-w-[600px] p-10"
              showX={false}
            >
              <DialogTitle className="text-content-black text-left font-medium text-[22px]">
                Remove Bank Account
              </DialogTitle>
              <div aria-describedby="">
                {account.number_of_associated_distribution_investments ===
                  0 && (
                  <div className="px-4 py-3 bg-bg-light w-full flex flex-col gap-2 mt-4 mb-8">
                    <div className="text-base text-content-black font-medium">
                      {account.account_name} - {account.account_description}
                    </div>
                    <div>No investments</div>
                  </div>
                )}
                <div className="text-[#202020] text-16">
                  {account.number_of_associated_distribution_investments ===
                  0 ? (
                    `This bank account will be removed from your RealtyMogul.com
                account. If you'd like to link this bank account to your
                RealtyMogul.com account again, you can do so at any time.`
                  ) : (
                    <span>
                      <span className="font-medium">
                        This bank account is receiving distributions for{' '}
                        {account.number_of_associated_distribution_investments}{' '}
                        investments.
                      </span>{' '}
                      Please reassign the investments in your distribution
                      settings first before removing this bank account.
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-2 flex gap-4">
                <Button
                  variant="RM"
                  size="RM"
                  onClick={() => {
                    if (
                      account.number_of_associated_distribution_investments ===
                      0
                    ) {
                      setShowDialog(false)
                      setIsDeleting(true)
                      handleDelete(account.uuid)
                    } else {
                      nav('/dashboard/bank-accounts/distributions')
                    }
                  }}
                  data-cy="remove-bank-account-button"
                >
                  {account.number_of_associated_distribution_investments === 0
                    ? 'Remove Bank Account'
                    : 'Distribution Settings'}
                </Button>
                <DialogClose asChild>
                  <Button variant="RMSecondary" size="RMSecondary">
                    Cancel
                  </Button>
                </DialogClose>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="md:hidden w-full flex flex-row px-2 py-3 gap-2">
        <div className="px-2">
          <i className="fa-light fa-bank" />
        </div>
        <div className="flex-grow flex flex-col gap-1">
          <div className="font-medium">{account.account_name}</div>
          <div>
            {account.mask
              ? `${account.account_type} - *${account.mask}`
              : account.account_description}
          </div>
          <div className="text-sm">
            {account.number_of_associated_distribution_investments > 0 ? (
              <span>
                {account.number_of_associated_distribution_investments}{' '}
                {account.number_of_associated_distribution_investments > 1
                  ? 'investments'
                  : 'investment'}{' '}
                receiving{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={() =>
                    nav(
                      `/dashboard/bank-accounts/distributions?investment_account_uuid=${investment_account_uuid}`
                    )
                  }
                >
                  distributions
                </span>
              </span>
            ) : account.is_funding_in_progress_on_investment ? (
              'Investment pending'
            ) : (
              <span className="text-content-light">No Investments</span>
            )}
            {account.is_funding_in_progress_on_investment && (
              <InfoTooltip className="ml-1">
                <div className="text-base font-medium mb-3">
                  This account can't be removed.
                </div>
                <div>
                  This bank account is currently in use for one or more pending
                  investments.
                </div>
              </InfoTooltip>
            )}
          </div>
        </div>
        <Popover>
          <PopoverTrigger>
            <i
              className="px-6 py-4 fa-solid fa-ellipsis-vertical"
              data-cy="menu-trigger"
            ></i>
          </PopoverTrigger>
          <PopoverContent
            side="bottom"
            align="end"
            sideOffset={-55}
            avoidCollisions={false}
            className="w-[155px] text-base p-4"
          >
            <Dialog open={showDialogMobile} onOpenChange={setShowDialogMobile}>
              <DialogTrigger>Remove</DialogTrigger>
              <DialogContent className="w-screen h-screen p-6" showX={false}>
                <div className="flex flex-col gap-8">
                  <DialogTitle className="text-content-black text-left text-2xl font-bold">
                    Remove Bank Account
                  </DialogTitle>
                  <div>
                    {account.number_of_associated_distribution_investments ===
                      0 && (
                      <div className="px-4 py-3 bg-bg-light w-full flex flex-col gap-2 mt-4 mb-8">
                        <div className="text-base text-content-black font-medium">
                          {account.account_name} - {account.account_description}
                        </div>
                        <div>No investments</div>
                      </div>
                    )}
                    <div className="text-[#202020] text-16">
                      {account.number_of_associated_distribution_investments ===
                      0 ? (
                        `This bank account will be removed from your RealtyMogul.com account. If you'd like to link this bank account to your RealtyMogul.com account again, you can do so at any time.`
                      ) : (
                        <span>
                          <span className="font-medium">
                            This bank account is receiving distributions for{' '}
                            {
                              account.number_of_associated_distribution_investments
                            }{' '}
                            investments.
                          </span>{' '}
                          Please reassign the investments in your distribution
                          settings first before removing this bank account.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-2 flex flex-col gap-4">
                    <Button
                      variant="RM"
                      size="RM"
                      onClick={() => {
                        if (
                          account.number_of_associated_distribution_investments ===
                          0
                        ) {
                          setShowDialog(false)
                          setIsDeleting(true)
                          handleDelete(account.uuid)
                        } else {
                          nav('/dashboard/bank-accounts/distributions')
                        }
                      }}
                      data-cy="remove-bank-account-button-mobile"
                    >
                      {account.number_of_associated_distribution_investments ===
                      0
                        ? 'Remove Bank Account'
                        : 'Distribution Settings'}
                    </Button>
                    <Button
                      variant="RMSecondary"
                      size="RMSecondary"
                      onClick={() => setShowDialogMobile(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}
