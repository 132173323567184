import { useCallback, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link'
import { useQueryClient } from '@tanstack/react-query'

import { api, getAPIUrl } from '@/routes/shared/_api'

import { Button } from '@/components/ui/button'
import { useToast } from '@/hooks/use-toast'
import { useBankAccounts } from './api/get-bank-accounts'
import { Filter } from './components/filter'
import { useMobileNavStore } from '../api/use-mobile-nav-store'
import { cn } from '@/utils/utils'

const LinkNewBankAccount = () => {
  const nav = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: bankAccountsData } = useBankAccounts()
  const queryClient = useQueryClient()
  const [token, setToken] = useState<string>('')
  const { toast } = useToast()
  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)

  useEffect(() => updateMobileNav(false), [updateMobileNav])

  const onSuccess = (
    publicToken: string,
    account: PlaidLinkOnSuccessMetadata
  ) => {
    console.log(account)
    api
      .post(`${getAPIUrl()}dashboard/bank-accounts/plaid/public-token`, {
        public_token: publicToken,
        investment_account_uuid: new URLSearchParams(
          document.location.search
        ).get('investment_account_uuid'),
      })
      .then(() => {
        toast({
          variant: 'success',
          description: 'Bank account added successfully',
          position: 'top',
        })
        nav('/dashboard/bank-accounts')
        queryClient.invalidateQueries(['bank-accounts'])
        queryClient.invalidateQueries([
          'distribution-bank-accounts',
          searchParams.get('investment_account_uuid'),
        ])
      })
  }

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
  }

  const { open, ready } = usePlaidLink(config)

  const createLinkToken = useCallback(async () => {
    // For OAuth, use previously generated Link token
    if (window.location.href.includes('?oauth_state_id=')) {
      const linkToken = localStorage.getItem('link_token') || ''
      setToken(linkToken)
    } else {
      const user = await Auth.currentSession()
      const jwt = user.getIdToken().getJwtToken()

      const options = {
        method: 'GET',
        data: { noRedirectUri: true },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }

      fetch(`${getAPIUrl()}dashboard/bank-accounts/plaid/link-token`, options)
        .then((resp) => resp.json())
        .then((data) => {
          setToken(data.link_token)
          localStorage.setItem('link_token', data.link_token)
        })
        .catch((err) => {
          toast({
            variant: 'error',
            description: err.message
              ? err.message
              : 'Error while linking bank account',
            position: 'top',
          })
        })
    }
  }, [setToken, toast])

  useEffect(() => {
    if (!token && !window.Cypress) {
      createLinkToken()
    }
  }, [token, createLinkToken])

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (ready) open()
  }

  return (
    bankAccountsData && (
      <div className="w-full">
        <div
          className=" h-10 px-6 justify-start bg-bg-lighter items-center gap-3 flex md:hidden"
          onClick={() => {
            updateMobileNav(true)
            nav('/dashboard/bank-accounts')
          }}
        >
          <button className="h-4 flex-col justify-center items-center inline-flex">
            <i className="fa-solid fa-angle-left" />
          </button>
          <div className="text-base mt-[2px]">Bank Accounts</div>
        </div>
        <div className="w-full h-full p-6 md:p-0">
          <div className="hidden md:inline-block text-[13px] mb-10">
            <button
              className="text-primary"
              onClick={() => nav('/dashboard/bank-accounts')}
            >
              Bank Accounts
            </button>
            <i className="fa-light fa-angle-right mx-2" />
            Link New Bank Account
          </div>
          <h1 className="text-27 md:text-31 font-bold self-start mb-10">
            Link new Bank Account
          </h1>
          <div className="mb-10">
            Bank accounts are used to fund your investments.
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-0 md:items-center mb-6">
            <span className="mr-2">Link bank account for: </span>
            <Filter
              name="ownership_types"
              updateCallback={(newValue: string) => {
                const newSearchParams = new URLSearchParams(searchParams)
                if (newValue === 'all')
                  newSearchParams.delete('investment_account_uuid')
                else newSearchParams.set('investment_account_uuid', newValue)
                setSearchParams(newSearchParams)
              }}
              data={bankAccountsData.map((item) => {
                return {
                  name: item?.investment_account_name,
                  uuid: item?.investment_account_uuid,
                }
              })}
              defaultValue={
                searchParams.get('investment_account_uuid') || 'all'
              }
            />
          </div>
          <div
            className={cn(
              'relative bg-bg-lighter mb-6 p-4 xl:p-6 max-w-[500px]',
              !searchParams.get('investment_account_uuid') &&
                'opacity-60 pointer-events-none'
            )}
          >
            <div className="mb-6 flex items-center justify-between">
              <h2 className="max-w-[66%] text-16 font-bold text-content-black">
                Connect Your Bank Account
              </h2>
              <span className="h-[27px mr-2 w-[72px]">
                <img src="/plaid.svg" />
              </span>
            </div>
            <p className="mb-6 text-content-black text-base">
              We use our trusted partner, Plaid, the industry standard for
              connecting your bank account as a funding source.
            </p>
            <div className="flex flex-col md:flex-row gap-4 mb-6">
              <Button
                variant="RM"
                size="RM"
                disabled={!ready}
                onClick={handleClick}
              >
                Connect to Bank Account
              </Button>
              <Link to="/dashboard/bank-accounts">
                <Button
                  variant="RMSecondary"
                  size="RM"
                  onClick={() => updateMobileNav(true)}
                >
                  Cancel
                </Button>
              </Link>
            </div>
            <p className="mb-6 text-base text-content-black">
              <span className="font-medium">
                If you are unable to connect or locate your bank
              </span>
              , or do not wish to provide your bank login, you can{' '}
              <span
                className="cursor-pointer text-primary hover:text-primary-hover"
                data-cy="manual-link"
                onClick={(e) => {
                  e.preventDefault()
                  nav(`${searchParams.get('investment_account_uuid')}/manual`)
                }}
              >
                add your bank account manually
              </span>
              .
            </p>
          </div>
        </div>
      </div>
    )
  )
}

export default LinkNewBankAccount
