import { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

interface CheckboxProps {
  name: string
  errorMessage?: string | FieldError
  disabled?: boolean
  checked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: () => void
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    return (
      <div className="relative h-5 w-5">
        <input
          className="peer appearance-none before:content[''] before:peer rounded overflow-hidden bg-transparent text-base before:block before:h-5 before:w-5"
          type="checkbox"
          id={props.name}
          ref={ref}
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
        <div className="absolute top-0 left-0 peer-hover:block md:peer-hover:hidden">
          <i
            className="fa-light fa-square text-[22px]"
            style={{ color: '#202020' }}
          />
        </div>
        <div className="absolute hidden top-0 left-0 peer-checked:block">
          <i
            className="fa-solid fa-square-check text-[22px]"
            style={{ color: '#1D89FF' }}
          />
        </div>
        <div className="absolute hidden top-0 left-0 md:peer-hover:block">
          <i
            className="fa-light fa-square text-[22px]"
            style={{ color: '#1D89FF' }}
          />
        </div>
        <div className="absolute hidden top-0 left-0 peer-hover:peer-checked:block">
          <i
            className="fa-solid fa-square-check text-[22px]"
            style={{ color: '#0070EB' }}
          />
        </div>
      </div>
    )
  }
)

export default Checkbox
