import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
// import { useTranslation } from 'react-i18next'

import { cancelInvestment } from '@/routes/shared/_api'
import { track } from '@/utils/analytics'
import { useInvestDataState } from '../_investDataState'
import {
  useConcentrationDataState,
  useMutateConcentration,
} from '../_concentrationDataState'
import { useRoute } from '@/rules/find-route'

import FormFooter from '../../shared/FormFooter'
import Loader from '@/components/Loader'
import Radio from '@/components/Radio'
import Stepper from '@/components/Stepper'
import Sidebar from '../Sidebar'

const ConcentrationFollowUpScreen = () => {
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: concentrationData } = useConcentrationDataState()
  const { mutateAsync: mutateConcentration } = useMutateConcentration()
  // const { t } = useTranslation()
  const { findRoute } = useRoute()

  type FormTypes = {
    concentrationQuestion?: 'Yes' | 'No'
  }

  const { control, handleSubmit } = useForm<FormTypes>({
    defaultValues: {
      concentrationQuestion: 'Yes',
    },
  })

  const onSubmit = handleSubmit((data) => {
    if (data.concentrationQuestion === 'No') {
      mutateConcentration({
        investment_uuid: investData?.investment.id,
        concentration: true,
        concentration_follow_up: false,
      }).then(() => {
        cancelInvestment(investData.investment?.id).then(() => {
          track('Cancel Investment')
          window.location.href = 'https://www.realtymogul.com'
        })
      })
      return
    }
    mutateConcentration({
      investment_uuid: investData?.investment.id,
      concentration: true,
      concentration_follow_up: true,
    }).then(() => {
      findRoute({
        ...investData,
        investment_state: {
          ...investData?.investment_state,
          concentration: 'submitted',
        },
      }).then((route) => {
        nav(`/invest/${investData.opportunity.id}/${route.type}`)
      })
    })
  })

  return concentrationData ? (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={1} currentStep={8} totalStepCount={10} />
      ) : (
        <Stepper currentSection={1} currentStep={7} totalStepCount={9} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            Concentration Risk
          </h1>
          <p className="mb-6 text-16 text-content-black">
            {concentrationData.concentration_question_text}
          </p>
          <form onSubmit={onSubmit}>
            <Controller
              name="concentrationQuestion"
              control={control}
              render={({ field }) => (
                <div className="mb-6 flex flex-col gap-px">
                  <Radio
                    {...field}
                    value="Yes"
                    label="Yes"
                    checked={field.value === 'Yes'}
                  />
                  <Radio
                    {...field}
                    value="No"
                    label="No, please cancel my investment"
                    checked={field.value === 'No'}
                  />
                </div>
              )}
            />
            <FormFooter submitFunc={onSubmit} />
          </form>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  ) : (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={1} currentStep={8} totalStepCount={10} />
      ) : (
        <Stepper currentSection={1} currentStep={7} totalStepCount={9} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <div className="w-full pt-16 flex justify-center items-center">
            <Loader />
          </div>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default ConcentrationFollowUpScreen
