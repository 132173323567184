import { useState, useEffect, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link'
import { Auth } from '@aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import {
  useBankAccountsDataState,
  useMutatePlaidToken,
  useMutateUpdateWithBankAccount,
} from '../_bankAccountsDataState'
import { getPlaidEndpoint } from '../../shared/_api'
import { BankAccountTypes } from '../../shared/_types'

import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'
import { useRoute } from '../../../rules/find-route'

interface FormType {
  bankAccount: string
}

const DistributionScreen = () => {
  const [token, setToken] = useState<string>('')
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: bankAccountsData } = useBankAccountsDataState()
  const { mutateAsync: mutatePlaidToken } = useMutatePlaidToken()
  const bankAccountMutation = useMutateUpdateWithBankAccount()
  const { t } = useTranslation()
  const { findRoute } = useRoute()

  const PLAID_API_ENDPOINT = getPlaidEndpoint(investData?.investment?.id)

  const { handleSubmit, control, watch } = useForm<FormType>({
    defaultValues: {
      bankAccount: '',
    },
  })

  const watchBankAccount = watch('bankAccount')

  const submitBankAccount = async (bankAccount: string) => {
    bankAccountMutation
      .mutateAsync({
        distribution_bank_account_uuid: bankAccount,
        funding_status: 'wire_pending',
      })
      .then(() =>
        findRoute({
          ...investData,
          investment_state: {
            ...investData.investment_state,
            funding: 'submitted',
            review: 'required',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      )
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  }

  const onSuccess = (
    publicToken: string,
    _account: PlaidLinkOnSuccessMetadata
  ) => {
    mutatePlaidToken({
      public_token: publicToken,
    }).then((resp) => {
      submitBankAccount(resp.bank_account_uuids[0])
    })
  }

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
  }

  const { open, ready } = usePlaidLink(config)

  const createLinkToken = useCallback(async () => {
    // For OAuth, use previously generated Link token
    if (window.location.href.includes('?oauth_state_id=')) {
      const linkToken = localStorage.getItem('link_token') || ''
      setToken(linkToken)
    } else {
      const user = await Auth.currentSession()
      const jwt = user.getIdToken().getJwtToken()

      const options = {
        method: 'GET',
        data: { noRedirectUri: true },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }

      fetch(`${PLAID_API_ENDPOINT}`, options)
        .then((resp) => resp.json())
        .then((data) => {
          setToken(data.plaid_link_token)
          localStorage.setItem('link_token', data.plaid_link_token)
        })
        .catch((error) => {
          console.error(error.message)
          setServerError(error.message)
        })
    }
  }, [setToken, PLAID_API_ENDPOINT])

  const onSubmit = handleSubmit(async (data: FormType) => {
    submitBankAccount(data.bankAccount)
  })

  useEffect(() => {
    if (!token && !window.Cypress) {
      createLinkToken()
    }
  }, [token, createLinkToken])

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (ready) open()
  }

  return (
    investData &&
    bankAccountsData && (
      <div className="p-6 xl:p-0">
        {investData.opportunity.io_type === 'reit' ? (
          <Stepper currentSection={2} currentStep={1} totalStepCount={3} />
        ) : (
          <Stepper currentSection={2} currentStep={1} totalStepCount={5} />
        )}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <div>
              <h1 className="mb-2 text-2xl font-bold text-content-black">
                {t('funding:fundYourInvestment')}
              </h1>
              <p className="mb-6 italic">{t('funding:wireTransfer')}</p>
              <p className="mb-6 text-16 text-content-black">
                {t('funding:confirmOrAdd')}
              </p>
              {bankAccountsData ? (
                <>
                  <div className="mb-6 xl:">
                    {bankAccountsData?.bank_account_list && (
                      <>
                        <p className="mb-6 text-16 text-content-black">
                          <Trans i18nKey="funding:selectBankAccount" />
                        </p>
                        {bankAccountsData?.bank_account_list.length > 0 ? (
                          <Controller
                            name="bankAccount"
                            control={control}
                            rules={{ required: 'is required' }}
                            render={({ field }) => (
                              <div className="flex flex-col mb-6 gap-px">
                                {bankAccountsData?.bank_account_list.map(
                                  (item: BankAccountTypes, index: number) => (
                                    <Radio
                                      {...field}
                                      key={index}
                                      value={item.account_uuid}
                                      checked={
                                        field.value === item.account_uuid
                                      }
                                      label={
                                        <div>
                                          <p className="font-bold capitalize mb-px">
                                            {item.account_description ||
                                              item.account_name}
                                          </p>
                                          <p className="capitalize mb-px">
                                            {item.account_type}
                                          </p>
                                        </div>
                                      }
                                    />
                                  )
                                )}
                              </div>
                            )}
                          />
                        ) : (
                          <div className="">
                            No bank accounts found. Please add a bank account
                            below.
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="mb-6 relative bg-bg-lighter p-4 xl:p-6">
                    <div className="mb-6 flex items-center justify-between">
                      <h2 className="max-w-[66%] text-16 font-bold text-content-black">
                        {t('funding:connectBankAccount')}
                      </h2>
                      <span className="h-[27px mr-2 w-[72px]">
                        <img src="/plaid.svg" />
                      </span>
                    </div>
                    <p className="mb-6 text-16 text-content-black">
                      {t('funding:plaidTrusted')}
                    </p>
                    <button
                      className="mb-6 h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:bg-slate-200 disabled:text-content-black"
                      onClick={handleClick}
                      disabled={!ready}
                    >
                      {t('funding:connectToBank')}
                    </button>
                    <p className="mb-6 text-16 text-content-black">
                      <Trans i18nKey="funding:manualCopy" /> or do not wish to
                      provide your bank login, you can{' '}
                      <span
                        className="cursor-pointer text-primary hover:text-primary-hover"
                        onClick={(e) => {
                          e.preventDefault()
                          nav(
                            `/invest/${investData.opportunity.id}/funding/ach`
                          )
                        }}
                      >
                        add your bank account manually.
                      </span>
                    </p>
                  </div>
                  <FormFooter
                    submitFunc={onSubmit}
                    disabled={!watchBankAccount}
                  />
                  {serverError && (
                    <ServerError
                      serverError={serverError}
                      id={investData?.investment?.id}
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className="hidden h-full flex-grow xl:block">
            <Sidebar />
          </div>
        </div>
      </div>
    )
  )
}

export default DistributionScreen
