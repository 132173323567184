import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { api } from '@/routes/shared/_api'
import { investmentSchema, InvestmentsDataTypes } from './api/schema'

import { DetailsDocuments } from './components/details-documents'
import { DetailsHistory } from './components/details-history'
import { DetailsSettings } from './components/details-settings'
import { DetailsSidebar } from './components/details-sidebar'
import { DetailsSubNav } from './components/details-subnav'
import { EmptyState } from '../components/empty-state'
import { InvestmentStatistics } from './components/details-statistics'
import Loader from '@/components/Loader'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

const getInvestmentDetails = async (uuid?: string) => {
  const response: InvestmentsDataTypes = await api.get(
    `dashboard/investments?status=Active and completed&io_uuid=${uuid}`
  )
  return investmentSchema.parse(response.investments[0])
}

export const InvestmentDetails = () => {
  const [screen, setScreen] = useState('')
  const { uuid } = useParams()
  const nav = useNavigate()
  const { state } = useLocation()
  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  useEffect(() => updateMobileNav(false), [updateMobileNav])

  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ['investment-details', uuid],
    queryFn: () => getInvestmentDetails(uuid),
    staleTime: Infinity,
  })

  useEffect(() => {
    if (!data) return
    setScreen(data.status.toLowerCase() === 'active' ? 'settings' : 'history')
  }, [data])

  return data ? (
    <>
      <div
        className="h-10 px-6 justify-start bg-bg-lighter items-center gap-3 flex md:hidden"
        onClick={() => {
          nav(
            state?.previousLocation ??
              `/dashboard/investments/${data.status.toLowerCase()}`
          )
        }}
      >
        <button className="h-4 flex-col justify-center items-center inline-flex">
          <i className="fa-solid fa-angle-left" />
        </button>
        <div className="text-base mt-[2px]">Investments</div>
      </div>
      <div className="w-full h-full p-6 md:p-0">
        <div className="hidden md:inline-block text-[13px] mb-10">
          <button
            className="text-primary"
            onClick={() => {
              nav(
                state?.previousLocation ??
                  `/dashboard/investments/${data.status.toLowerCase()}`
              )
            }}
          >
            Investments
          </button>
          <i className="fa-light fa-angle-right mx-2" />
          {data.name}
        </div>
        <div className="w-full flex flex-col md:flex-row justify-between gap-10 mb-10">
          <div className="w-full">
            {data.property_type !== 'REIT' && (
              <div className="mb-2 text-16">{data.property_type}</div>
            )}
            <h1 className="text-27 md:text-31 font-bold self-start mb-2">
              {data.name}
            </h1>
            <div className="mb-10 text-16 font-medium">{data.location}</div>
            <InvestmentStatistics data={data} />
          </div>
          <DetailsSidebar data={data} />
        </div>
        <div className="w-full block">
          <div className="my-10">
            <DetailsSubNav
              activeCategory={screen}
              callback={(screen: string) => setScreen(screen)}
              isCompleted={data.status.toLowerCase() === 'completed'}
            />
          </div>
          {screen === 'settings' && <DetailsSettings data={data} />}
          {screen === 'history' && (
            <div className="max-w-[550px]">
              <DetailsHistory
                data={data}
                hasNewMessage={(data.unread_messages ?? 0) > 0}
              />
            </div>
          )}
          {screen === 'signed' && <DetailsDocuments data={data} />}
        </div>
      </div>
    </>
  ) : (
    <div className="w-full h-[50dvh] mb-10 flex justify-center items-center">
      {(isLoading || isFetching) && <Loader />}
      {isError && (
        <EmptyState
          iconClasses="fa-regular fa-bomb fa-3x text-content-black"
          copy="Could not find investment."
          linkCopy="Browse Current Investments"
          linkTo="https://www.realtymogul.com/investment-opportunities"
        />
      )}
    </div>
  )
}

export default InvestmentDetails
