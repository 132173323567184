import { useLocation } from 'react-router-dom'
import Footer from '../shared/Footer'
import { api, getDrupalUrl } from '../shared/_api'
import { useToast } from '@/hooks/use-toast'

export const EmailUnverified = () => {
  const location = useLocation()
  const { toast } = useToast()

  const handleClick = async () => {
    api
      .get(
        `${getDrupalUrl()}api/v1.0/user/resend-verification?destination=${location.pathname}`
      )
      .then(() => {
        toast({
          variant: 'success',
          description: 'A verification email has been sent.',
          position: 'top',
        })
      })
  }

  return (
    <div className="flex h-full w-full flex-col items-center">
      <div className="w-full">
        <div className="max-w-[1400px] w-full bg-white px-6 py-4 mx-auto">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <img className="mr-4" src="/rm.svg" />
              <span className="text-content-dark text-16 font-medium uppercase tracking-[0.15em]">
                RealtyMogul
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6 xl:p-[60px] h-[60vh] w-full xl:max-w-[1000px] text-content-black">
        <h1 className="text-[30px] mb-6 font-bold">
          Please verify your email address.
        </h1>
        <p className="mb-6">
          To continue with this investment, please verify your email address.
        </p>
        <button
          onClick={handleClick}
          className="flex items-center h-10 w-full md:w-fit justify-center rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] md:min-w-[200px]"
        >
          Send Verification Email
        </button>
      </div>
      <div className="w-full bg-black">
        <Footer />
      </div>
    </div>
  )
}
