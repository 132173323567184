import { useState, useEffect, useCallback } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link'
import { Auth } from '@aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import { Trans } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import {
  useBankAccountsDataState,
  useMutatePlaidToken,
  useMutateUpdateWithBankAccount,
} from '../_bankAccountsDataState'
import { getPlaidEndpoint } from '../../shared/_api'
import { BankAccountTypes } from '../../shared/_types'

import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Loader from '../../../components/Loader'
import { useTenThirtyOneDataState } from '../_tenThirtyOneDataState'
import { useRoute } from '../../../rules/find-route'

interface FormType {
  bankAccount: string
}

const FundingScreen = () => {
  const [token, setToken] = useState<string>('')
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: bankAccountsData } = useBankAccountsDataState()
  const { data: tenThirtyOneData } = useTenThirtyOneDataState()
  const { mutateAsync: mutatePlaidToken } = useMutatePlaidToken()
  const { isLoading, mutateAsync: bankAccountMutation } =
    useMutateUpdateWithBankAccount()
  const { findRoute } = useRoute()

  const PLAID_API_ENDPOINT = getPlaidEndpoint(investData?.investment?.id)

  const { handleSubmit, control } = useForm<FormType>({
    defaultValues: {
      bankAccount: '',
    },
  })

  const submitBankAccount = async (bankAccount: string) => {
    bankAccountMutation({
      funding_bank_account_uuid: bankAccount,
      distribution_bank_account_uuid: bankAccount,
      funding_status: 'ach_authorized',
    })
      .then(() =>
        findRoute({
          ...investData,
          investment_state: {
            ...investData.investment_state,
            funding: 'submitted',
            review: 'required',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      )
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  }

  const onSuccess = (
    publicToken: string,
    account: PlaidLinkOnSuccessMetadata
  ) => {
    console.log(account)
    mutatePlaidToken({
      public_token: publicToken,
    }).then((resp) => {
      submitBankAccount(resp.bank_account_uuids[0])
    })
  }

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
  }

  const { open, ready } = usePlaidLink(config)

  const createLinkToken = useCallback(async () => {
    // For OAuth, use previously generated Link token
    if (window.location.href.includes('?oauth_state_id=')) {
      const linkToken = localStorage.getItem('link_token') || ''
      setToken(linkToken)
    } else {
      const user = await Auth.currentSession()
      const jwt = user.getIdToken().getJwtToken()

      const options = {
        method: 'GET',
        data: { noRedirectUri: true },
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }

      fetch(`${PLAID_API_ENDPOINT}`, options)
        .then((resp) => resp.json())
        .then((data) => {
          setToken(data.plaid_link_token)
          localStorage.setItem('link_token', data.plaid_link_token)
        })
        .catch(() => {
          setServerError('Failed to create Plaid link token. Please try again.')
        })
    }
  }, [setToken, PLAID_API_ENDPOINT])

  const watchBankAccount = useWatch({ control, name: 'bankAccount' })

  const onSubmit = handleSubmit(async (data: FormType) => {
    submitBankAccount(data.bankAccount)
  })

  useEffect(() => {
    if (!token && !window.Cypress) {
      createLinkToken()
    }
  }, [token, createLinkToken])

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (ready) open()
  }

  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={2} currentStep={2} totalStepCount={3} />
      ) : (
        <Stepper currentSection={2} currentStep={2} totalStepCount={5} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <div className="mb-6 text-16 text-content-black">
            <h1 className="mb-6 text-2xl font-bold text-content-black">
              <Trans i18nKey="funding:fundYourInvestment" />
            </h1>
            {bankAccountsData && tenThirtyOneData ? (
              <>
                <div className="mb-6">
                  <Trans i18nKey="funding:fundingCopy" />
                </div>
                <div className="mb-6 xl:">
                  {bankAccountsData?.bank_account_list &&
                    bankAccountsData?.bank_account_list.length > 0 && (
                      <>
                        <p className="mb-6 text-16 text-content-black">
                          <Trans i18nKey="funding:selectBankAccount" />
                        </p>
                        <Controller
                          name="bankAccount"
                          control={control}
                          rules={{ required: 'is required' }}
                          render={({ field }) => (
                            <div className="flex flex-col mb-6 gap-px">
                              {bankAccountsData?.bank_account_list.map(
                                (item: BankAccountTypes, index: number) => (
                                  <Radio
                                    {...field}
                                    key={index}
                                    value={item.account_uuid}
                                    checked={field.value === item.account_uuid}
                                    label={
                                      <div>
                                        <p className="font-bold capitalize mb-px">
                                          {item.account_description ||
                                            item.account_name}
                                        </p>
                                        <p className="capitalize mb-px">
                                          {item.account_type}
                                        </p>
                                      </div>
                                    }
                                  />
                                )
                              )}
                            </div>
                          )}
                        />
                      </>
                    )}
                </div>
                <p className="mb-6 text-16 text-content-black">
                  <Trans i18nKey="funding:addBankAccount" />
                </p>
                <div className="relative bg-bg-lighter mb-6 p-4 xl:p-6">
                  <div className="mb-6 flex items-center justify-between">
                    <h2 className="max-w-[66%] text-16 font-bold text-content-black">
                      <Trans i18nKey="funding:connectBankAccount" />
                    </h2>
                    <span className="h-[27px mr-2 w-[72px]">
                      <img src="/plaid.svg" />
                    </span>
                  </div>
                  <p className="mb-6 text-16 text-content-black">
                    <Trans i18nKey="funding:plaidTrusted" />
                  </p>
                  <button
                    className="mb-6 h-10 w-full rounded bg-primary text-16 font-medium hover:bg-primary-hover text-[#F2F2F2] disabled:bg-slate-200 disabled:text-content-black"
                    onClick={handleClick}
                    disabled={!ready}
                  >
                    {ready ? (
                      <span>
                        <Trans i18nKey="funding:connectToBank" />
                      </span>
                    ) : (
                      <div>
                        <Trans i18nKey="funding:connectToPlaid" />
                      </div>
                    )}
                  </button>
                  <p className="mb-6 text-16 text-content-black">
                    <Trans i18nKey="funding:manualCopy" />{' '}
                    <span
                      className="cursor-pointer text-primary hover:text-primary-hover"
                      data-cy="manual-link"
                      onClick={(e) => {
                        e.preventDefault()
                        nav(`/invest/${investData.opportunity.id}/funding/ach`)
                      }}
                    >
                      add your bank account manually
                    </span>{' '}
                    or use a{' '}
                    <span
                      className="cursor-pointer text-primary hover:text-primary-hover"
                      onClick={(e) => {
                        e.preventDefault()
                        nav(`/invest/${investData.opportunity.id}/funding/wire`)
                      }}
                    >
                      wire transfer.
                    </span>
                  </p>
                </div>
                <FormFooter
                  submitFunc={onSubmit}
                  disabled={!watchBankAccount || isLoading}
                />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </>
            ) : (
              <div className="w-full pt-16 flex justify-center items-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
        <div className="hidden h-full flex-grow xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default FundingScreen
