import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-nowrap',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        active: 'text-base text-[#008d7d] bg-[#E6F5F0] font-medium border-none',
        RM: 'bg-primary text-white font-normal',
        'auto-invest-on':
          'text-base font-medium text-primary bg-[#1D89FF0D] border-none',
        'auto-invest-off':
          'text-base font-medium text-[#8C8C8C] bg-[#F3F4F6] border-none',
        completed:
          'text-base text-content-light bg-bg-light font-medium border-none',
        incomplete:
          'text-base text-[#826600] bg-[#FFF3B9] font-medium border-none',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
