import { awsConfig } from '../routes/shared/_auth'
import { Amplify } from 'aws-amplify'

import { cookieStorage } from '../routes/shared/_auth'
import { getDrupalSessionTokens } from '@/routes/shared/_api'

export const amountRegex = /\D/g
export const amountRegexDecimal = /[^\d.]+|(?<=\..*)\./g

// Helper function to convert decimal numbers to integers while preserving precision
export const toIntegerAmount = (value: string | number | undefined) =>
  Math.round(Number(value) * 100)

export const validateAmountStep = (
  value: string | number | undefined,
  stepSize: string | number | undefined
) => {
  // Return early if no step size is defined
  if (!stepSize) return { isValid: null }

  // Convert both values to integers to avoid floating point precision issues
  const step = toIntegerAmount(stepSize)
  const amount = toIntegerAmount(value)

  // Validate that amount is a multiple of step
  if (amount % step !== 0) {
    return {
      isValid: false,
      error: `Amount must be a multiple of $${moneyMask(stepSize)}`,
    }
  }

  return { isValid: true }
}

export const getDecimalPart = (value: string | number | undefined) => {
  const decimalPart = String(value).replace(amountRegexDecimal, '')
  return decimalPart
}

export const moneyMask = (
  value: string | number | undefined,
  regex: RegExp = amountRegex
) => {
  value =
    regex === amountRegexDecimal
      ? String(value).replace(regex, '')
      : String(value)?.replace('.', '').replace(',', '').replace(regex, '')
  const options =
    regex === amountRegexDecimal
      ? { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      : { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  return new Intl.NumberFormat('en-US', options).format(Number(value))
}

export const FALLBACK_RM_IMAGE =
  'https://realtymogul.brightspotgocdn.com/dims4/default/6509e4e/2147483647/strip/true/crop/778x437+0+164/resize/500x281!/quality/90/?url=https%3A%2F%2Fbrightspot-go-k1-realtymogul.s3.us-east-1.amazonaws.com%2Fbrightspot%2F97%2F85%2F84b836f949779c7330f9d5d7259c%2Frm-logo-on-cover-image1.jpg'

export const STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
]

export const dateOut = (date: string) => {
  // format date to YYYY-MM-DD
  if (!date) return undefined
  const parts = date.split('/')
  return parts[2] + '-' + parts[0] + '-' + parts[1]
}

export const dateIn = (date?: string) => {
  //format Date to MM-DD-YYYY
  if (!date) return ''
  const parts = date.split('-')
  return parts[1] + '/' + parts[2] + '/' + parts[0]
}

export const decodePayload = (jwtToken: string) => {
  const payload = jwtToken.split('.')[1]
  try {
    // Replace base64url characters with base64 characters
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/')
    // Pad with '=' to make the length a multiple of 4
    const padded = base64.padEnd(
      base64.length + ((4 - (base64.length % 4)) % 4),
      '='
    )
    // Decode base64 string
    const decoded = atob(padded)
    return JSON.parse(decoded)
  } catch (err) {
    console.error('Failed to decode JWT payload:', err)
    return {}
  }
}

export const calculateClockDrift = (
  iatAccessToken: number,
  iatIdToken: number
) => {
  const now = Math.floor((new Date() as any) / 1000)
  const iat = Math.min(iatAccessToken, iatIdToken)
  return now - iat
}

export const setTokens = (
  accessToken: string,
  idToken: string,
  refreshToken: string
) => {
  const storage = cookieStorage

  // Clear any existing data with prefix CognitoIdentityServiceProvider.
  for (const key in storage) {
    if (key.startsWith('CognitoIdentityServiceProvider.')) {
      storage.removeItem(key)
    }
  }

  const idTokenData = decodePayload(idToken)
  const accessTokenData = decodePayload(accessToken)

  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.LastAuthUser',
    idTokenData['cognito:username']
  )
  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.' +
      idTokenData['cognito:username'] +
      '.idToken',
    idToken
  )
  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.' +
      idTokenData['cognito:username'] +
      '.accessToken',
    accessToken
  )
  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.' +
      idTokenData['cognito:username'] +
      '.refreshToken',
    refreshToken
  )
  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.' +
      idTokenData['cognito:username'] +
      '.clockDrift',
    '' + calculateClockDrift(accessTokenData['iat'], idTokenData['iat']) + ''
  )

  Amplify.configure({ ...awsConfig, storage: storage })
}

export const getAndSetMasqueradeTokens = async () => {
  const responseData = await getDrupalSessionTokens()
  if (
    responseData.accessToken &&
    responseData.idToken &&
    responseData.refreshToken
  ) {
    setTokens(
      responseData.accessToken,
      responseData.idToken,
      responseData.refreshToken
    )
  }

  return responseData
}

export const getEnv = () => {
  switch (window.location.hostname) {
    case 'my.realtymogul.com':
      return 'prod'
    case 'my.develop.realtymogul.com':
      return 'develop'
    case 'my.sandbox.realtymogul.com':
      return 'sandbox'
    case 'my.qa.realtymogul.com':
      return 'qa'
    default:
      return 'localhost'
  }
}

export const getDomain = () => {
  if (window.location.origin.includes('rtml.localhost')) {
    return '.rtml.localhost'
  }
  if (window.location.origin.includes('localhost')) {
    return '.localhost'
  }
  return '.realtymogul.com'
}

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const part = parts.pop()
    if (part) {
      return part.split(';').shift()
    }
  }
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const formatCurrency = (amount: string) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(parseFloat(amount))
}
