import './init'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Amplify } from '@aws-amplify/core'
import { awsConfig } from './routes/shared/_auth'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import './utils/i18n'
import './index.css'

import { getAndSetMasqueradeTokens, setTokens, getEnv } from './utils/helpers'

import { Toaster } from '@/components/ui/toaster'

import Error404 from './routes/error-404'

import Dashboard from './routes/dashboard/dashboard-root'
import Messages from './routes/dashboard/messages'
import Taxes from './routes/dashboard/taxes'
import Profile from './routes/dashboard/profile'
import ProfileCommPrefs from './routes/dashboard/profile/comm-prefs'
import EmailSecurity from './routes/dashboard/profile/email'
import VerifyEmail from './routes/dashboard/profile/verify-email'
import Transactions from './routes/dashboard/transactions'
import OwnershipTypes from './routes/dashboard/ownership'
import OwnershipTypesEdit from './routes/dashboard/ownership/edit-account'
import Investments from './routes/dashboard/investments'
import InvestmentDetails from './routes/dashboard/investments/details.tsx'
import BankAccounts from './routes/dashboard/bankaccounts'
import LinkNewBankAccount from './routes/dashboard/bankaccounts/link-new-account'
import EditDistributionSettings from './routes/dashboard/bankaccounts/edit-distribution-settings'

import Invest from './routes/Invest'
import Funded from './routes/Invest/Failed/Funded'
import FullyPledged from './routes/Invest/Failed/FullyPledged'
import HandleDocusignReturn from './routes/HandleDocusignReturn'
import PersonalInfoScreen from './routes/Invest/PersonalInfo'
import AccountScreen from './routes/Invest/Account'
import IndividualAccountScreen from './routes/Invest/Account/Individual'
import JointAccountScreen from './routes/Invest/Account/Joint'
import TrustAccountScreen from './routes/Invest/Account/Trust'
import EntityAccountScreen from './routes/Invest/Account/Entity'
import RetirementAccountScreen from './routes/Invest/Account/Retirement'
import RetirementSelectionAccountScreen from './routes/Invest/Account/RetirementSelection'
import AccredFailedScreen from './routes/Invest/Failed/AccredFailed'
import TenThirtyOneScreen from './routes/Invest/TenThirtyOne'
import TenThirtyOneInfoScreen from './routes/Invest/TenThirtyOne/TenThirtyOneInfo'
import TenThirtyOneAccommodatorScreen from './routes/Invest/TenThirtyOne/TenThirtyOneAccommodator'
import ReitIncomeScreen from './routes/Invest/Amount/ReitIncomeScreen'
import ReitAmountScreen from './routes/Invest/Amount/ReitAmountScreen'
import PrivatePlacementAmountScreen from './routes/Invest/Amount/PrivatePlacementAmountScreen'
import PledgeAmountScreen from './routes/Invest/Amount/PledgeAmountScreen'
import AccredScreen from './routes/Invest/Accred'
import StateFailedScreen from './routes/Invest/Failed/StateFailed'
import AboutYouScreen from './routes/Invest/AboutYou'
import ConcentrationScreen from './routes/Invest/Concentration'
import ConcentrationFollowUpScreen from './routes/Invest/Concentration/ConcentrationFollowUp'
import EmploymentScreen from './routes/Invest/Employment'
import Employed from './routes/Invest/Employment/Employed'
import SelfEmployed from './routes/Invest/Employment/SelfEmployed'
import Retired from './routes/Invest/Employment/Retired'
import Student from './routes/Invest/Employment/Student'
import NotEmployed from './routes/Invest/Employment/NotEmployed'
import EmployedFailedScreen from './routes/Invest/Failed/EmployedFailed'
import VerificationScreen from './routes/Invest/Verification'
import ThirdParty from './routes/Invest/Verification/ThirdParty'
import IncomeVerification from './routes/Invest/Verification/IncomeVerification'
import NetWorthVerification from './routes/Invest/Verification/NetWorthVerification'
import IdentityScreen from './routes/Invest/Identity'
// import MoreInformationScreen from './routes/Invest/Identity/MoreInformation'
// import PhotoScreen from './routes/Invest/Identity/PhotoScreen'
import SignatureScreen from './routes/Invest/Signature'
import FundingScreen from './routes/Invest/Funding'
import ManualACH from './routes/Invest/Funding/ACHManual'
import WireScreen from './routes/Invest/Funding/Wire'
import DistributionScreen from './routes/Invest/Funding/WireDistribution'
import ReviewScreen from './routes/Invest/Review'
import TenThirtyOneComplete from './routes/Invest/Completed/TenThirtyOneComplete'
import RetirementComplete from './routes/Invest/Completed/RetirementComplete'
import PledgeComplete from './routes/Invest/Completed/PledgeComplete'
import CompletedScreen from './routes/Invest/Completed'
import UploadScreen from './routes/dashboard/secure-document-upload.tsx'
import AddManualBankAccount from './routes/dashboard/bankaccounts/manual-ach'
import { EmailVerification } from './routes/email-verification'

// import AutoInvest from './routes/AutoInvest'
// import AutoInvestStartScreen from './routes/AutoInvest/Start'
// import AutoInvestAccredScreen from './routes/AutoInvest/Accred'
// import AutoInvestIncomeScreen from './routes/AutoInvest/Income'
// import AutoInvestAmountScreen from './routes/AutoInvest/Amount'
// import AutoInvestAboutYouScreen from './routes/AutoInvest/AboutYou'
// import AutoInvestAYStep1 from './routes/AutoInvest/AboutYou/Step1'
// import AutoInvestAYStep2 from './routes/AutoInvest/AboutYou/Step2'
// import AutoInvestAYStep3 from './routes/AutoInvest/AboutYou/Step3'
// import AutoInvestAYStep4 from './routes/AutoInvest/AboutYou/Step4'
// import AutoInvestEmploymentScreen from './routes/AutoInvest/Employment'
// import AutoInvestEmployed from './routes/AutoInvest/Employment/Employed'
// import AutoInvestSelfEmployed from './routes/AutoInvest/Employment/SelfEmployed'
// import AutoInvestRetired from './routes/AutoInvest/Employment/Retired'
// import AutoInvestStudent from './routes/AutoInvest/Employment/Student'
// import AutoInvestNotEmployed from './routes/AutoInvest/Employment/NotEmployed'
// import AutoInvestCompleted from './routes/AutoInvest/Completed'

if (getEnv() === 'prod' || getEnv() === 'qa') {
  datadogRum.init({
    clientToken: 'pub2fd23cff13e6e192043234a6ed90e831',
    applicationId: '1a5af2f3-acfb-469f-8bd6-9e1cf93190e1',
    site: 'us5.datadoghq.com',
    service: 'my.realtymogul.com',
    env: getEnv(),
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    version: '1.0.0',
  })
}

if (getEnv() !== 'localhost') {
  datadogLogs.init({
    clientToken: 'pub2fd23cff13e6e192043234a6ed90e831',
    site: 'us5.datadoghq.com',
    service: 'my.realtymogul.com',
    env: getEnv(),
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })
}

if (window.location.hash) {
  const { accessToken, idToken, refreshToken } = JSON.parse(
    decodeURIComponent(location.hash.replace('#token=', ''))
  )

  setTokens(accessToken, idToken, refreshToken)
  window.history.replaceState('', document.title, window.location.pathname)
}
// Check the query parameter if masquerade is true
if (new URLSearchParams(window.location.search).get('masquerade') === 'true') {
  // Call the Drupal session API get get the tokens
  getAndSetMasqueradeTokens().then(() => {
    // Remove the query parameter from the URL
    window.history.replaceState('', document.title, window.location.pathname)
    window.location.reload()
  })
}

Amplify.configure({ ...awsConfig })

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/dashboard/investments/active" />,
    errorElement: <Error404 />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard/investments/active" />,
      },
      {
        path: 'messages',
        element: <Messages />,
      },
      {
        path: 'taxes',
        element: <Taxes />,
      },
      {
        path: 'transactions',
        element: <Transactions />,
      },
      {
        path: 'investments',
        element: <Investments />,
        children: [
          {
            path: ':type',
            element: <Investments />,
          },
        ],
      },
      {
        path: 'investments/details/:uuid',
        element: <InvestmentDetails />,
      },
      {
        path: 'bank-accounts',
        element: <BankAccounts />,
      },
      {
        path: 'bank-accounts/new',
        element: <LinkNewBankAccount />,
      },
      {
        path: 'bank-accounts/new/:uuid/manual',
        element: <AddManualBankAccount />,
      },
      {
        path: 'bank-accounts/distributions',
        element: <EditDistributionSettings />,
      },
      {
        path: 'ownership-types',
        element: <OwnershipTypes />,
      },
      {
        path: 'ownership-types/edit/:uuid',
        element: <OwnershipTypesEdit />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'profile/email',
        element: <EmailSecurity />,
      },
      {
        path: 'profile/comm-prefs',
        element: <ProfileCommPrefs />,
      },
      {
        path: 'profile/email/verify',
        element: <VerifyEmail />,
      },
      {
        path: 'secure-document-upload',
        element: <UploadScreen />,
      },
    ],
  },
  {
    path: '/docusign/event',
    element: <HandleDocusignReturn />,
  },
  {
    path: 'invest/:ioID',
    element: <Invest />,
    children: [
      {
        path: 'start',
        index: true,
        element: <PersonalInfoScreen />,
      },
      {
        path: 'funded',
        index: true,
        element: <Funded />,
      },
      {
        path: 'fully_pledged',
        index: true,
        element: <FullyPledged />,
      },
      {
        path: 'account',
        element: <AccountScreen />,
      },
      {
        path: 'account/individual',
        element: <IndividualAccountScreen />,
      },
      {
        path: 'account/joint',
        element: <JointAccountScreen />,
      },
      {
        path: 'account/trust',
        element: <TrustAccountScreen />,
      },
      {
        path: 'account/entity',
        element: <EntityAccountScreen />,
      },
      {
        path: 'account/retirement',
        element: <RetirementAccountScreen />,
      },
      {
        path: 'account/retirement/selection',
        element: <RetirementSelectionAccountScreen />,
      },
      {
        path: 'account/failed',
        element: <StateFailedScreen />,
      },
      {
        path: 'amount/reit-income',
        element: <ReitIncomeScreen />,
      },
      {
        path: 'amount/reit',
        element: <ReitAmountScreen />,
      },
      {
        path: 'amount/pp',
        element: <PrivatePlacementAmountScreen />,
      },
      {
        path: 'amount/pledge',
        element: <PledgeAmountScreen />,
      },
      {
        path: '1031',
        element: <TenThirtyOneScreen />,
      },
      {
        path: '1031/info',
        element: <TenThirtyOneInfoScreen />,
      },
      {
        path: '1031/accommodator',
        element: <TenThirtyOneAccommodatorScreen />,
      },
      {
        path: '1031submitted',
        element: <TenThirtyOneComplete />,
      },
      {
        path: '1031complete',
        element: <TenThirtyOneComplete />,
      },
      {
        path: 'retirementcomplete',
        element: <RetirementComplete />,
      },
      {
        path: 'accred',
        element: <AccredScreen />,
      },
      {
        path: 'accred/failed',
        element: <AccredFailedScreen />,
      },
      {
        path: 'aboutyou/:step',
        element: <AboutYouScreen />,
      },
      {
        path: 'concentration',
        element: <ConcentrationScreen />,
      },
      {
        path: 'concentration/followup',
        element: <ConcentrationFollowUpScreen />,
      },
      {
        path: 'employment',
        element: <EmploymentScreen />,
      },
      {
        path: 'employment/employed',
        element: <Employed />,
      },
      {
        path: 'employment/failed',
        element: <EmployedFailedScreen />,
      },
      {
        path: 'employment/selfemployed',
        element: <SelfEmployed />,
      },
      {
        path: 'employment/retired',
        element: <Retired />,
      },
      {
        path: 'employment/student',
        element: <Student />,
      },
      {
        path: 'employment/notEmployed',
        element: <NotEmployed />,
      },
      {
        path: 'identity',
        element: <IdentityScreen />,
      },
      // {
      //   path: 'identity/moreinfo',
      //   element: <MoreInformationScreen />,
      // },
      // {
      //   path: 'identity/photo',
      //   element: <PhotoScreen />,
      // },
      {
        path: 'signature',
        element: <SignatureScreen />,
      },
      {
        path: 'verification',
        element: <VerificationScreen />,
      },
      {
        path: 'verification/thirdparty',
        element: <ThirdParty />,
      },
      {
        path: 'verification/income',
        element: <IncomeVerification />,
      },
      {
        path: 'verification/networth',
        element: <NetWorthVerification />,
      },
      {
        path: 'funding',
        element: <FundingScreen />,
      },
      {
        path: 'funding/ach',
        element: <ManualACH />,
      },
      {
        path: 'funding/wire',
        element: <WireScreen />,
      },
      {
        path: 'funding/wire/distribution',
        element: <DistributionScreen />,
      },
      {
        path: 'review',
        element: <ReviewScreen />,
      },
      {
        path: 'completed',
        element: <CompletedScreen />,
      },
      {
        path: 'pledgecomplete',
        element: <PledgeComplete />,
      },
    ],
  },
  {
    path: 'user/validate/*',
    element: <EmailVerification />,
  },
])

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} position="top-left" />
      <Toaster />
    </QueryClientProvider>
  </React.StrictMode>
)
