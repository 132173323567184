import { useTranslation } from 'react-i18next'

import { usePersonalInfoDataState } from '../_personalInfoDataState'

import FormFooter from '../../shared/FormFooter'
import { getDrupalUrl } from '../../shared/_api'
import { useFeatureFlags } from '@/rules/get-feature-flags'

const RetirementComplete = () => {
  const { t } = useTranslation()
  const { data: personalData } = usePersonalInfoDataState()
  const { data: featureFlagsData } = useFeatureFlags()

  return (
    <div className="h-full w-full border-t-4 border-success bg-white p-6 pb-20">
      <h1 className="mb-6 text-2xl font-bold text-content-black">
        <i
          className="fa-solid fa-circle-check mr-3"
          style={{ color: '#01B6A1' }}
        ></i>
        Investment Placed
      </h1>
      <p className="mb-6 text-16 text-content-black">
        <span className="font-medium">{personalData?.first_name}</span>, thank
        you for your investment request. Our team will reach out to you with
        next steps shortly. {t('completed:questionsCopy')}{' '}
        <a className="text-primary" href="tel:+14243204205">
          424-320-4205
        </a>{' '}
        or email us at{' '}
        <a className="text-primary" href="mailto:investor-help@realtymogul.com">
          investor-help@realtymogul.com
        </a>
        .
      </p>
      <div className="mb-6">
        <FormFooter
          submitFunc={() =>
            (window.location.href = `${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`)
          }
          secondaryLink
          buttonText="Go to Dashboard"
        />
      </div>
    </div>
  )
}

export default RetirementComplete
