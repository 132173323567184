import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

import { AccountDataTypes } from './api/schema'
import { formatAddress } from '../shared/format-address'
import { mapParams } from '../shared/map-params'
import { useOwnershipTypes } from './api/get-ownership-types'

import { EmptyState } from '../components/empty-state'
import Loader from '@/components/Loader'
import { OwnershipTypeMenu } from './components/edit-menu'
import { Pagination } from './components/pagination'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

const getPhoneNumber = (phoneNumber: string) => {
  if (isValidPhoneNumber(phoneNumber)) {
    return formatPhoneNumber(phoneNumber)
  }
  return phoneNumber
}

const getAddress = (account: AccountDataTypes) => {
  if (account.type === 'individual' || account.use_primary_investor_contact) {
    return account.primary_member.address
      ? formatAddress(account.primary_member.address)
      : ''
  }
  if (account.address) {
    return formatAddress(account.address)
  }
}

export const OwnershipTypes = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  useEffect(() => updateMobileNav(true), [updateMobileNav])

  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 10,
  })
  const { data, isLoading, isError } = useOwnershipTypes()

  useEffect(() => {
    const page = parseInt(searchParams.get('page') ?? '0') || 1
    setPagination((pagination) => {
      return { ...pagination, pageIndex: page - 1 }
    })
  }, [searchParams])

  return data ? (
    <div className="w-full h-full mb-10">
      <div className="w-full md:mb-10 flex flex-col justify-between items-center p-6 md:p-0 relative">
        <div className="w-full mb-6 flex justify-between items-center">
          <h1 className="text-27 md:text-31 font-bold self-start">
            Ownership Types
          </h1>
        </div>
        <div className="w-full flex flex-col gap-4 mb-4">
          {data.accounts.length > 0 ? (
            <>
              <div>Manage ownership types for investing.</div>
              {data.accounts.map((account) => (
                <div key={account.uuid}>
                  <div className="hidden md:flex w-full rounded border border-[#E7E7E7] justify-between items-center text-base">
                    <div className="p-4 inline-flex gap-3 w-[25%]">
                      <div className="w-5 h-4 flex justify-center text-16">
                        {account.type === 'individual' && (
                          <i className="fa-light fa-user" />
                        )}
                        {account.type === 'joint_ownership' && (
                          <i className="fa-light fa-user-group" />
                        )}
                        {(account.type === 'foe_trust' ||
                          account.type === 'foe_sdira') && (
                          <i className="fa-light fa-folder-closed" />
                        )}
                        {account.type === 'foe_entity' && (
                          <i className="fa-light fa-briefcase" />
                        )}
                      </div>
                      <div className="font-medium">
                        {account.investment_account_name}
                      </div>
                    </div>
                    <div className="p-4 w-[10%]">{mapParams(account.type)}</div>
                    <div className="p-4 w-[15%]">
                      {(account?.total_investment_count ?? 0) > 0 ? (
                        `${account.total_investment_count} investment${
                          (account.total_investment_count || 0) > 1 ? 's' : ''
                        }`
                      ) : (
                        <span className="text-base text-content-light">
                          No investments
                        </span>
                      )}
                    </div>
                    <div className="p-4 w-[30%]">{getAddress(account)}</div>
                    <div className="p-4 w-[15%]">
                      {getPhoneNumber(account.account_phone_number ?? '')}
                    </div>
                    <OwnershipTypeMenu
                      uuid={account.uuid}
                      showExpandedMenu={
                        (account?.total_investment_count ?? 0) > 0
                      }
                    />
                  </div>
                  <div className="md:hidden p-3 border border-border-normal rounded text-base flex flex-row">
                    <div className="mr-4">
                      {account.type === 'individual' && (
                        <i className="fa-light fa-user" />
                      )}
                      {account.type === 'joint_ownership' && (
                        <i className="fa-light fa-user-group" />
                      )}
                      {(account.type === 'foe_trust' ||
                        account.type === 'foe_sdira') && (
                        <i className="fa-light fa-folder-closed" />
                      )}
                      {account.type === 'foe_entity' && (
                        <i className="fa-light fa-briefcase" />
                      )}
                    </div>
                    <div className="flex flex-col flex-grow gap-1">
                      <div className="font-medium">
                        {account.investment_account_name}
                      </div>
                      <div>{mapParams(account.type)}</div>
                      <div className="text-content-light">
                        {(account?.total_investment_count ?? 0) > 0 ? (
                          `${account.total_investment_count} investment${
                            (account.total_investment_count || 0) > 1 ? 's' : ''
                          }`
                        ) : (
                          <span className="text-base text-content-light">
                            No investments
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="w-8 h-8">
                      <OwnershipTypeMenu
                        uuid={account.uuid}
                        showExpandedMenu={
                          (account?.total_investment_count ?? 0) > 0
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <Pagination
                pagination={pagination}
                totalPages={data.pages}
                totalCount={data.count}
                changePage={(newPage) => {
                  const newSearchParams = new URLSearchParams(searchParams)
                  newSearchParams.set('page', (newPage + 1).toString())
                  setSearchParams(newSearchParams)
                }}
              />
            </>
          ) : (
            <div className="md:py-40">
              <EmptyState
                iconClasses="fa-regular fa-user-group fa-3x text-primary opacity-20"
                copy="You have no ownership types."
                linkCopy="Browse Current Investments"
                linkTo="https://www.realtymogul.com/investment-opportunities"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="h-[75vh] flex justify-center items-center">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your ownership types."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default OwnershipTypes
