import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { cancelInvestment, getDrupalUrl } from '../../shared/_api'
import { useInvestDataState } from '../_investDataState'
import { track } from '../../../utils/analytics'
import { useFeatureFlags } from '@/rules/get-feature-flags'

const AccredFailedScreen = () => {
  const { data: investData } = useInvestDataState()
  const { t } = useTranslation()
  const { data: featureFlagsData } = useFeatureFlags()

  useEffect(() => {
    track('Cancel Investment')
    cancelInvestment(investData?.investment?.id)
  }, [investData])

  return (
    <div
      data-cy="accred-failed-screen"
      className="border-t-4 border-alert-light p-6"
    >
      <p className="mb-6 text-16 font-medium text-content-black">
        {t('failed:accredHeader')}
      </p>
      <p className="mb-12 text-base text-content-black">
        {t('failed:accred1')}
      </p>
      <a
        className="flex h-10 w-full md:w-fit px-4 items-center justify-center rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-50"
        data-cy="continue-button"
        href={`${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`}
      >
        {t('common:myDashboard')}
      </a>
    </div>
  )
}

export default AccredFailedScreen
