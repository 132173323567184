import { useNavigate } from 'react-router-dom'
import { ProfileSubNav } from './components/profile-subnav'
import { useAuthDataState } from '@/routes/shared/_authDataState'
import { useMobileNavStore } from '../api/use-mobile-nav-store'
import { useEffect } from 'react'

const ProfileCommPrefs = () => {
  const { data: authData } = useAuthDataState()
  const nav = useNavigate()

  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  useEffect(() => updateMobileNav(false), [updateMobileNav])

  return (
    authData && (
      <>
        <div
          className="h-10 px-6 justify-start bg-bg-lighter items-center gap-3 flex md:hidden"
          onClick={() => {
            nav('/dashboard/profile')
          }}
        >
          <button className="h-4 flex-col justify-center items-center inline-flex">
            <i className="fa-solid fa-angle-left" />
          </button>
          <div className="text-base mt-[2px]">Profile</div>
        </div>
        <div className="w-full h-full p-6 md:p-0">
          <h1 className="text-27 md:text-31 font-bold self-start">
            <span className="hidden md:inline">Profile</span>
            <span className="inline md:hidden">Communication Preferences</span>
          </h1>
          <div className="hidden md:block my-10">
            <ProfileSubNav />
          </div>
          <div className="mt-6 md:mt-0 text-16 mb-10">
            Manage the messages you would like to receive from us.
          </div>
          <a
            href={`https://cloud.e.realtymogul.com/preferences?email=${authData.attributes.email}`}
            target="_blank"
            className="w-full px-6 py-4 mb-10 h-[78px] flex justify-between items-center border rounded hover:bg-bg-lighter"
          >
            <div className="flex-col text-base">
              <div className="text-base mb-2">Email</div>
              <div className="font-medium">
                <span className="underline">
                  Unsubscribe from all marketing communications
                </span>
              </div>
            </div>
          </a>
        </div>
      </>
    )
  )
}

export default ProfileCommPrefs
