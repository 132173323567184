import { create } from 'zustand'

type NotificationsStore = {
  hideNewMessages: boolean
  hideTodos: boolean
  pendingActionItems: number
  pledgedActionItems: number
  increasePendingActionItem: () => void
  increasePledgedActionItem: () => void
  removeNewMessagesNotification: () => void
  removeTodosNotification: () => void
  resetActionItems: () => void
}

export const useNotificationsStore = create<NotificationsStore>((set) => ({
  hideNewMessages: false,
  hideTodos: false,
  pendingActionItems: 0,
  pledgedActionItems: 0,
  increasePendingActionItem: () =>
    set((state) => ({ pendingActionItems: state.pendingActionItems + 1 })),
  increasePledgedActionItem: () =>
    set((state) => ({ pledgedActionItems: state.pledgedActionItems + 1 })),
  removeNewMessagesNotification: () => set({ hideNewMessages: true }),
  removeTodosNotification: () => set({ hideTodos: true }),
  resetActionItems: () => set({ pendingActionItems: 0, pledgedActionItems: 0 }),
}))
