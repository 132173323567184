import { useRef, useState } from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { cn } from '@/utils/utils'
import { TaxDocumentType } from '../api/schema'
import { Link, useLocation } from 'react-router-dom'

type InvestmentsTooltipTypes = {
  isUploaded: string
  rowInvestmentsData: TaxDocumentType['investments']
}

export const InvestmentsTooltip = ({
  isUploaded,
  rowInvestmentsData,
}: InvestmentsTooltipTypes) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const { pathname, search } = useLocation()

  const toggleTooltip = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={isOpen}>
        <Tooltip.Trigger asChild>
          <button
            className={cn(
              isUploaded ? 'text-content-black' : 'text-[#8C8C8C]',
              'w-fit cursor-pointer underline text-base'
            )}
            onClick={toggleTooltip}
          >
            {`${rowInvestmentsData.length} investments`}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            ref={contentRef}
            className="bg-white p-6 text-base rounded shadow-[0px_3px_30px_0px_rgba(96,96,96,0.25)]"
          >
            <div className="font-medium mb-3">{`${rowInvestmentsData.length} investments`}</div>
            <ul className="list-disc pl-6">
              {rowInvestmentsData.map(({ io_uuid, io_name }) => (
                <li key={io_uuid} className="mb-2">
                  <Link
                    className="underline cursor-pointer text-base"
                    to={`/dashboard/investments/details/${io_uuid}`}
                    state={{ previousLocation: pathname + search }}
                  >
                    {io_name}
                  </Link>
                </li>
              ))}
            </ul>
            <Tooltip.Arrow className="fill-white" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
