import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '@/routes/shared/_api'
import { ShareRepurchaseTypes } from './get-share-repurchase'

type PostShareRepurchaseTypes = {
  io_uuid: string
  investment_account_uuid: string
  percent: number
}

const postShareRepurchase = (data: PostShareRepurchaseTypes) => {
  return api.post('/dashboard/investments/share-repurchase', { ...data })
}

export const usePostShareRepurchase = ({ io_uuid }: { io_uuid: string }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postShareRepurchase,
    onMutate: async (shareRepurchasePayload) => {
      await queryClient.cancelQueries({
        queryKey: ['share-repurchase', io_uuid],
      })
      const previousData = queryClient.getQueryData<ShareRepurchaseTypes>([
        'share-repurchase',
        io_uuid,
      ])
      // optimistically mark the investment account as having an active share repurchase
      const newEligibleInvestments = previousData?.eligible_investments.map(
        (item) => {
          if (
            item.investment_account_uuid ===
            shareRepurchasePayload.investment_account_uuid
          ) {
            return {
              ...item,
              has_active_share_repurchase_request: true,
            }
          } else {
            return item
          }
        }
      )
      queryClient.setQueryData(['share-repurchase', io_uuid], {
        ...previousData,
        eligible_investments: newEligibleInvestments,
      })

      return { previousData, shareRepurchasePayload }
    },
    onError: (_err, _variables, context: any) => {
      if (context?.previousData) {
        queryClient.setQueryData<ShareRepurchaseTypes>(
          ['share-repurchase', io_uuid],
          context.previousData
        )
      }
    },
  })
}
