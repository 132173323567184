import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { useBankAccounts } from './api/get-bank-accounts'
import { useDeleteBankAccount } from './api/delete-bank-account'
import { useToast } from '@/hooks/use-toast'

import { BankAccountCard } from './components/bank-account-card'
import { Button } from '@/components/ui/button'
import { EmptyState } from '../components/empty-state'
import Loader from '@/components/Loader'
import { mapParams } from '../shared/map-params'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

export const BankAccounts = () => {
  const nav = useNavigate()
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { data, isLoading, isError } = useBankAccounts()
  const { mutateAsync } = useDeleteBankAccount()
  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  useEffect(() => updateMobileNav(true), [updateMobileNav])

  const handleDelete = async (
    uuid: string,
    investment_account_uuid: string
  ) => {
    mutateAsync({ uuid, investment_account_uuid }).then(() => {
      toast({
        variant: 'success',
        description: 'Bank account deleted successfully',
        position: 'top',
      })
      setTimeout(() => {
        queryClient.invalidateQueries(['bank-accounts'])
      }, 3000)
    })
  }

  if (isError)
    return (
      <div className="flex justify-center items-center pt-10 md:h-[45vh]">
        <EmptyState
          iconClasses="fa-regular fa-bomb fa-3x text-content-black"
          copy="There was an error while retrieving your bank accounts."
          linkCopy="Browse Current Investments"
          linkTo="https://www.realtymogul.com/investment-opportunities"
        />
      </div>
    )

  if (isLoading)
    return (
      <div className="flex justify-center items-center pt-10 h-[45vh]">
        <Loader />
      </div>
    )

  return data ? (
    <div className="w-full h-full p-6 md:p-0 mb-10">
      <div className="w-full flex flex-col">
        <div className="w-full mb-10 flex justify-between items-center">
          <h1 className="text-27 md:text-31 font-bold self-start">
            Bank Accounts
          </h1>
        </div>
        {data.length > 0 ? (
          <>
            <div className="inline-flex flex-col md:flex-row justify-between items-center gap-4 mb-6">
              <span className="mr-2">
                Link and manage external bank accounts to fund investments and
                receive distributions.
              </span>
              <div className="w-full md:w-fit flex flex-row-reverse md:flex-row justify-between md:justify-end gap-4">
                <Button
                  variant="RMSecondary"
                  size="RM"
                  onClick={() => {
                    nav(`new`)
                  }}
                  disabled={data.length < 1}
                >
                  <span className="hidden md:inline">
                    Link New Bank Account
                  </span>
                  <span className="md:hidden">Link Account</span>
                </Button>
                <Button
                  variant="RM"
                  size="RM"
                  disabled={data.length < 1}
                  onClick={() => nav('distributions')}
                >
                  Distribution Settings
                </Button>
              </div>
            </div>
            {data.map((investmentAccount) => (
              <div
                className="w-full mb-3"
                key={investmentAccount?.investment_account_name}
              >
                <div className="w-full flex justify-between items-center mb-6">
                  <div className="flex flex-col gap-1">
                    <div className="text-base capitalize">
                      {mapParams(
                        investmentAccount?.investment_account_type || ''
                      ) || 'N/A'}
                    </div>
                    <div className="text-2xl font-medium">
                      {investmentAccount?.investment_account_name || 'N/A'}
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-4 mb-4">
                  {(investmentAccount?.bank_accounts.length ?? 0) > 0 &&
                    investmentAccount?.bank_accounts.map((account) => (
                      <BankAccountCard
                        account={account}
                        investment_account_uuid={
                          investmentAccount?.investment_account_uuid ?? ''
                        }
                        handleDelete={(uuid) =>
                          handleDelete(
                            uuid,
                            investmentAccount?.investment_account_uuid || ''
                          )
                        }
                        key={account.uuid}
                      />
                    ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="md: pt-10">
            <EmptyState
              iconClasses="fa-regular fa-bank fa-3x text-primary opacity-20"
              copy="You have no bank accounts."
              secondaryCopy="Once you have an approved investment, you can manage your external bank accounts."
              linkCopy="Browse Current Investments"
              linkTo="https://www.realtymogul.com/investment-opportunities"
            />
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default BankAccounts
