import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { api } from '@/routes/shared/_api'
import { mapParams } from '../shared/map-params'
import { AllAccountTypes } from './api/schema'
import { getAccount } from './api/get-account'
import { toast } from '@/hooks/use-toast'

import { Button } from '@/components/ui/button'
import Checkbox from '@/routes/dashboard/components/checkbox'
import CountryInput from '@/components/CountryInput'
import ErrorAlert from '@/components/ErrorAlert'
import { EmptyState } from '../components/empty-state'
import Loader from '@/components/Loader'
import { OtherDetails } from './components/other-details'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import StateDropdown from '@/components/StateDropdown'
import TextInput from '@/components/TextInput'
import NumberInput from '@/components/NumberInput'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

export const OwnershipTypesEdit = () => {
  const [hideAddressForm, setHideAddressForm] = useState(false)
  const { uuid } = useParams()
  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  const nav = useNavigate()
  const queryClient = useQueryClient()

  useEffect(() => updateMobileNav(false), [updateMobileNav])

  const { data, isLoading, isError } = useQuery<AllAccountTypes>({
    queryKey: ['account-data', uuid],
    queryFn: () => getAccount(uuid ?? ''),
    staleTime: Infinity,
  })

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<AllAccountTypes>({
    defaultValues: {
      address: {
        country: 'US',
        adminarea: 'AL',
      },
      use_primary_investor_contact: false,
    },
  })

  useEffect(() => {
    if (!data) return
    setValue(
      'phone_number',
      data?.type === 'joint_ownership'
        ? data.secondary_member.phone_number
        : data.phone_number
    )
    setValue('address', { ...data?.address })
    setValue('use_primary_investor_contact', data?.use_primary_investor_contact)
    setHideAddressForm(data.use_primary_investor_contact ?? false)
  }, [setValue, data])

  const watchAddress = useWatch({ control, name: 'address' })

  const onSubmit = handleSubmit((formData) => {
    api
      .patch(
        `/dashboard/investment-accounts/${data?.uuid}`,
        hideAddressForm
          ? {
              type: data?.type,
              use_primary_investor_contact: true,
            }
          : {
              type: data?.type,
              use_primary_investor_contact: false,
              address: { ...formData.address },
              phone_number: formData.phone_number,
            }
      )
      .then(() => {
        toast({
          variant: 'success',
          description: 'Ownership type updated.',
          position: 'top',
        })
        queryClient.invalidateQueries(['ownership-types', '1'])
        queryClient.invalidateQueries(['account-data', uuid])
      })
  })

  return data ? (
    <div className="w-full h-full mb-10">
      <div className="hidden md:inline-block text-[13px] mb-10">
        <button
          className="text-primary"
          onClick={() => nav('/dashboard/ownership-types')}
        >
          Ownership Types
        </button>
        <i className="fa-light fa-angle-right mx-2" />
        Edit Ownership Type
      </div>
      <div
        className=" h-10 px-6 justify-start bg-bg-lighter items-center gap-3 flex md:hidden"
        onClick={() => {
          updateMobileNav(true)
          nav('/dashboard/ownership-types')
        }}
      >
        <button className="h-4 flex-col justify-center items-center inline-flex">
          <i className="fa-solid fa-angle-left" />
        </button>
        <div className="text-base mt-[2px]">Ownership Types</div>
      </div>
      <div className="w-full p-6 md:p-0">
        <h1 className="text-27 md:text-31 font-bold self-start mb-2">
          Edit Ownership Type
        </h1>
        <div className="text-16 italic capitalize mb-6">
          {mapParams(data?.type ?? '')} - {data?.investment_account_name}
        </div>
        {data?.type === 'individual' && (
          <>
            <div className="bg-[#FFF3B9] text-base text-[#897C00] px-4 py-2 mb-6 w-fit">
              Updates to your contact information below will update your
              Individual Ownership Type and tax documents for the upcoming
              calendar year.
            </div>
            <div className="flex flex-col md:flex-row gap-6">
              <Button
                variant="RM"
                size="RM"
                onClick={(e) => {
                  e.preventDefault()
                  nav('/dashboard/profile')
                }}
              >
                Go to Profile
              </Button>
              <Button
                variant="RMSecondary"
                size="RMSecondary"
                onClick={(e) => {
                  e.preventDefault()
                  nav('/dashboard/ownership-types')
                }}
              >
                Back
              </Button>
            </div>
          </>
        )}
        {data?.type === 'foe_sdira' && (
          <div className="w-full flex flex-col gap-10">
            <OtherDetails data={data} />
            <div className="max-w-[500px]">
              <Button
                variant="RM"
                size="RM"
                onClick={(e) => {
                  e.preventDefault()
                  nav('/dashboard/ownership-types')
                }}
              >
                Back
              </Button>
            </div>
          </div>
        )}
        {(data?.type === 'foe_trust' ||
          data?.type === 'foe_entity' ||
          data?.type === 'joint_ownership') && (
          <div className="w-full flex flex-col md:flex-row justify-between gap-10">
            <form className="flex-grow max-w-[600px]" onSubmit={onSubmit}>
              <div className="text-[19px] font-medium mb-6">
                Contact Information
              </div>
              {Object.keys(errors).length > 0 && <ErrorAlert />}
              <div className="mb-6">
                <Controller
                  name="use_primary_investor_contact"
                  control={control}
                  render={({ field }) => (
                    <label className="w-full flex gap-2 cursor-pointer">
                      <Checkbox
                        {...field}
                        onChange={() => {
                          field.onChange(!field.value)
                          setHideAddressForm(!hideAddressForm)
                        }}
                        checked={field.value}
                      />
                      <span className="text-base md:text-16">
                        Use the contact information from{' '}
                        <Link className="underline" to="/dashboard/profile">
                          my profile
                        </Link>{' '}
                        for this Ownership Type.
                      </span>
                    </label>
                  )}
                />
              </div>
              {!hideAddressForm && (
                <div>
                  <div className="mb-6">
                    <span
                      className={`mb-2 block text-base font-normal ${
                        errors?.phone_number?.message
                          ? 'text-alert'
                          : 'text-content-black'
                      }`}
                    >
                      Phone Number
                    </span>
                    <PhoneInput
                      name="phone_number"
                      className={`phone-input-wrapper mb-2 h-[42px] w-full rounded border ${
                        errors?.phone_number ? 'border-alert' : 'border-input'
                      } px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary`}
                      defaultCountry="US"
                      countryOptionsOrder={['US']}
                      control={control}
                      initialValueFormat="national"
                      limitMaxLength={true}
                      rules={{
                        required: 'is required',
                        validate: {
                          isValidPhoneNumber: (value: string) =>
                            isValidPhoneNumber(value) ||
                            'Please enter a valid phone number.',
                        },
                      }}
                    />
                    {errors?.phone_number?.message && (
                      <p className="mb-1 mt-2 text-xs text-alert">
                        {errors?.phone_number?.message}
                      </p>
                    )}
                  </div>
                  <div className="mb-6">
                    <CountryInput
                      register={register('address.country')}
                      errorMessage={errors?.address?.country?.message}
                    />
                  </div>
                  <div className="mb-2">
                    <Controller
                      name="address.thoroughfare"
                      control={control}
                      rules={{
                        required: 'is required',
                        maxLength: {
                          value: 255,
                          message: '255 characters maximum',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextInput
                          label="Address"
                          errorMessage={fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-6">
                    <Controller
                      name="address.premise"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 255,
                          message: '255 characters maximum',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextInput
                          placeholder="Street 2"
                          errorMessage={fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-6">
                    <Controller
                      name="address.locality"
                      control={control}
                      rules={{
                        required: 'is required',
                        maxLength: {
                          value: 255,
                          message: '255 characters maximum',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextInput
                          label="City"
                          errorMessage={fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-10 flex gap-3">
                    <div className="w-1/2">
                      <Controller
                        name="address.adminarea"
                        control={control}
                        rules={{
                          required:
                            watchAddress?.country === 'US'
                              ? 'is required'
                              : false,
                          maxLength: {
                            value: 255,
                            message: '255 characters maximum',
                          },
                        }}
                        render={({ field, fieldState }) =>
                          watchAddress?.country === 'US' ? (
                            <StateDropdown
                              errorMessage={fieldState?.error?.message}
                              label={'State'}
                              {...field}
                            />
                          ) : (
                            <TextInput
                              label={
                                watchAddress?.country === 'US'
                                  ? 'State'
                                  : 'State/Province/Region'
                              }
                              errorMessage={fieldState?.error?.message}
                              {...field}
                            />
                          )
                        }
                      />
                    </div>
                    <div className="w-1/2">
                      <Controller
                        name="address.postcode"
                        control={control}
                        rules={{
                          required: 'is required',
                          maxLength: {
                            value: 20,
                            message: '20 characters maximum',
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <NumberInput
                            label={
                              watchAddress?.country === 'US'
                                ? 'Zip Code'
                                : 'Postal Code'
                            }
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-col md:flex-row gap-6">
                <Button
                  variant="RM"
                  size="RM"
                  disabled={!isDirty}
                  onClick={onSubmit}
                >
                  Update
                </Button>
                <Button
                  variant="RMSecondary"
                  size="RMSecondary"
                  onClick={(e) => {
                    e.preventDefault()
                    nav('/dashboard/ownership-types')
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
            <OtherDetails data={data} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="h-[75vh] flex justify-center items-center">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your account."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default OwnershipTypesEdit
