import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { z } from 'zod'
import { datadogLogs } from '@datadog/browser-logs'

import {
  InvestmentDataTypes,
  investmentsSchema,
  InvestmentsDataTypes,
} from './schema'
import { useSearchParams } from 'react-router-dom'
import { useRoute } from '@/rules/find-route'
import { useNotificationsStore } from './use-notifications-store'

export const getInvestments = async (
  params: URLSearchParams,
  increasePendingActionItem: () => void,
  increasePledgedActionItem: () => void,
  findRoute: (params: {
    investment: any
    opportunity: any
    investment_state: any
  }) => Promise<{ type: string }>
) => {
  const response = await api.get('/dashboard/investments', { params })

  const parsedData = investmentsSchema.safeParse(response)

  const groupAndProcessInvestments = async (data: InvestmentsDataTypes) => {
    const groupedInvestments = data.investments.reduce(
      (acc, investment) => {
        const status = investment.status.toLowerCase() ?? 'unknown'
        if (!acc[status]) {
          acc[status] = []
        }
        acc[status].push(investment)
        return acc
      },
      {} as { [key: string]: InvestmentDataTypes[] }
    )

    if (groupedInvestments?.pending) {
      groupedInvestments.pending = await Promise.all(
        groupedInvestments.pending.map(async (item) => {
          const route = await findRoute({
            investment: {
              ...item.investment,
              amount: Number(item.investment?.initial_amount),
            },
            opportunity: item.investment?.opportunity,
            investment_state: item.investment?.investflow_state,
          })
          if (
            route?.type !== 'funded' &&
            (route?.type !== 'completed' || item.investment?.wire_instructions)
          ) {
            increasePendingActionItem()
          }
          return { ...item, route: route.type }
        })
      )
      groupedInvestments.pending.sort((a, b) => {
        if (a.route === 'funded') return 1
        if (b.route === 'funded') return -1
        if (a.investment?.wire_instructions) return -1
        if (b.investment?.wire_instructions) return 1
        if (a.route === 'completed') return 1
        if (b.route === 'completed') return -1
        return 0
      })
    }

    if (groupedInvestments?.pledged) {
      groupedInvestments.pledged = await Promise.all(
        groupedInvestments.pledged.map(async (item) => {
          const route = await findRoute({
            investment: {
              ...item.investment,
            },
            opportunity: item.investment?.opportunity,
            investment_state: item.investment?.investflow_state,
          })
          if (
            (route?.type !== '1031submitted' &&
              route?.type !== 'pledgecomplete') ||
            (route?.type === 'pledgecomplete' &&
              item?.investment?.investflow_state?.eav === 'required')
          ) {
            increasePledgedActionItem()
          }
          return { ...item, route: route?.type }
        })
      )
      groupedInvestments.pledged.sort((a, b) => {
        if (a.route === '1031submitted') return 1
        if (b.route === '1031submitted') return -1
        if (a.route === 'pledgecomplete')
          return a.investment?.investflow_state?.eav === 'required' ? -1 : 1
        if (b.route === 'pledgecomplete') return -1
        return 0
      })
    }

    return groupedInvestments
  }

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-investments',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof investmentsSchema
    >
    return groupAndProcessInvestments(unsafeUnparsedData)
  } else {
    return groupAndProcessInvestments(parsedData.data)
  }
}

export const useInvestments = () => {
  const [searchParams, _] = useSearchParams()
  const increasePendingActionItem = useNotificationsStore(
    (state) => state.increasePendingActionItem
  )
  const increasePledgedActionItem = useNotificationsStore(
    (state) => state.increasePledgedActionItem
  )
  const resetActionItems = useNotificationsStore(
    (state) => state.resetActionItems
  )
  const { findRoute } = useRoute()

  return useQuery({
    queryKey: ['investments', searchParams.get('investment_account_uuid')],
    queryFn: () => {
      resetActionItems()
      return getInvestments(
        searchParams,
        increasePendingActionItem,
        increasePledgedActionItem,
        findRoute
      )
    },
    staleTime: 60 * 1000 * 60,
    keepPreviousData: true,
  })
}
