import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useBankAccounts } from './api/get-bank-accounts'
import { Filter } from './components/filter'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { api, getAPIUrl } from '@/routes/shared/_api'
import Loader from '@/components/Loader'
import {
  associatedInvestmentsSchema,
  distributionAccountSchema,
  DistributionAccountTypes,
  PatchInvestmentTypes,
} from './api/schema'
import { Button } from '@/components/ui/button'
import {
  capitalizeFirstLetter,
  dateIn,
  FALLBACK_RM_IMAGE,
} from '@/utils/helpers'
import { Checkbox } from '@/routes/dashboard/components/checkbox'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { cn } from '@/utils/utils'
import { useToast } from '@/hooks/use-toast'
import { EmptyState } from '../components/empty-state'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

const EditDistributionSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showSelectAll, setShowSelectAll] = useState(true)
  const [newDistributionAccount, setNewDistributionAccount] = useState<
    DistributionAccountTypes | undefined
  >()
  const [showDialog, setShowDialog] = useState(false)
  const { data: bankAccountsData } = useBankAccounts()
  const { toast } = useToast()
  const nav = useNavigate()
  const queryClient = useQueryClient()

  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  useEffect(() => updateMobileNav(false), [updateMobileNav])

  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ['distributions', searchParams.get('investment_account_uuid')],
    queryFn: async () => {
      const response = await api.get(
        `${getAPIUrl()}dashboard/bank-accounts/active-distribution-investments?investment_account_uuid=${searchParams.get('investment_account_uuid')}`
      )
      return associatedInvestmentsSchema.parse(response)
    },
    staleTime: Infinity,
    enabled: !!searchParams.get('investment_account_uuid'),
  })

  const { data: allowedBankAccountsData } = useQuery({
    queryKey: [
      'distribution-bank-accounts',
      searchParams.get('investment_account_uuid'),
    ],
    queryFn: async () => {
      const response = await api.get(
        `${getAPIUrl()}dashboard/investment-accounts/${searchParams.get('investment_account_uuid')}/valid-bank-accounts`
      )
      return z.array(distributionAccountSchema).parse(response)
    },
    staleTime: Infinity,
    enabled: !!searchParams.get('investment_account_uuid'),
  })

  const { control, reset, setValue, watch } = useForm()

  const watchInvestments = watch('update-distribution-accounts')

  const { control: modalControl, watch: modalWatch } = useForm()

  const watchACHAgreement = modalWatch('ach-agreement')

  const selectAll = () => {
    setValue('update-distribution-accounts', Array(data?.length).fill(true), {
      shouldDirty: true,
    })
    setShowSelectAll((toggle) => !toggle)
  }

  const clearAll = () => {
    reset({})
    setShowSelectAll((toggle) => !toggle)
  }

  const modalSubmit = () => {
    const submissionData: PatchInvestmentTypes[] = []
    watchInvestments.map((item: PatchInvestmentTypes, index: number) => {
      if (item && newDistributionAccount?.uuid) {
        submissionData.push({
          uuid: data?.[index]?.investment_uuid || '',
          distribution_account_uuid: newDistributionAccount.uuid,
        })
      }
    })
    api.patch('/dashboard/investments', submissionData).then(() => {
      setShowDialog(false)
      nav('/dashboard/bank-accounts')
      setTimeout(() => {
        queryClient.invalidateQueries(['bank-accounts'])
        queryClient.invalidateQueries([
          'distribution-bank-accounts',
          newDistributionAccount?.uuid,
        ])
        queryClient.invalidateQueries([
          'distributions',
          newDistributionAccount?.uuid,
        ])
      }, 3000)
      toast({
        variant: 'success',
        description: 'Distribution settings updated successfully',
        position: 'top',
      })
    })
  }

  const handleFilterUpdate = (uuid: string) => {
    setNewDistributionAccount(undefined)
    reset()
    const nextSearchParams = new URLSearchParams(searchParams)
    if (uuid === 'all') nextSearchParams.delete('investment_account_uuid')
    else nextSearchParams.set('investment_account_uuid', uuid)
    setSearchParams(nextSearchParams)
  }

  return (
    bankAccountsData && (
      <>
        <div
          className="h-10 px-6 justify-start bg-bg-lighter items-center gap-3 flex md:hidden"
          onClick={() => {
            nav('/dashboard/bank-accounts')
          }}
        >
          <button className="h-4 flex-col justify-center items-center inline-flex">
            <i className="fa-solid fa-angle-left" />
          </button>
          <div className="text-base mt-[2px]">Bank Accounts</div>
        </div>
        <div className="w-full h-full p-6 md:p-0">
          <div className="hidden md:inline-block text-[13px] mb-10">
            <button
              className="text-primary"
              onClick={() => nav('/dashboard/bank-accounts')}
            >
              Bank Accounts
            </button>
            <i className="fa-light fa-angle-right mx-2" />
            Edit Distribution Settings
          </div>
          <h1 className="text-27 md:text-31 font-bold self-start mb-10">
            Edit Distribution Settings
          </h1>
          <div className="flex flex-col gap-4 md:gap-0 md:flex-row md:items-center mb-6">
            <span className="mr-2">Manage distributions settings for: </span>
            <Filter
              name="ownership_types"
              updateCallback={handleFilterUpdate}
              data={bankAccountsData.map((item) => {
                return {
                  name: item?.investment_account_name,
                  uuid: item?.investment_account_uuid,
                }
              })}
              defaultValue={
                searchParams.get('investment_account_uuid') || 'all'
              }
            />
          </div>

          <div className="mb-6">
            Select distribution bank accounts for each of your investments.
          </div>

          {data ? (
            <div className="w-full">
              <div className="flex w-full justify-between items-center mb-6">
                <div>
                  {data.length} investments -{' '}
                  {showSelectAll ? (
                    <span
                      className="underline cursor-pointer"
                      onClick={selectAll}
                    >
                      select all
                    </span>
                  ) : (
                    <span
                      className="underline cursor-pointer"
                      onClick={clearAll}
                    >
                      clear selection
                    </span>
                  )}
                </div>
                <div className="hidden md:flex text-nowrap items-center gap-4">
                  <span>Reassign selected to: </span>
                  <Filter
                    name="Bank Account"
                    updateCallback={(account) =>
                      setNewDistributionAccount(
                        allowedBankAccountsData?.find(
                          (item) => item.uuid === account
                        )
                      )
                    }
                    defaultValue={
                      newDistributionAccount
                        ? newDistributionAccount.uuid
                        : 'all'
                    }
                    data={allowedBankAccountsData?.map((item) => {
                      return {
                        name:
                          item.account_name && item.account_type && item.mask
                            ? `${item.account_name} - ${capitalizeFirstLetter(item.account_type)} - *${item.mask}`
                            : item.account_description,
                        uuid: item?.uuid,
                      }
                    })}
                  />
                  <Dialog open={showDialog} onOpenChange={setShowDialog}>
                    <DialogTrigger asChild>
                      <Button
                        className="hidden md:flex"
                        variant="RM"
                        size="RM"
                        disabled={
                          !watchInvestments?.some(
                            (item?: boolean) => item == true
                          ) || !newDistributionAccount
                        }
                      >
                        Apply
                      </Button>
                    </DialogTrigger>
                    <DialogContent
                      aria-describedby={undefined}
                      className="max-w-[900px]"
                    >
                      <DialogTitle className="text-content-black text-left font-medium text-[22px]">
                        Confirm Distribution Settings
                      </DialogTitle>
                      <div aria-describedby="">
                        <div className="px-4 py-3 bg-bg-lighter w-full rounded flex flex-col gap-2 mt-4 mb-8">
                          <div className="text-sm">New Bank Account</div>
                          <div className="text-base text-content-black font-medium">
                            {newDistributionAccount?.mask
                              ? `${newDistributionAccount?.account_name || 'Bank Name'} - ${newDistributionAccount?.account_type} - xxxx${newDistributionAccount?.mask}`
                              : newDistributionAccount?.account_description}
                          </div>
                        </div>

                        <Controller
                          defaultValue={false}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <>
                              <label
                                className={cn(
                                  'flex gap-2 w-full',
                                  fieldState.error && 'border border-alert'
                                )}
                              >
                                <Checkbox {...field} checked={field.value} />
                                <div className="flex-grow text-base font-normal">
                                  The Issuer(s) of the investment
                                  opportunity(ies) selected above use VeriCheck
                                  to process electronic payments. I have elected
                                  to receive distributions to the account
                                  selected above. I authorize the Issuer(s) of
                                  the investment opportunity(ies) selected above
                                  to credit any future distributions (as
                                  applicable) to the bank account selected
                                  above. Additionally, I authorize debits from
                                  the selected account for any deposits made in
                                  error. This authorization will remain in
                                  effect until I give written notice to cancel
                                  it. ACH Agreement: I agree to be bound by
                                  NACHA Operating Rules as they pertain to this
                                  transaction. I acknowledge that the
                                  origination of ACH transactions from and to My
                                  Account must comply with the provisions of
                                  U.S. law. I certify that I am an authorized
                                  signatory of this bank account and will not
                                  dispute this transaction with my bank; so long
                                  as the transaction corresponds to the terms
                                  indicated in this transaction.
                                </div>
                              </label>
                              {fieldState.invalid && (
                                <div className="text-alert text-sm mt-1">
                                  This field is required
                                </div>
                              )}
                            </>
                          )}
                          name="ach-agreement"
                          control={modalControl}
                        />
                      </div>
                      <div className="mt-2 flex gap-4">
                        <Button
                          variant="RM"
                          size="RM"
                          disabled={!watchACHAgreement}
                          onClick={modalSubmit}
                          data-cy="remove-bank-account-button"
                        >
                          Confirm
                        </Button>
                        <DialogClose asChild>
                          <Button variant="RMSecondary" size="RMSecondary">
                            Cancel
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
              <div className="w-full flex flex-col gap-4 mb-10">
                {data.map((investment, index) => (
                  <label
                    className="w-full flex gap-4 md:gap-8 overflow-hidden border border-bg-light h-[80px] md:min-h-[106px] cursor-pointer"
                    key={investment.investment_uuid}
                  >
                    <div className="min-w-[50px] max-w-[50px] h-full md:min-w-[185px] md:max-w-[185px]">
                      <img
                        className="w-full h-full object-cover md:object-fill"
                        src={investment.image ?? FALLBACK_RM_IMAGE}
                      />
                    </div>
                    <div className="flex flex-col gap-1 text-base justify-center">
                      <span className="md:text-17 font-medium line-clamp-1">
                        {investment.io_name}
                      </span>
                      <span className="hidden md:block text-content-light">
                        Start date {dateIn(investment.start_date ?? '')}
                      </span>
                      <span className="md:hidden text-sm">
                        {investment.investment_account_name}
                      </span>
                      <span className="md:hidden text-sm">checking</span>
                    </div>
                    <div className="ml-auto pr-6 flex items-center justify-center">
                      <span className="hidden md:block mr-8">
                        {investment.mask
                          ? `${investment.bank_account_type} - *${investment.mask}`
                          : investment.bank_account_description}
                      </span>
                      <Controller
                        defaultValue={false}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                        name={`update-distribution-accounts.${index}`}
                        control={control}
                      />
                    </div>
                  </label>
                ))}
              </div>
              <div className="flex md:hidden flex-col gap-6 mb-6">
                <span>Reassign selected to: </span>
                <Filter
                  name="Bank Account"
                  updateCallback={(account) =>
                    setNewDistributionAccount(
                      allowedBankAccountsData?.find(
                        (item) => item.uuid === account
                      )
                    )
                  }
                  defaultValue="all"
                  data={allowedBankAccountsData?.map((item) => {
                    return {
                      name: item.account_description,
                      uuid: item?.uuid,
                    }
                  })}
                />
              </div>
              <div className="flex flex-col md:hidden gap-6 w-full">
                <Button
                  variant="RM"
                  size="RM"
                  disabled={
                    !watchInvestments?.some((item?: boolean) => item == true)
                  }
                  onClick={() => setShowDialog(true)}
                >
                  Apply
                </Button>
                <Button
                  variant="RMSecondary"
                  size="RMSecondary"
                  onClick={(e) => {
                    e.preventDefault()
                    nav('/dashboard/ownership-types')
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-full pt-10 md:py-40 flex justify-center items-center">
              {(isLoading || isFetching) &&
                (searchParams.get('investment_account_uuid') ? (
                  <Loader />
                ) : (
                  <span>Please select an ownership type.</span>
                ))}
              {isError && (
                <EmptyState
                  iconClasses="fa-regular fa-bomb fa-3x text-content-black"
                  copy="There was an error while retrieving your distribution settings."
                  linkCopy="Browse Current Investments"
                  linkTo="https://www.realtymogul.com/investment-opportunities"
                />
              )}
            </div>
          )}
        </div>
      </>
    )
  )
}

export default EditDistributionSettings
