import { useLocation, useNavigate } from 'react-router-dom'
import { format } from 'date-fns/format'

import {
  amountRegexDecimal,
  FALLBACK_RM_IMAGE,
  moneyMask,
} from '@/utils/helpers'

import { ActiveInvestmentMenu } from './edit-menu-active'
import { Badge } from '@/components/ui/badge'
import { InvestmentDataTypes } from '../api/schema'

export const ActiveInvestmentCard = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => {
  const nav = useNavigate()
  const { pathname, search } = useLocation()
  const handleClick = () => {
    nav(`/dashboard/investments/details/${investment.io_uuid}`, {
      state: { previousLocation: pathname + search },
    })
  }

  return (
    <div
      className="w-full flex gap-4 md:gap-8 overflow-hidden hover:bg-bg-lighter cursor-pointer h-[56px] md:h-[106px]"
      onClick={handleClick}
    >
      <div className="min-w-[50px] w-[50px] md:w-[180px]">
        <img
          className="w-full h-full object-cover md:object-fill"
          src={investment.image ?? FALLBACK_RM_IMAGE}
        />
      </div>
      <div className="flex-grow md:w-[85%] flex gap-6 py-4 justify-between">
        <div className="flex md:w-[50%] flex-col gap-1 justify-center">
          <div>
            <p className="text-sm md:text-[17px] font-medium line-clamp-2">
              {investment.name}
            </p>
          </div>
          <div className="hidden md:block text-16">
            <p className="line-clamp-1">{investment.subhead}</p>
          </div>
          <div className="hidden md:block text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        <div className="hidden md:flex flex-col justify-start">
          <Badge variant="active">Active</Badge>
        </div>
        <div className="flex gap-4">
          <div className="text-base gap-2 hidden md:flex flex-col justify-start">
            <div className="flex w-[170px] gap-2 flex-row justify-between text-right font-medium text-16">
              <i className="text-[20px] fa-light fa-money-check-dollar-pen" />
              <div>
                $
                {moneyMask(investment.total_invested ?? '', amountRegexDecimal)}
              </div>
            </div>
            <div className="text-base text-content-light">
              {investment.start_date ? (
                <span className="whitespace-pre">
                  Start date{' '}
                  {format(new Date(investment.start_date ?? ''), 'MM/dd/yyyy')}
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          </div>
          <div className="hidden md:flex w-[170px] gap-2 flex-row justify-between text-right font-medium text-16">
            <i className="ml-5 text-[20px] fa-light fa-hand-holding-dollar" />
            <div>
              $
              {moneyMask(
                investment.total_distributions ?? '',
                amountRegexDecimal
              )}
            </div>
          </div>
          <div onClick={(e) => e.stopPropagation()} className="-mt-3">
            <ActiveInvestmentMenu
              uuid={investment.io_uuid}
              is_reit={investment.is_reit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
