import { useLocation, Link } from 'react-router-dom'
import { cn } from '@/utils/utils'

const SubItem = ({
  icon,
  text,
  location,
  isActive = false,
}: {
  icon: string
  text: string
  location: string
  isActive?: boolean
}) => (
  <Link
    to={location}
    className={cn(
      'p-4 md:py-1 w-full items-center justify-between flex cursor-pointer bg-white md:bg-transparent hover:bg-white border-b border-bg-light last-of-type:border-b-0 md:border-none',
      isActive && 'md:bg-white md:cursor-auto'
    )}
  >
    <div className="flex flex-row items-center gap-2 whitespace-nowrap">
      <i
        className={cn(
          'fa-regular fa-sm',
          `fa-${icon}`,
          isActive && 'md:fa-solid md:text-primary'
        )}
      />
      <div
        className={cn(
          'text-content-black font-normal',
          isActive && 'md:font-medium'
        )}
      >
        {text}
      </div>
    </div>
    <i className="md:hidden fa-light fa-angle-right text-content-light text-16" />
  </Link>
)

export const ProfileSubNav = ({
  handleClick,
}: {
  handleClick?: (screen: string) => void
}) => {
  const location = useLocation()

  return (
    <div className="inline-flex w-full md:w-fit flex-col md:flex-row bg-bg-light border border-bg-light text-16 text-content-black rounded">
      <div
        className="border-b border-b-bg-light md:border-b-0"
        onClick={() => handleClick && handleClick('profile')}
      >
        <SubItem
          icon="info-circle"
          text="Personal Info"
          location="/dashboard/profile"
          isActive={location.pathname === '/dashboard/profile'}
        />
      </div>
      <SubItem
        icon="envelope"
        text="Email & Security"
        location="/dashboard/profile/email"
        isActive={location.pathname === '/dashboard/profile/email'}
      />
      <SubItem
        icon="message"
        text="Communication Preferences"
        location="/dashboard/profile/comm-prefs"
        isActive={location.pathname === '/dashboard/profile/comm-prefs'}
      />
    </div>
  )
}
