import { z } from 'zod'
import { useQuery } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { getDrupalUrl } from '../shared/_api'
import axios from 'axios'
import Footer from '../shared/Footer'
import Loader from '@/components/Loader'
import { EmptyState } from '../dashboard/components/empty-state'
import { useEffect } from 'react'
import { useToast } from '@/hooks/use-toast'

const responseSchema = z.object({ redirect: z.string().nullable() })

const verifyEmail = async (pathname: string) => {
  const response = await axios.get(`${getDrupalUrl()}_${pathname}`)
  return responseSchema.parse(response.data)
}

export const EmailVerification = () => {
  const { pathname } = useLocation()
  const nav = useNavigate()
  const { toast } = useToast()

  const { data, isLoading, isError } = useQuery(
    ['email-verification'],
    () => verifyEmail(pathname),
    { staleTime: Infinity, retry: false }
  )

  useEffect(() => {
    if (!data) return
    if (data.redirect) {
      nav(data.redirect)
      toast({
        variant: 'success',
        description: 'Your account has been verified.',
        position: 'top',
      })
    }
  }, [data, nav, toast])

  return (
    <div className="flex h-full w-full flex-col items-center">
      <div className="w-full">
        <div className="max-w-[1400px] w-full bg-white px-6 py-4 mx-auto">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <img className="mr-4" src="/rm.svg" />
              <span className="text-content-dark text-16 font-medium uppercase tracking-[0.15em]">
                RealtyMogul
              </span>
            </div>
          </div>
        </div>
      </div>
      {data ? (
        <>
          <div className="p-6 xl:p-[60px] h-[60vh] w-full xl:max-w-[1000px] text-content-black">
            <h1 className="text-[30px] mb-6 font-bold">
              Your email is verified.
            </h1>
            <p className="mb-6">
              Thank you for verifying your email address. You are now able to
              begin investing in our real estate offerings.
            </p>
            <a
              href="https://www.realtymogul.com/investment-opportunities"
              className="flex items-center h-10 w-full md:w-fit justify-center rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] md:min-w-[200px]"
            >
              Browse Current Investments
            </a>
          </div>
        </>
      ) : (
        <div className="h-[60vh] w-full flex justify-center items-center">
          {isLoading && <Loader />}
          {isError && (
            <EmptyState
              iconClasses="fa-regular fa-bomb fa-3x text-content-black"
              copy="There was an error while attempting to verify your email."
              linkCopy="Browse Current Investments"
              linkTo="https://www.realtymogul.com/investment-opportunities"
            />
          )}
        </div>
      )}
      <div className="w-full bg-black">
        <Footer />
      </div>
    </div>
  )
}
