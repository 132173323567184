import { create } from 'zustand'

type EmailStore = {
  updatedEmail: string
  updateEmail: (email: string) => void
}

export const useEmailStore = create<EmailStore>((set) => ({
  updatedEmail: '',
  updateEmail: (email) => set({ updatedEmail: email }),
}))
