interface ContactUsProps {
  links: {
    label: string
    to: string | (() => void)
  }[]
}

export const ContactUs = ({ links }: ContactUsProps) => {
  return (
    <div className="w-full px-6 md:px-0">
      <div className="w-full flex flex-col md:flex-row border-t border-[#E7E7E7] p-6 md:px-0 items-start md:items-center box-border">
        <div className="text-[17px] font-medium mb-4 md:mb-0">Contact us</div>
        <div className="md:ml-6 flex-1">
          {links.map((link, index) =>
            typeof link.to !== 'string' ? (
              <button
                className="text-base md:px-4 py-2 cursor-pointer inline-flex items-center mb-2 md:mb-0"
                key={index}
                onClick={(e) => {
                  e.preventDefault()
                  if (typeof link.to === 'function') {
                    link.to()
                  }
                }}
              >
                <span>{link.label}</span>
                <i className="text-primary w-2 h-4 ml-2 fa-regular fa-angle-right" />
              </button>
            ) : (
              <a
                className="text-base md:px-4 py-2 cursor-pointer inline-flex items-center"
                key={index}
                href={link.to}
              >
                <span>{link.label}</span>
                <i className="text-primary w-2 h-4 ml-2 fa-regular fa-angle-right" />
              </a>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default ContactUs
