import { Skeleton } from '@/components/ui/skeleton'

export const SkeletonInvestmentCard = () => {
  return (
    <div className="w-full flex gap-8 overflow-hidden hover:bg-bg-lighter cursor-pointer h-[106px]">
      <div className="w-[180px] bg-bg-light"></div>
      <div className="w-[85%] flex gap-6 py-4">
        <div className="flex w-[50%] flex-col gap-1 justify-center">
          <div>
            <Skeleton className="h-4 w-[100px]" />
          </div>
          <Skeleton className="h-4 w-[85px]" />
          <Skeleton className="h-4 w-[25px]" />
        </div>

        <Skeleton className="h-4 w-[60px]" />
        <Skeleton className="h-4 w-[20%]" />
        <Skeleton className="h-4 w-[20%]" />
      </div>
    </div>
  )
}
