import { ReactNode } from 'react'
import { format } from 'date-fns'

import { InvestmentDataTypes } from '../api/schema'
import { formatCurrency } from '@/utils/helpers'

import { Badge } from '@/components/ui/badge'
import { InfoTooltip } from '../../components/info-tooltip'

const Row = ({
  label,
  value,
  icon,
}: {
  label: string | ReactNode
  value: string | number | ReactNode
  icon: string
}) => (
  <div className="w-full flex justify-between text-16">
    <div className="h-full flex items-center">
      <div className="w-[25px] h-[20px] flex justify-center items-center">
        <div className="text-center text-xl">
          <i className={`fa-light fa-fw fa-${icon}`} />
        </div>
      </div>
      <div className="ml-2">{label}</div>
    </div>
    <div className="font-medium ml-4">{value}</div>
  </div>
)

export const InvestmentStatistics = ({
  data,
}: {
  data: InvestmentDataTypes
}) => {
  return (
    <div className="flex flex-col w-full max-w-[440px] ">
      {data.status.toLowerCase() === 'active' && !data.is_reit && (
        <div className="flex flex-col w-full gap-4 justify-center">
          <Row
            icon="clipboard"
            label="Investment Status"
            value={<Badge variant="active">Active</Badge>}
          />
          <Row
            icon="calendar-clock"
            label="Start / Accrual Date"
            value={format(new Date(data.start_date ?? ''), 'MM/dd/yyyy')}
          />
          <Row
            icon="money-check-dollar-pen"
            label="Invested Amount"
            value={formatCurrency(data.total_invested ?? '')}
          />
          <Row
            icon="hand-holding-dollar"
            label={
              <div>
                Cash Distributions Paid
                <InfoTooltip className="ml-1">
                  Distributions represent the cash distributions authorized and
                  distributed by the Company and may be inclusive of returned
                  principal.
                </InfoTooltip>
              </div>
            }
            value={formatCurrency(data.total_distributions ?? '')}
          />
        </div>
      )}
      {data.status.toLowerCase() === 'active' && data.is_reit && (
        <div className="flex flex-col w-full gap-4 justify-center item-center">
          <Row
            icon="calendar-clock"
            label="Start / Accrual Date"
            value={format(new Date(data.start_date ?? ''), 'MM/dd/yyyy')}
          />
          <Row icon="layer-group" label="Shares" value={data.shares} />
          <Row
            icon="tag"
            label={
              <div>
                NAV Per Share
                <InfoTooltip className="ml-1">
                  The goal of the Net Asset Value (NAV) calculation is to
                  provide a reasonable estimate of the value of the REIT's
                  common shares. This valuation is subject to a number of
                  subjective judgments and assumptions. Review the terms of the
                  Offering Circular for more information.
                </InfoTooltip>
              </div>
            }
            value={`${formatCurrency(data.nav ?? '')}`}
          />
          <Row
            icon="coins"
            label={
              <div>
                Current Investment Value
                <InfoTooltip className="ml-1">
                  The product of the number of REIT shares that you currently
                  own and their most recent available Net Asset Value (NAV) per
                  share. This does not take into account any repurchase
                  discounts or costs. Please note that the calculation of NAV
                  includes estimates. Review the terms of the Offering Circular
                  for more information.
                </InfoTooltip>
              </div>
            }
            value={`${formatCurrency(data.investment_value ?? '')}`}
          />
          <Row
            icon="calendar"
            label="Annualized Distribution"
            value={data.annualized_distribution_rate}
          />
          <Row
            icon="clock"
            label="Distribution Frequency"
            value={data.distribution_frequency}
          />
          <Row
            icon="money-check-dollar-pen"
            label={
              <div>
                Current Total Invested
                <InfoTooltip className="ml-1">
                  Total amount invested in the REIT by you, as well as any
                  reinvested REIT distributions.
                </InfoTooltip>
              </div>
            }
            value={`${formatCurrency(data.total_invested ?? '0')}`}
          />
          <Row
            icon="hand-holding-dollar"
            label={
              <div>
                Cash Distributions Paid
                <InfoTooltip className="ml-1">
                  Distributions represent the cash distributions authorized and
                  distributed by the Company and may be inclusive of returned
                  principal.
                </InfoTooltip>
              </div>
            }
            value={`${formatCurrency(data.cash_distributions ?? '0')}`}
          />
          <Row
            icon="money-check-dollar-pen"
            label={
              <div>
                Distribution Reinvested
                <InfoTooltip className="ml-1">
                  Total dollar value of distributions that were used to purchase
                  new shares at the time of reinvestment. In the event that
                  shares marked as reinvested distributions are redeemed, the
                  redeemed dollar amount will be deducted from this value.
                </InfoTooltip>
              </div>
            }
            value={`${formatCurrency(data.distributions_reinvested ?? '0')}`}
          />
        </div>
      )}
      {data.status.toLowerCase() === 'completed' && (
        <div className="flex flex-col w-full gap-4 justify-center">
          <Row
            icon="clipboard"
            label="Investment Status"
            value={<Badge variant="auto-invest-off">Completed</Badge>}
          />
          <Row
            icon="money-check-dollar-pen"
            label="Invested Amount"
            value={formatCurrency(data.total_invested ?? '')}
          />
          <Row
            icon="dollar"
            label={data.is_reit ? 'Total Distributed' : 'Total Returned'}
            value={formatCurrency(data.total_distributions ?? '')}
          />
          {!data.is_reit && (
            <Row
              icon="chart-line"
              label={
                <div>
                  Actual IRR / Return
                  <InfoTooltip className="ml-1">
                    The return figure represents the offering's annualized
                    return. Your investment's annualized return may be slightly
                    higher or lower depending on the timing of your investment
                    and other factors.
                  </InfoTooltip>
                </div>
              }
              value={
                data.return_calculated
                  ? `${data.return_calculated}%`
                  : 'Not Available'
              }
            />
          )}
          <Row
            icon="calendar-clock"
            label="Start / Accrual Date"
            value={
              data.start_date
                ? format(new Date(data.start_date ?? ''), 'MM/dd/yyyy')
                : 'N/A'
            }
          />
          {!data.is_reit && (
            <Row
              icon="calendar-check"
              label={
                <div>
                  Maturity Date
                  <InfoTooltip className="ml-1">
                    The date of sale for the property.
                  </InfoTooltip>
                </div>
              }
              value={
                data.maturity_date
                  ? format(new Date(data.maturity_date ?? ''), 'MM/dd/yyyy')
                  : 'N/A'
              }
            />
          )}
        </div>
      )}
    </div>
  )
}
