import { useNavigate } from 'react-router-dom'
import { InvestmentDataTypes } from '../api/schema'
import { Badge } from '@/components/ui/badge'

export const DetailsHistory = ({
  data,
  hasNewMessage = false,
}: {
  data: InvestmentDataTypes
  hasNewMessage: boolean
}) => {
  const nav = useNavigate()

  return (
    <div className="w-full flex flex-col gap-3">
      <div
        className="w-full border border-bg-light inline-flex rounded px-3 py-2 cursor-pointer justify-between hover:bg-bg-light"
        onClick={() =>
          nav(`/dashboard/messages?page=1&io_uuid=${data.io_uuid}`)
        }
      >
        <div className="inline-flex gap-3 items-center">
          <div className="w-[22.5px] text-center">
            <i className="fa-light fa-envelope fa-lg" />
          </div>
          <div className="text-base">
            View <span className="font-medium">messages</span> for this
            investment
          </div>
        </div>
        <div>{hasNewMessage ? <Badge variant="RM">New</Badge> : null}</div>
      </div>
      <div
        className="w-full border border-bg-light inline-flex items-center gap-3 rounded px-3 py-2 cursor-pointer hover:bg-bg-light"
        onClick={() => nav(`/dashboard/taxes?page=1&io_uuid=${data.io_uuid}`)}
      >
        <div className="w-[22.5px] text-center">
          <i className="fa-light fa-files fa-lg" />
        </div>
        <div className="text-base">
          View <span className="font-medium">tax documents</span> for this
          investment
        </div>
      </div>
      <div
        className="w-full border border-bg-light inline-flex items-center gap-3 rounded px-3 py-2 cursor-pointer hover:bg-bg-light"
        onClick={() =>
          nav(`/dashboard/transactions?page=1&io_uuid=${data.io_uuid}`)
        }
      >
        <div className="w-[22.5px] text-center">
          <i className="fa-light fa-rectangle-list fa-lg" />
        </div>
        <div className="text-base">
          View <span className="font-medium">transactions</span> for this
          investment
        </div>
      </div>
    </div>
  )
}
