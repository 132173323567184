import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { api, getSignedS3Url, uploadToSignedS3 } from '../../shared/_api'
import { useMobileNavStore } from '../api/use-mobile-nav-store'
import { useToast } from '@/hooks/use-toast'

import NumberInput from '../../../components/NumberInput'
import TextInput from '../../../components/TextInput'
import FileUpload from '../../../components/FileUpload'
import Radio from '../../../components/Radio'
import Checkbox from '../../../components/Checkbox'
import { Button } from '@/components/ui/button'
import { useQueryClient } from '@tanstack/react-query'

const AddManualBankAccount = () => {
  const [showUploadForm, setShowUploadForm] = useState<boolean>(true)
  const { uuid } = useParams()
  const { toast } = useToast()
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      account_type: 'checking',
      account_number: '',
      routing_number: '',
      account_name: '',
      verification_file_uuid: '',
    },
  })

  useEffect(() => updateMobileNav(false), [updateMobileNav])

  const onSubmit = handleSubmit(async (data) => {
    const newAccount = {
      account_type: data.account_type,
      account_number: data.account_number,
      routing_number: data.routing_number,
      account_name: data.account_name,
      verification_file_uuid: showUploadForm
        ? data.verification_file_uuid
        : null,
      investment_account_uuid: uuid,
    }
    api.post('/dashboard/bank-accounts/', newAccount).then(() => {
      toast({
        variant: 'success',
        description: 'Bank account added successfully',
        position: 'top',
      })
      nav('/dashboard/bank-accounts')
      queryClient.invalidateQueries(['bank-accounts'])
      queryClient.invalidateQueries(['distribution-bank-accounts', uuid])
    })
  })

  return (
    <div className="w-full">
      <div
        className=" h-10 px-6 justify-start bg-bg-lighter items-center gap-3 flex md:hidden"
        onClick={() => {
          updateMobileNav(true)
          nav('/dashboard/bank-accounts')
        }}
      >
        <button className="h-4 flex-col justify-center items-center inline-flex">
          <i className="fa-solid fa-angle-left" />
        </button>
        <div className="text-base mt-[2px]">Bank Accounts</div>
      </div>
      <div className="w-full p-6 md:p-0">
        <div className="hidden md:inline-block text-[13px] mb-10">
          <button
            className="text-primary"
            onClick={() => nav('/dashboard/bank-accounts')}
          >
            Bank Accounts
          </button>
          <i className="fa-light fa-angle-right mx-2" />
          <button
            className="text-primary"
            onClick={() => nav('/dashboard/bank-accounts/new')}
          >
            Link New Bank Account
          </button>
          <i className="fa-light fa-angle-right mx-2" />
          Add New Bank Account Manually
        </div>
        <h1 className="mb-10 text-27 md:text-31 font-bold text-content-black">
          Add New Bank Account
        </h1>
        <p className="mb-10 text-16 text-content-black">
          Add a new external bank account to fund your investments.
        </p>
        <form onSubmit={onSubmit} className="xl:max-w-[312px]">
          <div className="mb-5">
            <p className="mb-2 text-xl font-bold text-content-black">
              Account Info
            </p>
          </div>
          <p className="mb-2 text-sm">Account Type</p>
          <Controller
            name="account_type"
            control={control}
            render={({ field }) => (
              <div className="mb-6 flex flex-col gap-px first:bg-red-50">
                <Radio
                  {...field}
                  value="checking"
                  label="Checking"
                  checked={field.value === 'checking'}
                />
                <Radio
                  {...field}
                  value="savings"
                  label="Savings"
                  checked={field.value === 'savings'}
                />
              </div>
            )}
          />
          <div className="mb-6">
            <Controller
              name="account_name"
              control={control}
              render={({ field, fieldState }) => (
                <TextInput
                  label="Account Name"
                  errorMessage={fieldState.error}
                  placeholder="New Account Label"
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-6">
            <Controller
              name="routing_number"
              control={control}
              rules={{
                required: 'is required',
                pattern: {
                  value: /^[0-9]{9}$/,
                  message: 'Must be exactly 9 digits',
                },
              }}
              render={({ field, fieldState }) => (
                <NumberInput
                  label="Routing Number"
                  errorMessage={fieldState.error}
                  maxLength={9}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-6">
            <Controller
              name="account_number"
              control={control}
              rules={{ required: 'is required' }}
              render={({ field, fieldState }) => (
                <NumberInput
                  label="Account Number"
                  errorMessage={fieldState.error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-5">
            <p className="mb-2 text-xl font-bold text-content-black">
              Check Image
            </p>
          </div>
          <p className="mb-6 text-sm">
            To verify ownership of this account, please provide an image of a
            voided check.
          </p>
          <div className="mb-6">
            <Checkbox
              name="upload-check-later"
              label="I will provide a copy of a voided check later. I acknowledge that my investment will not be processed until I provide the voided check."
              onChange={() => {
                setShowUploadForm(!showUploadForm)
              }}
            />
          </div>
          {showUploadForm && (
            <Controller
              name="verification_file_uuid"
              control={control}
              rules={{ required: 'File is required' }}
              render={({ field, fieldState }) => (
                <div className="mb-6">
                  <FileUpload
                    {...field}
                    accept=".pdf, .jpg, .png, .gif, .jpeg"
                    label="Upload Check"
                    uploadFileFunction={async (file: File) => {
                      const signedS3Resp = await getSignedS3Url({
                        fileName: file.name,
                        type: 'check',
                      })
                      if (signedS3Resp.status === 200) {
                        const { fields, url, s3_obj_id } = signedS3Resp.data
                        const uploadResp = await uploadToSignedS3(
                          file,
                          url,
                          fields
                        )
                        console.log(uploadResp)
                        setValue('verification_file_uuid', s3_obj_id)
                      }
                    }}
                  />
                  {fieldState.error && (
                    <span className="mb-1 text-xs text-alert">
                      {fieldState.error.message}
                    </span>
                  )}
                  <p className="mt-4 text-center text-[13px]">
                    Upload a JPG, PNG, GIF or PDF.
                  </p>
                </div>
              )}
            />
          )}
          <div className="flex flex-col md:flex-row gap-6 mb-6">
            <Button variant="RM" size="RM" onClick={onSubmit}>
              Save
            </Button>
            <Link to="/dashboard/bank-accounts">
              <Button
                variant="RMSecondary"
                size="RM"
                onClick={() => updateMobileNav(true)}
              >
                Cancel
              </Button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddManualBankAccount
