import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { accountsSchema, AccountsDataTypes } from './schema'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { datadogLogs } from '@datadog/browser-logs'

const accountResponseSchema = z.object({
  total_investment_count: z.number().int().min(0),
})

export const getAccounts = async (params: URLSearchParams) => {
  const response = await api.get('/dashboard/investment-accounts/', {
    params,
  })
  const parsedData = accountsSchema.safeParse(response)

  const accountsDataWithCount = async (data: AccountsDataTypes) => {
    const accountsDataWithCount = await Promise.all(
      data.accounts.map(async (account) => {
        const accountResponse = await api.get(
          `/dashboard/investment-accounts/${account.uuid}/active-investments`
        )
        const responseData = accountResponseSchema.parse(accountResponse)
        return {
          ...account,
          total_investment_count: responseData.total_investment_count,
        }
      })
    )
    return { ...data, accounts: accountsDataWithCount }
  }

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-ownership-types',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof accountsSchema
    >
    return accountsDataWithCount(unsafeUnparsedData)
  } else {
    return accountsDataWithCount(parsedData.data)
  }
}

export const useOwnershipTypes = () => {
  const [searchParams] = useSearchParams()

  return useQuery<AccountsDataTypes>({
    queryKey: ['ownership-types', searchParams.get('page')],
    queryFn: () => getAccounts(searchParams),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
