import { ReactNode, useState } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { PopoverArrow } from '@radix-ui/react-popover'
import { cn } from '@/utils/utils'

export const InfoTooltip = ({
  children,
  className,
  target = null,
}: {
  children: ReactNode | string
  target?: ReactNode | string
  className?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className={cn(
          target
            ? 'inline-block'
            : 'w-6 h-6 inline-flex justify-center items-center group outline-none',
          className
        )}
      >
        {target ? (
          <span>{target}</span>
        ) : (
          <i className="fa-light fa-info-circle text-sm text-content-light group-hover:text-primary-hover" />
        )}
      </PopoverTrigger>
      <PopoverContent side="bottom" className="p-6 text-base max-w-[330px]">
        <PopoverArrow
          style={{
            fill: '#FFF',
            filter: 'drop-shadow(0 0 3px gray)',
            clipPath: 'inset(0 -10px -10px -10px)',
          }}
        />
        {children}
      </PopoverContent>
    </Popover>
  )
}
