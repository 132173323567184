import { Controller, useForm } from 'react-hook-form'

import { usePatchProfile } from '../api/patch-profile'
import { NameTypes } from '../api/schema'
import { useToast } from '@/hooks/use-toast'

import { Button } from '@/components/ui/button'
import ErrorAlert from '@/components/ErrorAlert'
import TextInput from '@/components/TextInput'

export const UpdateName = ({
  nameData,
  callback,
}: {
  nameData: NameTypes
  callback: (screen: string) => void
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<NameTypes>({
    defaultValues: {
      first_name: nameData?.first_name || '',
      last_name: nameData?.last_name || '',
    },
  })

  const { mutateAsync: patchProfile, isLoading } = usePatchProfile()
  const { toast } = useToast()

  const onFormSubmit = handleSubmit((data: NameTypes) => {
    if (!data) return
    console.log(errors, data)
    patchProfile(data).then(() => {
      toast({
        variant: 'success',
        description: 'Name updated.',
        position: 'top',
      })
      callback('')
    })
  })

  return (
    <div className="mt-10">
      <div className="max-w-[312px]">
        <form
          onSubmit={onFormSubmit}
          autoComplete="on"
          className="w-full mb-10"
        >
          {Object.keys(errors).length > 0 && (
            <ErrorAlert text="Please fix the error(s) below:" />
          )}
          <div className="mb-2">
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: 'is required',
                maxLength: { value: 40, message: '40 characters maximum' },
              }}
              render={({ field, fieldState }) => (
                <TextInput
                  label="First Name"
                  errorMessage={fieldState.error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-10">
            <Controller
              name="last_name"
              control={control}
              rules={{
                required: 'is required',
                maxLength: { value: 80, message: '80 characters maximum' },
              }}
              render={({ field, fieldState }) => (
                <TextInput
                  label="Last Name"
                  errorMessage={fieldState.error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <Button
              variant="RM"
              size="RM"
              disabled={isLoading || !isDirty}
              onClick={onFormSubmit}
            >
              Update
            </Button>
            <Button
              variant="RMSecondary"
              size="RMSecondary"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault()
                callback('')
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
