// import { z } from 'zod'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { useQueryClient } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { useToast } from '@/hooks/use-toast'

export const WaitlistInvestmentMenu = ({ url }: { url: string }) => {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  return (
    <Popover>
      <PopoverTrigger>
        <i className="px-4 md:px-6 md:py-4 fa-regular fa-ellipsis-vertical"></i>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="end"
        sideOffset={-55}
        avoidCollisions={false}
        className="w-[187px] text-base px-0 py-2 rounded flex flex-col"
      >
        <Dialog>
          <DialogTrigger asChild>
            <div className="w-full text-left px-4 py-3 hover:bg-bg-light">
              Remove from List
            </div>
          </DialogTrigger>
          <DialogContent
            aria-describedby={undefined}
            className="text-content-black text-left"
          >
            <DialogTitle className="text-content-black font-medium text-[22px] mb-10">
              Remove this investment?
            </DialogTitle>
            <div className="text-16 mb-10">
              The investment will be removed from your waitlist.
            </div>
            <div className="flex gap-4">
              <Button
                variant="RM"
                size="RM"
                onClick={() => {
                  api.get(url).then(() => {
                    queryClient.invalidateQueries(['investments'])
                    toast({
                      description: 'Investment removed from waitlist',
                      variant: 'success',
                      position: 'top',
                    })
                  })
                }}
              >
                Yes - remove it
              </Button>
              <Button variant="RMSecondary" size="RMSecondary">
                No - keep it
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </PopoverContent>
    </Popover>
  )
}
