import { cn } from '@/utils/utils'
import { ReactNode } from 'react'

type ProfileItemProps = {
  label: string
  value: string | ReactNode
  isLocked?: boolean
  noEdit?: boolean
  callback?: (screen: string) => void
}

export const ProfileItem = ({
  label,
  value,
  isLocked = false,
  noEdit = false,
  callback,
}: ProfileItemProps) => {
  return (
    <div
      className={cn(
        'w-full px-6 py-4 min-h-[78px] flex justify-between items-center group',
        !isLocked && !noEdit && 'hover:bg-bg-lighter cursor-pointer'
      )}
      onClick={() => !isLocked && callback && callback(label)}
    >
      <div className="flex flex-col text-base gap-2 line-clamp-1 overflow-ellipsis">
        <div className="text-sm">{label}</div>
        <div className="font-medium">{value ? value : '-'}</div>
        {isLocked && (
          <div className="text-sm text-content-light">
            If you need to update this information, please contact us.
          </div>
        )}
      </div>
      {!isLocked && !noEdit && (
        <div className="hidden group-hover:block text-base px-2">Edit</div>
      )}
      {isLocked && (
        <div>
          <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 7V4.75C3.5 2.28906 5.50391 0.25 8 0.25C10.4609 0.25 12.5 2.28906 12.5 4.75V7H13.0625C14.6094 7 15.875 8.26562 15.875 9.8125V15.4375C15.875 17.0195 14.6094 18.25 13.0625 18.25H2.9375C1.35547 18.25 0.125 17.0195 0.125 15.4375V9.8125C0.125 8.26562 1.35547 7 2.9375 7H3.5ZM4.625 7H11.375V4.75C11.375 2.88672 9.86328 1.375 8 1.375C6.10156 1.375 4.625 2.88672 4.625 4.75V7ZM1.25 15.4375C1.25 16.3867 1.98828 17.125 2.9375 17.125H13.0625C13.9766 17.125 14.75 16.3867 14.75 15.4375V9.8125C14.75 8.89844 13.9766 8.125 13.0625 8.125H2.9375C1.98828 8.125 1.25 8.89844 1.25 9.8125V15.4375Z"
              fill="#202020"
            />
          </svg>
        </div>
      )}
    </div>
  )
}
