import { useEffect, useState } from 'react'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

import { cn } from '@/utils/utils'
import { dateIn } from '@/utils/helpers'
import { formatAddress } from '../shared/format-address'
import { useCommPrefs } from './api/get-comm-prefs'
import { useMobileNavStore } from '../api/use-mobile-nav-store'
import { useProfile } from '@/routes/dashboard/profile/api/get-profile'

import { EmptyState } from '../components/empty-state'
import { Loader } from '@/components/Loader'
import { ProfileItem } from './components/profile-item'
import { ProfileSubNav } from './components/profile-subnav'

import { UpdateAddress } from './components/update-address'
import { UpdateName } from './components/update-name'
import { UpdateBirthdate } from './components/update-birthdate'
import { UpdatePhone } from './components/update-phone'

const getPhoneNumber = (phoneNumber: string) => {
  if (isValidPhoneNumber(phoneNumber)) {
    return formatPhoneNumber(phoneNumber)
  }
  return phoneNumber
}

const Profile = () => {
  const [screen, setScreen] = useState('')
  const [mobileScreen, setMobileScreen] = useState('')
  const { data, isLoading, isError } = useProfile()
  const { data: commPrefsData } = useCommPrefs()
  const isLocked = data?.has_approved_investments

  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)

  useEffect(
    () => updateMobileNav(!mobileScreen ? true : false),
    [mobileScreen, updateMobileNav]
  )

  return data && commPrefsData ? (
    <>
      <div
        className={cn(
          'h-10 px-6 justify-start bg-bg-lighter items-center gap-3 hidden',
          mobileScreen && 'flex md:hidden'
        )}
        onClick={() => {
          if (screen) setScreen('')
          else setMobileScreen('')
        }}
      >
        <button className="h-4 flex-col justify-center items-center inline-flex">
          <i className="fa-solid fa-angle-left" />
        </button>
        <div className="text-base mt-[2px]">
          {' '}
          {screen ? 'Personal Info' : 'Profile'}
        </div>
      </div>
      <div className="w-full h-full p-6 md:p-0">
        {screen && (
          <div className="hidden md:inline-block text-[13px] mb-10">
            <button className="text-primary" onClick={() => setScreen('')}>
              Profile
            </button>
            <i className="fa-light fa-angle-right mx-2" />
            Update {screen}
          </div>
        )}
        <h1 className="text-27 md:text-31 font-bold self-start">
          {!screen
            ? mobileScreen
              ? 'Personal Info'
              : 'Profile'
            : `Update ${screen}`}
        </h1>
        {!screen && (
          <div>
            <div className={cn('my-10', mobileScreen && 'hidden')}>
              <ProfileSubNav
                handleClick={(screen) => setMobileScreen(screen)}
              />
            </div>
            <div
              className={cn(
                'hidden mt-6 md:block border rounded border-border-normal',
                mobileScreen && 'block'
              )}
            >
              <div className="border-b border-bg-light">
                <ProfileItem
                  label="Name"
                  value={`${data.first_name} ${data.last_name}`}
                  isLocked={isLocked}
                  callback={setScreen}
                />
              </div>
              <div className="border-b border-bg-light">
                <ProfileItem
                  label="Date of Birth"
                  value={`${data.birthdate ? dateIn(data.birthdate) : ''}`}
                  isLocked={isLocked}
                  callback={setScreen}
                />
              </div>
              <div className="border-b border-bg-light">
                <ProfileItem
                  label="Phone"
                  value={getPhoneNumber(data.phone_number ?? '')}
                  callback={setScreen}
                />
              </div>
              <div>
                <ProfileItem
                  label="Address"
                  value={data.address ? formatAddress(data.address) : ''}
                  callback={setScreen}
                />
              </div>
            </div>
          </div>
        )}
        {screen === 'Name' && (
          <UpdateName
            nameData={{
              first_name: data.first_name,
              last_name: data.last_name,
            }}
            callback={setScreen}
          />
        )}
        {screen === 'Date of Birth' && (
          <UpdateBirthdate
            dobData={{ birthdate: data.birthdate }}
            callback={setScreen}
          />
        )}
        {screen === 'Phone' && (
          <UpdatePhone
            data={{ phone_number: data.phone_number }}
            callback={setScreen}
          />
        )}
        {screen === 'Address' && (
          <UpdateAddress
            addressData={data.address ?? undefined}
            callback={setScreen}
          />
        )}
      </div>
    </>
  ) : (
    <div className="flex justify-center items-center w-full h-[35vh]">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your profile settings."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default Profile
