import { useRef, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { useInvestDataState } from '../Invest/_investDataState'
import { cancelInvestment, getDrupalUrl } from './_api'
import { track } from '../../utils/analytics'
import { useFeatureFlags } from '@/rules/get-feature-flags'

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false)
  const saveRef = useRef<HTMLDialogElement>(null)
  const cancelRef = useRef<HTMLDialogElement>(null)
  const queryClient = useQueryClient()
  const locationObj = useLocation()
  const { data: featureFlagsData } = useFeatureFlags()

  const removeBothButtons = ['failed', 'complete', 'submitted'].some((path) =>
    locationObj.pathname.includes(path)
  )
  const removeCancelButton = ['start', 'review', 'verification'].some((path) =>
    locationObj.pathname.includes(path)
  )

  const { data: investData } = useInvestDataState()
  const data = queryClient.getQueryData(['auth'])

  return (
    <div className="w-full border-b">
      <div className="max-w-[1400px] primary bg-white px-6 py-4 mx-auto">
        {!investData?.investmentCancelled ? (
          <div className="flex w-full items-center justify-between relative">
            <div className="flex flex-row items-center">
              <img className="mr-4" src="/rm.svg" />
              <span className="text-content-dark text-16 font-medium uppercase tracking-[0.15em]">
                Invest
              </span>
            </div>
            <button
              className="flex items-center font-medium md:hidden"
              onClick={(e) => {
                e.preventDefault()
                setShowMenu((oldData) => !oldData)
              }}
            >
              Exit
              <div className="ml-3">
                <div
                  className="ease-in duration-75"
                  style={{
                    transform: showMenu ? 'rotate(180deg)' : 'none',
                  }}
                >
                  <i className="fa-solid fa-chevron-down" />
                </div>
              </div>
            </button>
            <div className="flex-row gap-1 md:gap-4 hidden md:flex">
              {!!data && (
                <span
                  className="cursor-pointer text-primary text-[12px] md:text-16 hover:text-primary-hover"
                  onClick={(e) => {
                    e.preventDefault()
                    Auth.signOut()
                    location.reload()
                  }}
                >
                  Log out
                </span>
              )}
              {investData?.investment &&
                !removeCancelButton &&
                !removeBothButtons && (
                  <span
                    className="cursor-pointer text-primary text-[12px] md:text-16 hover:text-primary-hover"
                    onClick={(e) => {
                      e.preventDefault()
                      cancelRef?.current?.showModal()
                    }}
                  >
                    Cancel Investment
                  </span>
                )}
              {!removeBothButtons && (
                <span
                  className="cursor-pointer text-primary text-[12px] md:text-16 hover:text-primary-hover"
                  onClick={(e) => {
                    e.preventDefault()
                    saveRef?.current?.showModal()
                  }}
                >
                  Finish Later
                </span>
              )}
            </div>
            <dialog
              className="p-0"
              ref={cancelRef}
              onClick={(event) => {
                if (event?.target === cancelRef?.current) {
                  cancelRef?.current?.close()
                }
              }}
            >
              <div className="md:px-8 px-6 py-8 w-full md:w-[500px] shadow-[0_0_20px_0_rgba(0,0,0,0.65)]">
                <h2 className="mb-6 text-[17px] font-medium text-content-black">
                  Cancel Investment?
                </h2>
                <p className="mb-6 font-sans text-16 font-normal text-content-black">
                  Your progress will be lost and your investment will be
                  canceled.
                </p>
                <div className="flex flex-col md:flex-row gap-4">
                  <button
                    className="h-10 w-full md:w-fit px-4 rounded bg-primary text-16 font-medium hover:bg-primary-hover text-[#F2F2F2]"
                    data-cy="cancel-investment"
                    onClick={async (e) => {
                      e.preventDefault()
                      track('Cancel Investment')
                      cancelInvestment(investData?.investment?.id).then(() => {
                        queryClient.invalidateQueries([
                          'invest',
                          investData?.opportunity?.id,
                        ])
                        cancelRef?.current?.close()
                        window.location.href = `${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`
                      })
                    }}
                  >
                    Cancel Investment
                  </button>
                  <button
                    className="h-10 w-full md:w-fit px-4 rounded border border-primary bg-white py-2 text-16 font-medium text-primary"
                    onClick={(e) => {
                      e.preventDefault()
                      cancelRef?.current?.close()
                    }}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </dialog>
            <dialog
              className="p-0"
              ref={saveRef}
              onClick={(event) => {
                if (event?.target === saveRef?.current) {
                  saveRef?.current?.close()
                }
              }}
            >
              <div className="md:px-8 px-6 py-8">
                <h2 className="mb-6 text-xl font-medium text-content-black">
                  Save and exit?
                </h2>
                <p className="mb-6 font-sans text-16 font-normal text-content-black">
                  Your progress will be saved for you to complete later.
                </p>
                <div className="flex flex-col md:flex-row gap-4">
                  <button
                    className="h-10 w-full md:w-fit px-4 rounded bg-primary text-16 font-medium hover:bg-primary-hover text-[#F2F2F2]"
                    onClick={(e) => {
                      e.preventDefault()
                      track('Save Investment Clicked')
                      window.location.href = window.location.origin.includes(
                        '.rtml.localhost'
                      )
                        ? 'http://www.rtml.localhost/investment-opportunities'
                        : 'https://www.realtymogul.com/investment-opportunities'
                    }}
                  >
                    Save & Exit
                  </button>
                  <button
                    className="h-10 w-full md:w-fit px-4 rounded border border-primary bg-white py-2 text-16 font-medium text-primary"
                    onClick={(e) => {
                      e.preventDefault()
                      saveRef?.current?.close()
                    }}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </dialog>
          </div>
        ) : (
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <img className="mr-4" src="/rm.svg" />
              <span className="text-content-dark text-16 font-medium uppercase tracking-[0.15em]">
                Invest
              </span>
            </div>
          </div>
        )}
      </div>
      {showMenu && (
        <div className="absolute top-14 right-6 border border-1 w-fit flex flex-col gap-px bg-bg-light shadow-[0_0_24px_0_rgba(96,96,96,0.25)] animate-bounce-short">
          <div
            className="flex justify-center items-center px-4 py-3 bg-white text-16"
            onClick={(e) => {
              e.preventDefault()
              track('Save Investment Clicked')
              window.location.href = `${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`
            }}
          >
            Finish Later
          </div>
          {investData?.investment && (
            <div
              className="flex justify-center items-center px-4 py-3 bg-white text-16"
              onClick={(e) => {
                e.preventDefault()
                cancelInvestment(investData?.investment?.id).then(() => {
                  queryClient.clear()
                  window.location.href = `${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`
                })
              }}
            >
              Cancel Investment
            </div>
          )}
          {!!data && (
            <div
              className="flex justify-center items-center px-4 py-3 bg-white text-16"
              onClick={(e) => {
                e.preventDefault()
                Auth.signOut()
                location.reload()
              }}
            >
              Log out
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Header
