import Footer from '../shared/Footer'

const Error404 = () => (
  <div className="flex h-full w-full flex-col items-center">
    <div className="w-full">
      <div className="max-w-[1400px] w-full bg-white px-6 py-4 mx-auto">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <img className="mr-4" src="/rm.svg" />
            <span className="text-content-dark text-16 font-medium uppercase tracking-[0.15em]">
              RealtyMogul
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="p-6 xl:p-[60px] h-[60vh] w-full xl:max-w-[1000px] text-content-black">
      <h1 className="text-[30px] mb-3 font-bold">404</h1>
      <div className="text-[24px] mb-6 font-bold">Uh oh! Page not found.</div>
      <p className="mb-6">
        Sorry, the page you are looking for doesn’t exist or was removed. If you
        have any questions, please don't hesitate to contact us.
      </p>
      <a
        href="https://www.realtymogul.com"
        className="flex items-center h-10 w-full md:w-fit justify-center rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] md:min-w-[200px]"
      >
        Back to Homepage
      </a>
    </div>
    <div className="w-full bg-black">
      <Footer />
    </div>
  </div>
)

export default Error404
