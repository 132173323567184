import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { useInvestDataState } from '../_investDataState'
import { update1031 } from '../../shared/_api'
import { useRoute } from '../../../rules/find-route'

import FormFooter from '../../shared/FormFooter'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import TextInput from '../../../components/TextInput'

interface AccommodatorFormTypes {
  companyName: string
  contactName: string
  phone: string
  email: string
}

const TenThirtyOneAccommodatorScreen = () => {
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { state } = useLocation()
  const { t } = useTranslation()
  const { findRoute } = useRoute()

  const [serverError, setServerError] = useState('')
  const { data: investData } = useInvestDataState()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AccommodatorFormTypes>({
    defaultValues: {
      companyName: investData?.tenThirtyOne?.companyName || '',
      contactName: investData?.tenThirtyOne?.contactName || '',
      phone: investData?.tenThirtyOne?.phone || '',
      email: investData?.tenThirtyOne?.email || '',
    },
  })

  // const backLocation = `/invest/${investData?.investment.id}/1031/info`

  const onSubmit = handleSubmit(async (data) => {
    console.log(errors, data)
    update1031(investData?.investment?.id, {
      investment_uuid: investData?.investment?.id,
      exchange_details: state.exchange_details,
      sale_date: state.sale_date,
      has_accomodator: true,
      company_name: data.companyName,
      contact_name: data.contactName,
      contact_phone: data.phone,
      contact_email: data.email,
      funded_by_1031: true,
    })
      .then(() => {
        queryClient.invalidateQueries(['1031'])
        queryClient.invalidateQueries(['invest'])
        findRoute({
          ...investData,
          investment_state: {
            ...investData.investment_state,
            step_1031: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={0} currentStep={4} totalStepCount={4} isPledge />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('tenThirtyOne:header')}
          </h1>
          <p className="mb-6 text-[19px] font-medium">
            {t('tenThirtyOne:contactInfo')}
          </p>
          <p className="mb-6 text-16 text-content-black">
            {t('tenThirtyOne:contactInfoCopy')}
          </p>
          <form onSubmit={onSubmit}>
            <div className="mb-6">
              <Controller
                name="companyName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Company"
                    placeholder="Company"
                    errorMessage={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mb-6">
              <Controller
                name="contactName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Contact Name"
                    placeholder="Contact Name"
                    errorMessage={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mb-6">
              <span
                className={`mb-2 block text-base font-normal ${
                  errors?.phone?.message ? 'text-alert' : 'text-content-black'
                }`}
              >
                Phone Number
              </span>
              <PhoneInput
                name="phone"
                className={`phone-input-wrapper mb-2 h-[42px] w-full rounded border ${
                  errors?.phone ? 'border-alert' : 'border-input'
                } px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary`}
                defaultCountry="US"
                countryOptionsOrder={['US']}
                control={control}
                initialValueFormat="national"
                limitMaxLength={true}
              />
              {errors?.phone?.message && (
                <p className="mb-1 text-xs text-alert">
                  {errors?.phone?.message}
                </p>
              )}
            </div>
            <div className="mb-6">
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Email"
                    placeholder="Email"
                    errorMessage={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
            <FormFooter submitFunc={onSubmit} />
            {serverError && (
              <ServerError
                serverError={serverError}
                id={investData?.investment?.id}
              />
            )}
          </form>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default TenThirtyOneAccommodatorScreen
