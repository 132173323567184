import { ReactNode } from 'react'
import { cn } from '@/utils/utils'
import { Link } from 'react-router-dom'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

type Link = {
  title: string
  label?: string
  icon: ReactNode
  location: string
  active: boolean
}
interface NavProps {
  isCollapsed: boolean
  unreadCount?: number
  links: Link[]
  links2: Link[]
  toggleCollapse: () => void
}

const CollapsedLink = ({
  link,
  unreadCount = 0,
}: {
  link: Link
  unreadCount?: number
}) => (
  <Tooltip delayDuration={0}>
    <TooltipTrigger asChild>
      <Link
        to={link.location}
        className={cn(
          'w-fit h-fit px-4 py-3 flex justify-center items-center rounded',
          link.active ? 'text-primary bg-bg-light' : 'text-content-black',
          'text-xl'
        )}
      >
        <div className="w-6 h-6 relative">
          <span className={cn(link.active && 'hidden')}>
            <i className={`fa-light fa-fw fa-${link.icon}`} />
          </span>
          <span className={cn('hidden', link.active && 'block')}>
            <i className={`fa-regular fa-fw fa-${link.icon}`} />
          </span>
          <span className="sr-only">{link.title}</span>
          {unreadCount > 0 && link.title.toLowerCase() === 'messages' && (
            <div className="absolute -bottom-1 right-0">
              <i className="fa-solid text-[7px] text-primary fa-circle" />
            </div>
          )}
        </div>
      </Link>
    </TooltipTrigger>
    <TooltipContent side="right" className="flex items-center gap-4">
      {link.title}
    </TooltipContent>
  </Tooltip>
)

const RegularLink = ({
  link,
  unreadCount = 0,
}: {
  link: Link
  unreadCount?: number
}) => (
  <Link
    to={link.location}
    className={cn(
      'w-full h-6 box-content justify-start bg-white flex items-center font-normal px-4 py-3 mr-4 rounded hover:bg-bg-lighter',
      link.active && 'bg-[#F9FAFB]'
    )}
  >
    <span
      className={cn(
        'w-6 h-6 mr-3 flex justify-center items-center',
        link.active ? 'text-primary' : 'text-content-black'
      )}
    >
      <span className={cn(link.active && 'hidden')}>
        <i className={`fa-light fa-lg fa-${link.icon}`} />
      </span>
      <span className={cn('hidden', link.active && 'inline')}>
        <i className={`fa-regular fa-lg fa-${link.icon}`} />
      </span>
    </span>
    <span className={cn(link.active && 'font-medium')}>{link.title}</span>
    {unreadCount > 0 && link.title.toLowerCase() === 'messages' && (
      <span className="px-2 ml-2 leading-6 text-[11px] font-medium rounded-[43px] bg-primary whitespace-pre text-white">
        {unreadCount} new
      </span>
    )}
  </Link>
)

const ActiveLink = ({ link }: { link: Link }) => (
  <span className="flex items-center text-16">
    <span className="mr-3">
      <i className={`fa-light fa-lg fa-${link?.icon}`} />
    </span>
    <span className="mb-[1px]">{link?.title}</span>
  </span>
)

const MobileNav = ({
  links,
  links2,
  unreadCount = 0,
}: {
  links: Link[]
  links2: Link[]
  unreadCount?: number
}) => {
  const mobileNavMenu = useMobileNavStore((state) => state.mobileNavMenu)
  const updateMobileNavMenu = useMobileNavStore(
    (state) => state.updateMobileNavMenu
  )
  const showMobileNav = useMobileNavStore((state) => state.showMobileNav) // for 2nd level menus

  return (
    showMobileNav && (
      <DropdownMenu
        open={mobileNavMenu}
        onOpenChange={() => updateMobileNavMenu(!mobileNavMenu)}
      >
        <DropdownMenuTrigger className="w-full h-[50px] flex bg-primary">
          <div className="h-full px-6 flex items-center text-16 flex-grow text-white font-normal">
            {mobileNavMenu ? (
              'Main Menu'
            ) : (
              <ActiveLink
                link={links.concat(links2).filter((link) => link.active)[0]}
              />
            )}
          </div>
          <div className="w-[46px] h-full flex justify-center items-center border-l border-primary-hover">
            <i
              className={cn(
                `fa-solid fa-${mobileNavMenu ? 'xmark' : 'angle-down'} text-white mx-2`
              )}
            />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="border-t-0 h-screen"
          avoidCollisions={false}
          sideOffset={0}
        >
          <div className="w-screen flex flex-col px-4 py-6">
            <div className="text-base text-[#8C8C8C] px-4 mb-2">Dashboard</div>
            <div className="flex flex-col gap-1">
              {links.map((link, index) => (
                <div key={index} onClick={() => updateMobileNavMenu(false)}>
                  <RegularLink link={link} unreadCount={unreadCount} />
                </div>
              ))}
            </div>
            <div className="text-base text-[#8C8C8C] mt-4 px-4 mb-2">
              Settings
            </div>
            {links2.map((link, index) => (
              <div key={index} onClick={() => updateMobileNavMenu(false)}>
                <RegularLink link={link} />
              </div>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  )
}

export function Nav({
  links,
  links2,
  isCollapsed,
  toggleCollapse,
  unreadCount = 0,
}: NavProps) {
  return (
    <>
      <div
        data-collapsed={isCollapsed}
        className={cn(
          'group flex-col gap-4 py-2 w-full h-full data-[collapsed=true]:py-2 relative hidden md:flex'
        )}
      >
        <nav className="grid rounded">
          {!isCollapsed && (
            <div className="text-base text-[#8C8C8C] px-4 mb-2">Dashboard</div>
          )}
          <div className="flex flex-col gap-1">
            {links.map((link, index) =>
              isCollapsed ? (
                <div key={index}>
                  <CollapsedLink link={link} unreadCount={unreadCount} />
                </div>
              ) : (
                <div key={index}>
                  <RegularLink link={link} unreadCount={unreadCount} />
                </div>
              )
            )}
          </div>
          {!isCollapsed && (
            <div className="text-base text-[#8C8C8C] mt-4 px-4 mb-2">
              Settings
            </div>
          )}
          {links2.map((link, index) =>
            isCollapsed ? (
              <div key={index}>
                <CollapsedLink link={link} />
              </div>
            ) : (
              <div key={index}>
                <RegularLink link={link} />
              </div>
            )
          )}
          <button
            className={cn(
              !isCollapsed && 'hidden',
              'w-fit h-fit px-4 py-3 text-xl text-[#8C8C8C] hover:text-primary'
            )}
            onClick={() => toggleCollapse()}
          >
            <div className="w-6 h-6">
              <i className="fa-light fa-arrow-right-from-line" />
            </div>
          </button>
          <button
            className={cn(
              'flex flex-row items-center mt-3 py-3 px-5 fill-[#8C8C8C] hover:text-primary [&>span]:hover:opacity-100 ',
              isCollapsed && 'hidden'
            )}
            onClick={() => toggleCollapse()}
          >
            <i className="fa-light text-xl fa-arrow-left-to-line" />
            <span className="opacity-0 ml-2 mt-[2px] text-content-black">
              Close
            </span>
          </button>
        </nav>
      </div>
      <div className="md:hidden">
        <MobileNav links={links} links2={links2} unreadCount={unreadCount} />
      </div>
    </>
  )
}
