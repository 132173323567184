import { mapParams } from '../../shared/map-params'
import { AllAccountTypes } from '../api/schema'

const DetailRow = ({ label, value }: { label: string; value: string }) => (
  <div className="flex-col justify-start items-start gap-2 flex text-base font-normal">
    <div>{label}</div>
    <div className="font-medium">{value}</div>
  </div>
)

export const OtherDetails = ({ data }: { data: AllAccountTypes }) => {
  return (
    <div className="max-w-[500px] p-6 bg-gray-50 rounded flex-col justify-start items-start gap-6 inline-flex">
      <div className="font-medium text-[19px]">Other Details</div>
      <div className="gap-3 inline-flex text-base text-content-light">
        <i className="mt-1 fa-solid fa-lock" />
        <div>
          <span>
            If you need to update the information below, please reach out to our
            team at{' '}
          </span>
          <a href="mailto:investor-help@realtymogul.com" className="underline">
            investor-help@realtymogul.com
          </a>
          <span>.</span>
        </div>
      </div>
      {data.type === 'foe_sdira' && (
        <>
          <DetailRow
            label="Custodian Name"
            value={data?.custodian?.name || 'N/A'}
          />
          <DetailRow
            label="Account Holder Name"
            value={data.investment_account_name ?? ''}
          />
        </>
      )}
      {data.type === 'joint_ownership' && (
        <>
          {data.secondary_member.first_name && (
            <DetailRow
              label="Name of Joint Investor"
              value={`${data.secondary_member.first_name} - ${data.secondary_member.last_name}`}
            />
          )}
          {data.subtype && (
            <DetailRow
              label="Type of Joint Investor"
              value={
                data.subtype.charAt(0).toUpperCase() + data.subtype.slice(1)
              }
            />
          )}
        </>
      )}
      {data.type === 'foe_trust' && (
        <>
          {data.investment_account_name && (
            <DetailRow
              label="Name of Trust"
              value={data.investment_account_name}
            />
          )}
          {data.title_within_trust && (
            <DetailRow
              label="Your Title Within Trust"
              value={data.title_within_trust}
            />
          )}
          {data.subtype && (
            <DetailRow
              label="Type of Trust"
              value={data.subtype === 'revocable' ? 'Revocable' : 'Irrevocable'}
            />
          )}
          {data.tin && (
            <DetailRow
              label="Tax Identification Number (TIN)"
              value={data.tin}
            />
          )}
          {data.is_disregarded_entity && (
            <DetailRow
              label="Is this a disregarded entity?"
              value={data.is_disregarded_entity ? 'Yes' : 'No'}
            />
          )}
          {data.jurisdiction_of_registration && (
            <DetailRow
              label="Jurisdiction of Registration"
              value={data.jurisdiction_of_registration}
            />
          )}
          {data.trust_agreement?.file_name && (
            <DetailRow
              label="Trust Agreement"
              value={data.trust_agreement.file_name}
            />
          )}
        </>
      )}
      {data.type === 'foe_entity' && (
        <>
          {data.investment_account_name && (
            <DetailRow
              label="Entity Name"
              value={data.investment_account_name}
            />
          )}
          {data.title && (
            <DetailRow label="Your Title Within Entity" value={data.title} />
          )}
          {data.subtype && (
            <DetailRow label="Type of Entity" value={mapParams(data.subtype)} />
          )}
          {data.tin && (
            <DetailRow
              label="Tax Identification Number (TIN)"
              value={data.tin}
            />
          )}
          {data.is_disregarded_entity && (
            <DetailRow
              label="Is this a disregarded entity?"
              value={data.is_disregarded_entity ? 'Yes' : 'No'}
            />
          )}
          {data.jurisdiction_of_registration && (
            <DetailRow
              label="Jurisdiction of Registration"
              value={data.jurisdiction_of_registration}
            />
          )}
          {data.certificate_of_formation && (
            <DetailRow
              label="Trust Agreement"
              value={data.certificate_of_formation}
            />
          )}
          {data.operating_agreement && (
            <DetailRow
              label="Trust Agreement"
              value={data.operating_agreement}
            />
          )}
        </>
      )}
    </div>
  )
}
