import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import { api } from '@/routes/shared/_api'
import { transactionsSchema, TransactionsTypes } from './schema'
import { datadogLogs } from '@datadog/browser-logs'
import { z } from 'zod'

export const getTransactions = async (params: URLSearchParams) => {
  const response = await api.get('/dashboard/transactions', {
    params,
  })
  const parsedData = transactionsSchema.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-transactions',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof transactionsSchema
    >
    return unsafeUnparsedData
  } else {
    return parsedData.data
  }
}

export const useTransactions = () => {
  const [searchParams] = useSearchParams()
  return useQuery<TransactionsTypes>({
    queryKey: [
      'transactions',
      searchParams.get('page'),
      searchParams.get('io_uuid'),
      searchParams.get('investment_account_uuid'),
      searchParams.get('transaction_type'),
      searchParams.get('date_from'),
      searchParams.get('date_to'),
    ],
    queryFn: () => getTransactions(searchParams),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
