import { useEffect, useState } from 'react'
import {
  endOfYear,
  format,
  isBefore,
  startOfYear,
  subDays,
  subYears,
} from 'date-fns'

import { DatePicker } from './date-picker'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select-filter'
import { Button } from '@/components/ui/button'
import { cn } from '@/utils/utils'

type DateFilterType = {
  handleDateFilterSelect: (date_from: string, date_to: string) => void
  defaultValues: [date_from?: string, date_to?: string]
}

const dateFormat = 'yyyy-MM-dd'

const getFormattedDate = (date: Date): string => {
  return format(date, dateFormat)
}

const getLastNDaysISO = (days: number): Date => {
  return subDays(new Date(), days)
}

function DateFilter({ handleDateFilterSelect, defaultValues }: DateFilterType) {
  const [showDatePickers, setShowDatePickers] = useState(
    defaultValues[0] || defaultValues[1] ? true : false
  )
  const [date_from, setDate_from] = useState(defaultValues[0])
  const [date_to, setDate_to] = useState(defaultValues[1])
  const [value, setValue] = useState(showDatePickers ? 'date-pick' : 'all')
  const name = 'dates'

  useEffect(() => {
    setDate_from(defaultValues[0])
    setDate_to(defaultValues[1])
    if (!defaultValues[0] && !defaultValues[1]) {
      setValue('all')
      setShowDatePickers(false)
    }
  }, [defaultValues])

  const handleFilterSelect = (value: string) => {
    setValue(value)
    setShowDatePickers(false)
    switch (value) {
      case 'all':
        handleDateFilterSelect('', '')
        break
      case 'last-30':
        handleDateFilterSelect(getFormattedDate(getLastNDaysISO(30)), '')
        break
      case 'last-60':
        handleDateFilterSelect(getFormattedDate(getLastNDaysISO(60)), '')
        break
      case 'last-90':
        handleDateFilterSelect(getFormattedDate(getLastNDaysISO(90)), '')
        break
      case 'year-to-date':
        handleDateFilterSelect(getFormattedDate(startOfYear(new Date())), '')
        break
      case 'last-year':
        handleDateFilterSelect(
          getFormattedDate(startOfYear(subYears(new Date(), 1))),
          getFormattedDate(endOfYear(subYears(new Date(), 1)))
        )
        break
      case 'date-pick':
        setShowDatePickers(true)
        break
    }
  }

  const handleStartDateSelect = (value: string) => {
    if (date_to) {
      handleDateFilterSelect(
        isBefore(date_to, value) ? date_to : value,
        date_to
      )
      setDate_from(isBefore(date_to, value) ? date_to : value)
    } else {
      handleDateFilterSelect(value, '')
      setDate_from(value)
    }
  }

  const handleEndDateSelect = (value: string) => {
    if (date_from) {
      handleDateFilterSelect(
        date_from,
        isBefore(value, date_from) ? date_from : value
      )
      setDate_to(isBefore(value, date_from) ? date_from : value)
    } else {
      handleDateFilterSelect('', value)
      setDate_to(value)
    }
  }

  const data = [
    { name: 'Last 30 days', uuid: 'last-30' },
    { name: 'Last 60 days', uuid: 'last-60' },
    { name: 'Last 90 days', uuid: 'last-90' },
    { name: 'Year to date', uuid: 'year-to-date' },
    { name: 'Last Year', uuid: 'last-year' },
  ]

  return (
    data && (
      <div className="flex flex-col gap-4 md:flex-row">
        <Select
          value={value}
          onValueChange={handleFilterSelect}
          defaultValue="all"
        >
          <SelectTrigger
            className={cn(
              'w-full md:w-fit h-[38px]',
              value !== 'all' && 'border-primary font-medium'
            )}
          >
            <span>
              <SelectValue placeholder={`All ${name}`} />
            </span>
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">
              <span className="capitalize">{`All ${name.split('_').join(' ')}`}</span>
            </SelectItem>
            {data.map((item) => (
              <SelectItem value={item.uuid} key={item.uuid}>
                {item.name}
              </SelectItem>
            ))}
            <SelectItem value="date-pick">Date Range</SelectItem>
          </SelectContent>
        </Select>
        {showDatePickers && (
          <div className="flex flex-col md:flex-row items-start md:items-center text-sm text-[#717B8E]">
            <div className="md:hidden mb-3">From</div>
            <DatePicker
              value={date_from}
              handleDateSelect={handleStartDateSelect}
            />
            <span className="hidden md:block">
              <i className="fa-regular fa-arrow-right mx-4 text-[#817E99]" />
            </span>
            <div className="md:hidden mt-4 mb-3">To</div>
            <DatePicker
              value={date_to}
              handleDateSelect={handleEndDateSelect}
            />
            <Button
              variant="ghost"
              className="text-[13px] text-[#717B8E] hover:bg-transparent mt-3 p-0 md:mt-0 md:ml-4"
              onClick={() => {
                handleFilterSelect('all')
                setDate_from(undefined)
                setDate_to(undefined)
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    )
  )
}

export { DateFilter }
