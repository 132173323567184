import { ColumnDef } from '@tanstack/react-table'
import { cn } from '@/utils/utils'

import { TaxDocumentType } from '../api/schema'
import { DataTableColumnHeader } from './column-header'

import { InvestmentsTooltip } from './investments-tooltip'
import { mapParams } from '../../shared/map-params'
import { Link } from 'react-router-dom'

export const columns: ColumnDef<TaxDocumentType>[] = [
  {
    accessorKey: 'tax_year',
    header: ({ column }) => (
      <DataTableColumnHeader
        className="whitespace-nowrap"
        column={column}
        title="Tax Year"
      />
    ),
    cell: ({ row }) => {
      const isUploaded = row.original.document_url
      return (
        <div
          className={cn(
            isUploaded ? '' : 'text-[#8C8C8C]',
            'font-medium text-base '
          )}
        >
          {row.getValue('tax_year')}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'form_type',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Document" />
    ),
    cell: ({ row }) => {
      const isUploaded = row.original.document_url
      return (
        <div
          className={cn(
            isUploaded ? '' : 'text-[#8C8C8C]',
            'font-medium text-base'
          )}
        >
          {mapParams(row.original.form_type)}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'investments',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Investment" />
    ),
    cell: ({ row }) => {
      const isUploaded = row.original.document_url
      const rowInvestmentsData = row.original.investments

      return rowInvestmentsData.length > 1 ? (
        <InvestmentsTooltip
          isUploaded={isUploaded || ''}
          rowInvestmentsData={rowInvestmentsData}
        />
      ) : (
        <Link
          className={cn(
            isUploaded ? '' : 'text-[#8C8C8C]',
            'underline cursor-pointer text-base'
          )}
          to={`/dashboard/investments/details/${rowInvestmentsData[0].io_uuid}`}
          state={{
            previousLocation: window.location.pathname + window.location.search,
          }}
        >
          {rowInvestmentsData[0].io_name}
        </Link>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'ownership_type',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Ownership Type" />
    ),
    cell: ({ row }) => {
      const isUploaded = row.original.document_url
      return (
        <div className={cn(isUploaded ? '' : 'text-[#8C8C8C]', 'text-base')}>
          {row.original.investment_account_name}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'issuer',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Issuer" />
    ),
    cell: ({ row }) => {
      const isUploaded = row.original.document_url
      return (
        <div className={cn(isUploaded ? '' : 'text-[#8C8C8C]', 'text-base')}>
          {row.original.issuer}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'document_url',
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center min-w-[150px]"
        column={column}
        title="Status"
      />
    ),
    cell: ({ row }) => {
      const isUploaded = row.original.document_url
      return isUploaded ? (
        <a
          className="flex underline justify-center text-[#0070EB] text-base"
          href={row.getValue('document_url')}
          target="_blank"
        >
          Download
        </a>
      ) : (
        <div className="text-center text-[#8C8C8C] underline text-base">
          Reviewing tax forms
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
]
