import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { useSearchParams } from 'react-router-dom'
import { messagesDataSchema, MessagesDataTypes } from './schema'
import { datadogLogs } from '@datadog/browser-logs'
import { z } from 'zod'

export const getMessages = async (params: URLSearchParams) => {
  const response = await api.get('/dashboard/messages', {
    params,
  })
  const parsedData = messagesDataSchema.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-messages',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof messagesDataSchema
    >
    return unsafeUnparsedData
  } else {
    return parsedData.data
  }
}

export const useMessages = () => {
  const [searchParams, _setSearchParams] = useSearchParams()
  return useQuery<MessagesDataTypes>({
    queryKey: [
      'messages',
      searchParams.get('page'),
      searchParams.get('io_uuid'),
      searchParams.get('investment_account_uuid'),
      searchParams.get('date_from'),
      searchParams.get('date_to'),
      searchParams.get('unread'),
    ],
    queryFn: () => getMessages(searchParams),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
