import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getDrupalUrl, uploadIncomeForm } from '../../shared/_api'
import { useInvestDataState } from '../_investDataState'
import { useMutateEAV } from '../_eavDataState'
import { useRoute } from '../../../rules/find-route'

import FormFooter from '../../shared/FormFooter'
import FileUpload from '../../../components/FileUpload'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'
import { useFeatureFlags } from '@/rules/get-feature-flags'

interface IncomeVerificationFormTypes {
  preferredMethod: string
  additional_info: string | undefined
  recentDocumentFirst: File
  firstDocument1031: boolean
  recentDocumentSecond: File
  secondDocument1031: boolean
}

const IncomeVerification = () => {
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { mutateAsync: updateEAV } = useMutateEAV()
  const { t } = useTranslation()
  const { findRoute } = useRoute()
  const { data: featureFlagsData } = useFeatureFlags()

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<IncomeVerificationFormTypes>({
    defaultValues: {
      preferredMethod:
        investData?.verification?.preferredMethod || 'submitForm',
    },
  })

  const handleAfterUpload = (file: File) => {
    setValue('recentDocumentFirst', file)
  }

  const handleAfterUpload2 = (file: File) => {
    setValue('recentDocumentSecond', file)
  }

  // const backLocation = `/invest/${investData?.investment.id}/verification`

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    Promise.all([
      uploadIncomeForm(investData?.investment?.id, data.recentDocumentFirst),
      data.recentDocumentSecond &&
        uploadIncomeForm(investData?.investment?.id, data.recentDocumentSecond),
    ]).then(() => {
      updateEAV({
        verification_type: 'income',
        additional_info: data.additional_info,
      })
        .then(() => {
          if (investData.investment?.status === 'pledge') {
            window.location.href = `${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`
          } else {
            findRoute({
              ...investData,
              investment_state: {
                ...investData.investment_state,
                eav: 'submitted',
              },
            }).then((route) => {
              nav(`/invest/${investData.opportunity.id}/${route.type}`)
            })
          }
        })
        .catch((error) => {
          console.error(error.message)
          setServerError(error.message)
        })
    })
  })

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={2} currentStep={4} totalStepCount={5} />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            {t('verification:eav')}
          </h1>
          <p className="mb-6 text-base italic text-content-light">
            {t('verification:income')}
          </p>
          <p className="mb-6 text-[19px] font-medium">
            {t('verification:lastTwoYears')}
          </p>
          <p className="mb-6 text-16">{t('verification:perSEC')}</p>
          <form onSubmit={onSubmit} className="xl:">
            <div className="mb-6">
              <p className="mb-6 text-16 text-content-black">
                Income Document 1:
              </p>
              <Controller
                name="recentDocumentFirst"
                control={control}
                rules={{ required: 'File is required' }}
                render={({ field }) => (
                  <FileUpload
                    {...field}
                    accept=".pdf"
                    label={t('common:uploadDocument')}
                    uploadFileFunction={handleAfterUpload}
                  />
                )}
              />
              {errors?.recentDocumentFirst?.message && (
                <span className="mb-1 text-xs text-alert">
                  {errors.recentDocumentFirst.message}
                </span>
              )}
              <p className="my-4 text-center text-[13px]">
                {t('common:pdfOnly')}
              </p>
            </div>
            <div className="mb-6">
              <p className="mb-6 text-16 text-content-black">
                Income Document 2:
              </p>
              <Controller
                name="recentDocumentSecond"
                control={control}
                rules={{ required: 'File is required' }}
                render={({ field }) => (
                  <FileUpload
                    {...field}
                    accept=".pdf"
                    label={t('common:uploadDocument')}
                    uploadFileFunction={handleAfterUpload2}
                  />
                )}
              />
              {errors?.recentDocumentSecond?.message && (
                <span className="mb-1 text-xs text-alert">
                  {errors.recentDocumentSecond.message}
                </span>
              )}
              <p className="my-4 text-center text-[13px]">
                {t('common:pdfOnly')}
              </p>
            </div>
            <p className="mb-6 text-xl font-medium">
              {t('verification:additionalInformation')}
            </p>
            <p className="mb-6 text-16 text-content-black">
              {t('verification:additionalInformationCopy')}
            </p>
            <textarea
              className="mb-6 h-[100px] w-full rounded border border-[#A4C2D5] p-3"
              {...register('additional_info')}
            />
            <p className="mb-6 text-16">{t('verification:northCap')}</p>
            <FormFooter submitFunc={onSubmit} />
            {serverError && (
              <ServerError
                serverError={serverError}
                id={investData?.investment?.id}
              />
            )}
          </form>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default IncomeVerification
