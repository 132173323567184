import { useAuthDataState } from '@/routes/shared/_authDataState'
import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { z } from 'zod'
import { datadogLogs } from '@datadog/browser-logs'

const unreadMessagesSchema = z.object({
  unread_count: z.number(),
})

const getUnreadMessages = async () => {
  const response = await api.get('/dashboard/messages/unread')
  const parsedData = unreadMessagesSchema.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-unread-messages',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof unreadMessagesSchema
    >
    return unsafeUnparsedData
  } else {
    return parsedData.data
  }
}

export const useUnreadDataQuery = () => {
  const { data } = useAuthDataState()

  return useQuery<number>({
    queryKey: ['unreadMessages'],
    queryFn: async () => {
      const data = await getUnreadMessages()
      return data.unread_count
    },
    staleTime: Infinity,
    enabled: !!data,
  })
}
