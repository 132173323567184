import { cn } from '@/utils/utils'

const SubItem = ({
  icon,
  text,
  clickFn,
  isActive = false,
}: {
  icon: string
  text: string
  clickFn: (screen: string) => void
  isActive?: boolean
}) => (
  <button
    onClick={() => clickFn(text)}
    className={cn(
      'px-3 md:px-4 py-1 items-center gap-2 flex cursor-pointer hover:bg-white',
      isActive && 'bg-white cursor-auto text-primary'
    )}
  >
    <i
      className={cn(
        'hidden md:inline fa-sm',
        `fa-${icon}`,
        isActive ? 'fa-solid' : 'fa-thin'
      )}
    />
    <div
      className={cn(
        'text-content-black font-normal',
        isActive && 'font-medium'
      )}
    >
      <span className="hidden md:inline">{text}</span>
      <span className="md:hidden">
        {text.split(' ').length > 1 ? text.split(' ')[1] : text.split(' ')[0]}
      </span>
    </div>
  </button>
)

export const DetailsSubNav = ({
  activeCategory,
  callback,
  isCompleted = false,
}: {
  activeCategory: string
  callback: (screen: string) => void
  isCompleted?: boolean
}) => {
  return (
    <div className="inline-flex bg-bg-light border-[2px] border-bg-light text-16 text-content-black rounded">
      {!isCompleted && (
        <SubItem
          icon="gear"
          text="Settings"
          clickFn={() => callback('settings')}
          isActive={activeCategory === 'settings'}
        />
      )}
      <SubItem
        icon="history"
        text="Investment History"
        clickFn={() => callback('history')}
        isActive={activeCategory === 'history'}
      />
      <SubItem
        icon="file-signature"
        text="Signed Documents"
        clickFn={() => callback('signed')}
        isActive={activeCategory === 'signed'}
      />
    </div>
  )
}
