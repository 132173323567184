import { Auth } from 'aws-amplify'
import { useQuery } from '@tanstack/react-query'

import { useTokensDataState } from '@/routes/shared/_tokensDataState'

const getCurrentUserEmail = async (): Promise<string> => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    return currentUser.attributes.email
  } catch {
    // Not logged in.
    return Promise.resolve('')
  }
}

const parseFeatureFlag = async (value: string) => {
  const email = await getCurrentUserEmail()

  // If the value is equal to QA, make sure the email address has realtymogulqa
  // in it or ends in @realtymogul.com
  if (value === 'QA') {
    if (email.includes('realtymogulqa') || email.endsWith('@realtymogul.com')) {
      return true
    }
  }

  // If the value is set to true, return true.
  if (value === 'true') {
    return true
  }

  // If the value is set to false, return false.
  if (value === 'false') {
    return false
  }
  return false
}

export const useFeatureFlags = () => {
  const { data: tokenData } = useTokensDataState()
  return useQuery({
    queryKey: ['feature-flags'],
    queryFn: async () => {
      const filteredData = await Object.keys(tokenData).reduce(
        async (accPromise, key) => {
          const acc = await accPromise
          if (key.startsWith('ff_')) {
            acc[key] = await parseFeatureFlag(tokenData[key])
          }
          return acc
        },
        Promise.resolve({} as Record<string, boolean>)
      )
      return filteredData
    },
    enabled: !!tokenData,
    staleTime: 1000 * 60 * 60,
  })
}
