import { CompletedInvestmentMenu } from './edit-menu-completed'
import {
  amountRegexDecimal,
  FALLBACK_RM_IMAGE,
  moneyMask,
} from '@/utils/helpers'
import { Badge } from '@/components/ui/badge'
import { format } from 'date-fns/format'
import { InvestmentDataTypes } from '../api/schema'
import { useLocation, useNavigate } from 'react-router-dom'
import { cn } from '@/utils/utils'

export const CompletedInvestmentCard = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => {
  const nav = useNavigate()
  const { pathname, search } = useLocation()
  const handleClick = () => {
    nav(`/dashboard/investments/details/${investment.io_uuid}`, {
      state: { previousLocation: pathname + search },
    })
  }

  return (
    <div
      className="w-full flex gap-4 md:gap-8 overflow-hidden hover:bg-bg-lighter cursor-pointer h-[56px] md:h-[106px]"
      onClick={handleClick}
    >
      <div className="min-w-[50px] w-[50px] md:w-[180px]">
        <img
          className="w-full h-full object-cover md:object-fill"
          src={investment.image ?? FALLBACK_RM_IMAGE}
        />
      </div>
      <div className="w-[85%] flex py-4">
        <div
          className={cn(
            'flex md:w-[30%] flex-col h-full gap-1 justify-center',
            investment.is_reit && 'w-[50%]'
          )}
        >
          <div>
            <p className="text-base md:text-[17px] font-medium line-clamp-2">
              {investment.name}
            </p>
          </div>
          <div className="hidden md:block">
            <p className="text-16 line-clamp-1">{investment.subhead}</p>
          </div>
          <div className="hidden md:block text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        <div className="hidden md:flex flex-col justify-start">
          <Badge variant="completed">Completed</Badge>
        </div>
        <div className="ml-auto flex gap-4">
          {!investment.is_reit ? (
            <div className="hidden md:flex w-[200px] text-base gap-2 flex-col justify-start text-right">
              <div className="font-medium text-16">
                {investment.return_calculated ? (
                  <>
                    <i className="text-[20px] fa-light fa-chart-line" />
                    <div className="inline-block ml-4 min-w-[105px]">
                      {Number(investment.return_calculated).toFixed(1)}% IRR
                    </div>
                  </>
                ) : (
                  <div className="font-normal text-content-light">
                    Not available
                  </div>
                )}
              </div>
              <div className="hidden md:block text-base text-content-light">
                {investment.maturity_date ? (
                  <span>
                    Maturity date{' '}
                    {format(
                      new Date(investment.maturity_date ?? ''),
                      'MM/dd/yyyy'
                    )}
                  </span>
                ) : (
                  <span className="text-sm whitespace-pre">
                    Maturity date not available
                  </span>
                )}
              </div>
            </div>
          ) : null}
          <div className="hidden md:flex w-[200px] text-base gap-2 flex-col justify-start text-right">
            <div className="font-medium text-16">
              <i className="text-[20px] fa-light fa-money-check-dollar-pen" />
              <div className="inline-block ml-4 min-w-[105px]">
                $
                {moneyMask(investment.total_invested ?? '', amountRegexDecimal)}
              </div>
            </div>
            <div className="text-base text-content-light">Total Investment</div>
          </div>
          <div className="hidden md:flex w-[200px] text-base gap-2 flex-col justify-start text-right">
            <div className="font-medium text-16">
              <i className="text-[20px] fa-light fa-hand-holding-dollar" />
              <div className="inline-block ml-4 min-w-[95px]">
                $
                {moneyMask(
                  investment.total_distributions ?? '',
                  amountRegexDecimal
                )}
              </div>
            </div>
            <div className="text-base text-content-light">
              Total Distributions
            </div>
          </div>
          <div onClick={(e) => e.stopPropagation()} className="-mt-3">
            <CompletedInvestmentMenu uuid={investment.io_uuid} />
          </div>
        </div>
      </div>
    </div>
  )
}
