import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkDocusign, getIO } from '../shared/_api'
import Loader from '../../components/Loader'
import { useRoute } from '../../rules/find-route'

const successStatus = 'OnSigningComplete'
const viewedStatus = 'OnViewingComplete'

const HandleDocusignReturn = () => {
  const location = useLocation()
  const nav = useNavigate()
  const { findRoute } = useRoute()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const envelopeId = params.get('envelope_id') || ''
    const status = params.get('event') || ''
    const investmentId = params.get('identifier') || ''
    checkDocusign(investmentId, envelopeId, status)
      .then(() => {
        if (status.includes(successStatus) || status.includes(viewedStatus)) {
          getIO(investmentId).then((data) => {
            findRoute({
              ...data,
              investment_state: {
                ...data?.investment_state,
                agreement_status: 'submitted',
              },
            }).then((step) => {
              nav(`/invest/${data.opportunity.id}/${step.type}`, {
                replace: true,
                state: { params: step.params },
              })
            })
          })
        } else {
          nav(`/invest/${investmentId}/signature`, {
            state: { error: true, docusignReturn: true },
          })
        }
      })
      .catch(() => {
        nav(`/invest/${investmentId}/signature`, { state: { error: true } })
      })
  }, [location, nav])

  return (
    <div className="w-full pt-16 flex justify-center items-center">
      <Loader />
    </div>
  )
}

export default HandleDocusignReturn
