import { create } from 'zustand'

type MobileNavStore = {
  showMobileNav: boolean
  mobileNavMenu: boolean
  updateMobileNavMenu: (menuShow: boolean) => void
  updateMobileNav: (show: boolean) => void
}

export const useMobileNavStore = create<MobileNavStore>((set) => ({
  showMobileNav: true,
  mobileNavMenu: false,
  updateMobileNavMenu: (menuShow) => set({ mobileNavMenu: menuShow }),
  updateMobileNav: (show) => set({ showMobileNav: show }),
}))
