import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { useInvestDataState } from '../_investDataState'
import { BankAccountTypes } from '../../shared/_types'
import { useBankAccountsDataState } from '../_bankAccountsDataState'
import { postVeriCheck } from '../../shared/_api'
import { useRoute } from '../../../rules/find-route'

import FormFooter from '../../shared/FormFooter'
import Stepper from '../../../components/Stepper'
import Checkbox from '../../../components/Checkbox'
import ErrorAlert from '../../../components/ErrorAlert'
import Sidebar from '../Sidebar'
import Loader from '../../../components/Loader'
import ServerError from '../../../components/ErrorAlert/ServerError'

interface ReviewFormTypes {
  purchaseAuthorization: boolean
  achAuthorization: boolean
}

const Review = () => {
  const [serverError, setServerError] = useState('')
  const [fundingSource, setFundingSource] = useState<BankAccountTypes>()
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { data: investData } = useInvestDataState()
  const { data: bankAccountsData } = useBankAccountsDataState()
  const { t } = useTranslation()
  const { findRoute } = useRoute()

  useEffect(() => {
    if (!investData.investment?.distribution_account_uuid) {
      queryClient.invalidateQueries(['invest'])
    }
    if (!bankAccountsData) return
    bankAccountsData.bank_account_list.forEach((bankAccount: any) => {
      if (
        bankAccount.account_uuid === investData.investment?.funding_account_uuid
      )
        setFundingSource(bankAccount)
      if (
        bankAccount.account_uuid ===
          investData.investment?.distribution_account_uuid &&
        !investData.investment?.funding_account_uuid
      )
        setFundingSource(bankAccount)
    })
  }, [bankAccountsData, investData, queryClient])

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ReviewFormTypes>({
    defaultValues: {
      purchaseAuthorization: false,
      achAuthorization: false,
    },
  })

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    postVeriCheck(investData?.investment?.id, data)
      .then(() => {
        findRoute({
          ...investData,
          investment_state: {
            ...investData.investment_state,
            review: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })
  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={2} currentStep={3} totalStepCount={3} />
      ) : (
        <Stepper currentSection={2} currentStep={5} totalStepCount={5} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            {t('review:header')}
          </h1>
          {bankAccountsData &&
          investData.investment?.distribution_account_uuid ? (
            <>
              <p className="mb-6 text-base italic text-content-light"></p>
              <p className="mb-6 text-16 text-content-black">
                {t('review:copy1')}
              </p>
              {investData.investment?.funding_account_uuid && (
                <p className="mb-6 text-xl font-medium">
                  {t('review:fundingSource')}
                </p>
              )}
              <p className="mb-2 text-16 text-content-black">
                {investData.investment?.distribution_account_uuid &&
                !investData.investment?.funding_account_uuid
                  ? 'Distribution Account'
                  : 'Bank Account'}
              </p>
              <div className="relative mb-6 w-full bg-bg-lighter p-3">
                <p className="font-bold first-letter:capitalize">
                  {fundingSource?.account_name && fundingSource?.mask
                    ? `${fundingSource?.account_name} - *${fundingSource?.mask}`
                    : fundingSource?.account_description}
                </p>
                <p className="capitalize">{fundingSource?.account_type}</p>
              </div>
              <p className="mb-4 text-xl font-medium">
                {t('review:authorizations')}
              </p>
              {investData.investment?.funding_account_uuid && (
                <p className="mb-6 text-base italic">{t('review:vericheck')}</p>
              )}
              <form onSubmit={onSubmit} className="xl:">
                <div className="mb-5">
                  {(errors.purchaseAuthorization ||
                    errors.achAuthorization) && (
                    <ErrorAlert text="Please check all of the boxes below:" />
                  )}
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="purchaseAuthorization"
                      control={control}
                      rules={{ required: 'is required' }}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          {...field}
                          label={
                            <Trans
                              i18nKey={
                                investData.investment
                                  ?.distribution_account_uuid &&
                                !investData.investment?.funding_account_uuid
                                  ? t('review:authorizeWire')
                                  : t('review:authorizeAch')
                              }
                            />
                          }
                          errorMessage={fieldState.error}
                        />
                      )}
                    />
                  </div>
                </div>
                <FormFooter
                  submitFunc={onSubmit}
                  buttonText={t('review:submitInvestmentRequest')}
                  disabled={!isDirty}
                />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </form>
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default Review
