import { z } from 'zod'

export const investmentSchema = z.object({
  annualized_distribution_rate: z.string().nullable(),
  auto_invest_details: z
    .object({
      enabled: z.boolean(),
      amount: z.string().nullable(),
      next_investment_date: z.string().nullable(),
    })
    .nullable(),
  auto_invest_eligible: z.boolean(),
  cash_distributions: z.string().nullable(),
  distribution_bank_account_enabled: z.boolean().nullable(),
  distribution_details: z
    .object({
      bank_account_uuid: z.string().uuid(),
      account_name: z.string().nullable(),
      last_four: z.string().nullable(),
      last_amount: z.string().nullable(),
    })
    .nullable(),
  distribution_frequency: z.string().nullable(),
  distributions_reinvested: z.string().nullable(),
  documents: z
    .array(
      z
        .object({
          id: z.number().int(),
          type: z.string(),
          url: z.string().url(),
        })
        .nullable()
    )
    .nullable(),
  drip_details: z
    .object({
      status: z.string().nullable(),
      status_reason: z.string().nullable(),
      turn_on_url: z.string().nullable(),
      turn_off_url: z.string().nullable(),
    })
    .nullable(),
  drip_eligible: z.boolean().nullable(),
  image: z.string().nullable(),
  investment: z
    .object({
      uuid: z.string().uuid(),
      status: z.string(),
      investment_account_type: z.string().nullable(),
      initial_amount: z.union([z.string(), z.number().int().min(0)]).nullable(),
      ten_31_record: z
        .object({
          investment_uuid: z.string().uuid(),
        })
        .nullable(),
      agreement_type: z.string().nullable(),
      has_reservation: z.boolean(),
      funding_status: z.string().nullable(),
      funding_account_uuid: z.string().uuid().nullable(),
      distribution_account_uuid: z.string().uuid().nullable(),
      invest_flow_step: z.string().nullable(),
      eav_status: z.string().nullable(),
      wire_instructions: z.string().nullable(),
      investflow_state: z
        .object({
          agreement: z.string().nullish(),
          amount: z.string().nullish(),
          concentration: z.string().nullish(),
          eav: z.string().nullish(),
          employment: z.string().nullish(),
          funding: z.string().nullish(),
          identity_state: z.string().nullish(),
          income_net_worth: z.string().nullish(),
          investment_account: z.string().nullish(),
          personal_info: z.string().nullish(),
          qualification: z.string().nullish(),
          review: z.string().nullish(),
          self_accreditation: z.string().nullish(),
          step_1031: z.string().nullish(),
        })
        .nullish(),
      opportunity: z.object({
        uuid: z.string().uuid(),
        io_type: z.string(),
        eligible_1031: z.boolean().nullable(),
        wire_only: z.boolean().nullable(),
        is_funded: z.boolean().nullable(),
        fully_pledged: z.boolean().nullable(),
      }),
    })
    .nullable(),
  investment_entity: z.string().nullable(),
  investment_status: z.string().nullable(),
  investment_type: z.string().nullable(),
  investment_value: z.string().nullable(),
  io_drupal_id: z.number().int(),
  io_uuid: z.string().uuid(),
  is_reit: z.boolean(),
  location: z.string().nullable(),
  maturity_date: z.string().nullable(),
  name: z.string(),
  nav: z.string().nullable(),
  property_class: z.string().nullable(),
  property_type: z.string().nullable(),
  redemptions_enabled: z.boolean().nullable(),
  return_calculated: z.string().nullable(),
  route: z.string().optional(),
  shares: z.string().nullable(),
  start_date: z.string().nullable(),
  status: z.string(),
  subhead: z.string().nullable(),
  total_distributions: z.string().nullable(),
  total_invested: z.string().nullable(),
  unread_messages: z.number().int().nullable(),
  url: z.string().nullable(),
  waitlist_details: z
    .object({
      action: z.enum(['waitlist-add', 'waitlist-remove']),
      url: z.string(),
    })
    .nullable(),
})

export type InvestmentDataTypes = z.infer<typeof investmentSchema>

export const investmentsSchema = z.object({
  count: z.number().int(),
  pages: z.number().int(),
  page: z.number().int(),
  page_size: z.number().int().nullable(),
  investments: z.array(investmentSchema),
})

export type InvestmentsDataTypes = z.infer<typeof investmentsSchema>
