import { useRef, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Link } from 'react-router-dom'

import { cn } from '@/utils/utils'
import { useAuthDataState } from '@/routes/shared/_authDataState'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'

declare global {
  interface Window {
    Cypress?: Cypress.Cypress
  }
}

const ListItem = ({
  to,
  className,
  title,
}: {
  to: string
  className?: string
  title: string
}) => {
  return (
    <li>
      <Link
        to={to}
        className={cn(
          'block px-4 py-3 text-base text-content-black whitespace-pre hover:text-[#176DB6]',
          className
        )}
      >
        {title}
      </Link>
    </li>
  )
}

ListItem.displayName = 'ListItem'

export const Header = () => {
  const mobileNavMenu = useMobileNavStore((state) => state.mobileNavMenu)
  const { data: userData } = useAuthDataState()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const FIRST_NAME = !window.Cypress
    ? userData?.attributes?.given_name
    : 'CYPRESS'

  return (
    <div
      className={cn(
        'w-full h-[69px] md:h-[90px] border-b border-b-slate-100',
        mobileNavMenu && 'bg-[rgb(0,0,0,0.25)] border-b-0 pointer-events-none'
      )}
    >
      <div className="w-full h-full max-w-[1800px] flex justify-between p-4 items-center mx-auto relative">
        <a
          href="https://www.realtymogul.com/"
          data-cy="logo-trigger"
          className="group"
        >
          <div className="md:hidden flex items-center gap-4">
            <img src="/rm-tower.svg" />
            <img src="/rm-type.svg" />
          </div>
          <img
            className="hidden ml-4 md:block h-[60px] transition-opacity duration-200 group-hover:opacity-50"
            src="/logo-desktop.svg"
            data-cy="logo-img"
          />
        </a>
        <div className="flex flex-row items-center gap-4">
          <NavigationMenu className="hidden md:block">
            <NavigationMenuList>
              <Link
                to="https://www.realtymogul.com/investment-opportunities"
                className="text-sm text-[#176DB6] block font-medium uppercase tracking-[3.12px] hover:text-content-black"
                data-cy="marketplace-trigger"
              >
                MARKETPLACE
              </Link>
            </NavigationMenuList>
          </NavigationMenu>
          <NavigationMenu className="hidden md:block">
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <span
                    className="text-sm text-[#176DB6] font-medium uppercase tracking-[3.12px] hover:text-content-black"
                    data-cy="1031-exchange-trigger"
                  >
                    1031 EXCHANGE
                  </span>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid gap-3 p-4 md:w-[200px] lg:w-[300px] lg:grid-cols-[.75fr_1fr]">
                    <ListItem
                      to="https://www.realtymogul.com/rm-1031-exchange/knowledge-center"
                      title="Knowledge Center"
                    />
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <NavigationMenu className="hidden md:block">
            <NavigationMenuList>
              <NavigationMenuItem>
                <Link
                  to="https://www.realtymogul.com/rm-communities"
                  className="text-sm text-[#176DB6] block font-medium uppercase tracking-[3.12px] hover:text-content-black"
                  data-cy="communities-trigger"
                >
                  RM COMMUNITIES
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <NavigationMenu className="hidden md:block">
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <span
                    className="text-sm text-[#176DB6] font-medium uppercase tracking-[3.12px] hover:text-content-black"
                    data-cy="reits-trigger"
                  >
                    REITS
                  </span>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid gap-3 p-4 md:w-[200px] lg:w-[300px] lg:grid-cols-[.75fr_1fr]">
                    <ListItem
                      to="https://www.realtymogul.com/investment-options/the-income-reit"
                      title="Income REIT"
                    />
                    <ListItem
                      to="https://www.realtymogul.com/investment-options/the-apartment-growth-reit"
                      title="Growth REIT"
                    />
                    <ListItem
                      to="https://www.realtymogul.com/knowledge-center/why-reits"
                      title="Why REITs"
                    />
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <NavigationMenu className="hidden md:block">
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <span
                    className="text-sm text-[#176DB6] font-medium uppercase tracking-[3.12px] hover:text-content-black"
                    data-cy="research-trigger"
                  >
                    RESEARCH
                  </span>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="ml-auto grid gap-3 p-4 md:w-[275px] lg:grid-cols-[.75fr_1fr]">
                    <ListItem
                      to="https://www.realtymogul.com/knowledge-center/track-record"
                      title="Track Record"
                    />
                    <ListItem
                      to="https://www.realtymogul.com/past-investments"
                      title="Past Investments"
                    />
                    <ListItem
                      to="https://www.realtymogul.com/due-diligence-process"
                      title="Due Diligence Process"
                    />
                    <ListItem
                      to="https://www.realtymogul.com/investment-options"
                      title="Investment Options"
                    />
                    <ListItem
                      to="https://www.realtymogul.com/investment-options/1031-exchange"
                      title="1031 Exchange"
                    />
                    <ListItem
                      to="https://www.realtymogul.com/knowledge-center/why-commercial-real-estate"
                      title="Why Commercial Real Estate"
                    />
                    <ListItem
                      to="https://www.realtymogul.com/knowledge-center"
                      title="Knowledge Center"
                    />
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className="flex-row gap-8 hidden md:flex mr-8">
          <NavigationMenu delayDuration={0} className="hidden md:block">
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  <span
                    className="text-sm text-[#176DB6] font-medium uppercase tracking-[3.12px] hover:text-content-black"
                    data-cy="user-name-trigger"
                  >
                    {FIRST_NAME}
                  </span>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="ml-auto grid gap-3 p-4 md:w-[275px] lg:grid-cols-[.75fr_1fr]">
                    <li>
                      <NavigationMenuLink asChild>
                        <button
                          className="block px-4 py-3 text-base text-content-black whitespace-pre hover:text-[#176DB6]"
                          onClick={(e) => {
                            e.preventDefault()
                            Auth.signOut()
                            window.location.href =
                              'https://www.realtymogul.com/investment-opportunity'
                          }}
                        >
                          Logout
                        </button>
                      </NavigationMenuLink>
                    </li>
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className="md:hidden">
          <button className="p-2 mr-2" onClick={() => setIsMenuOpen(true)}>
            <i className="fa-regular fa-bars" />
          </button>
        </div>
        {isMenuOpen && (
          <div
            ref={contentRef}
            className="absolute left-0 top-0 w-screen bg-bg-light z-50"
          >
            <div className="w-full flex justify-between items-center">
              <div className="pl-6">{FIRST_NAME}</div>
              <button className="p-6 mr-2" onClick={() => setIsMenuOpen(false)}>
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
            <div className="w-full flex flex-col gap-px px-6 pb-6">
              <Link
                to="/dashboard"
                className="p-4 pr-6 bg-white text-[#176DB6]"
                onClick={() => setIsMenuOpen(false)}
              >
                My Dashboard
              </Link>
              <div
                className="p-4 pr-6 bg-white text-[#176DB6]"
                onClick={(e) => {
                  e.preventDefault()
                  Auth.signOut()
                  window.location.href =
                    'https://www.realtymogul.com/investment-opportunity'
                }}
              >
                Sign out
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
