import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { api } from '@/routes/shared/_api'
import { datadogLogs } from '@datadog/browser-logs'

const shareRepurchaseSchema = z.object({
  count: z.number(),
  days_prior_end_of_quarter: z.number(),
  days_processed_end_of_quarter: z.number(),
  eligible_investments: z.array(
    z.object({
      eligible_shares: z.string(),
      investment_account_name: z.string(),
      investment_account_type: z.string(),
      investment_account_uuid: z.string(),
      eligible_date: z.string(),
      trade_date: z.string(),
      has_active_share_repurchase_request: z.boolean(),
      eligible_date_in_past: z.boolean(),
    })
  ),
})

export type ShareRepurchaseTypes = z.infer<typeof shareRepurchaseSchema>

export const getShareRepurchase = async (io_uuid: string) => {
  const response = await api.get(
    `/dashboard/investments/share-repurchase?io_uuid=${io_uuid}`
  )
  const parsedData = shareRepurchaseSchema.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-investments-filters',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof shareRepurchaseSchema
    >
    return unsafeUnparsedData
  } else {
    return parsedData.data
  }
}

export const useShareRepurchase = ({ io_uuid }: { io_uuid: string }) => {
  return useQuery({
    queryKey: ['share-repurchase', io_uuid],
    queryFn: () => getShareRepurchase(io_uuid),
    staleTime: Infinity,
  })
}
