import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
// import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import {
  useConcentrationDataState,
  useMutateConcentration,
} from '../_concentrationDataState'

import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import { cancelInvestment } from '@/routes/shared/_api'
import { track } from '@/utils/analytics'
import Loader from '@/components/Loader'
import { useRoute } from '@/rules/find-route'

const ConcentrationScreen = () => {
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: concentrationData } = useConcentrationDataState()
  const { mutateAsync: mutateConcentration } = useMutateConcentration()
  // const { t } = useTranslation()
  const { findRoute } = useRoute()

  type FormTypes = {
    redFlagQuestion?: 'Yes' | 'No'
    concentrationQuestion?: 'Yes' | 'No'
  }

  const { control: controlRedFlag, handleSubmit: handleSubmitRedFlag } =
    useForm<FormTypes>({
      defaultValues: {
        redFlagQuestion: 'Yes',
      },
    })

  const { control, handleSubmit } = useForm<FormTypes>({
    defaultValues: {
      concentrationQuestion: 'Yes',
    },
  })

  const onSubmitRedFlag = handleSubmitRedFlag(async (data) => {
    if (data.redFlagQuestion === 'No') {
      mutateConcentration({
        investment_uuid: investData?.investment.id,
        red_flag_response: false,
      }).then(() => {
        cancelInvestment(investData.investment?.id).then(() => {
          track('Cancel Investment')
          window.location.href = 'https://www.realtymogul.com'
        })
      })
      return
    }
    mutateConcentration({
      investment_uuid: investData?.investment.id,
      concentration: true,
      red_flag_response: true,
    }).then(() => {
      findRoute({
        ...investData,
        investment_state: {
          ...investData?.investment_state,
          concentration: 'submitted',
        },
      }).then((route) => {
        nav(`/invest/${investData.opportunity.id}/${route.type}`)
      })
    })
  })

  const onSubmit = handleSubmit(async (data) => {
    if (data.concentrationQuestion === 'Yes') {
      nav(`/invest/${investData?.opportunity.id}/concentration/followup`)
    } else {
      mutateConcentration({
        investment_uuid: investData?.investment.id,
        concentration: false,
      }).then(() => {
        findRoute({
          ...investData,
          investment_state: {
            ...investData?.investment_state,
            concentration: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
    }
  })

  return concentrationData ? (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={1} currentStep={8} totalStepCount={10} />
      ) : (
        <Stepper currentSection={1} currentStep={7} totalStepCount={9} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            Concentration Risk
          </h1>
          {concentrationData.red_flagged ? (
            <>
              <p className="mb-6 text-16 text-content-black">
                {concentrationData.red_flag_text}
              </p>
              <form onSubmit={onSubmitRedFlag}>
                <Controller
                  name="redFlagQuestion"
                  control={controlRedFlag}
                  render={({ field }) => (
                    <div className="mb-6 flex flex-col gap-px">
                      <Radio
                        {...field}
                        value="Yes"
                        label="Yes"
                        checked={field.value === 'Yes'}
                      />
                      <Radio
                        {...field}
                        value="No"
                        label="No, please cancel my investment"
                        checked={field.value === 'No'}
                      />
                    </div>
                  )}
                />
                <FormFooter submitFunc={onSubmitRedFlag} />
              </form>
            </>
          ) : (
            <>
              <p className="mb-6 text-16 text-content-black">
                Will this investment result in over 20% of your net worth being
                invested in illiquid investments?
              </p>
              <form onSubmit={onSubmit}>
                <Controller
                  name="concentrationQuestion"
                  control={control}
                  render={({ field }) => (
                    <div className="mb-6 flex flex-col gap-px">
                      <Radio
                        {...field}
                        value="Yes"
                        label="Yes"
                        checked={field.value === 'Yes'}
                      />
                      <Radio
                        {...field}
                        value="No"
                        label="No"
                        checked={field.value === 'No'}
                      />
                    </div>
                  )}
                />
                <FormFooter submitFunc={onSubmit} />
              </form>
            </>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  ) : (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={1} currentStep={8} totalStepCount={10} />
      ) : (
        <Stepper currentSection={1} currentStep={7} totalStepCount={9} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <div className="w-full pt-16 flex justify-center items-center">
            <Loader />
          </div>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default ConcentrationScreen
