import { InvestmentDataTypes } from '../api/schema'

export const DetailsDocuments = ({ data }: { data: InvestmentDataTypes }) => {
  return data.documents?.length ?? 0 > 0 ? (
    <div className="flex flex-col gap-3">
      {data.documents?.map((item) => (
        <a
          className="w-full border border-bg-light inline-flex items-center gap-3 rounded px-3 py-2 cursor-pointer hover:bg-bg-light max-w-[550px] group"
          href={item?.url}
          target="_blank"
          key={item?.id}
        >
          <div className="w-[22.5px] text-center group-hover:hidden">
            <i className="fa-light fa-file fa-lg" />
          </div>
          <div className="w-[22.5px] text-center text-primary-hover hidden group-hover:block">
            <i className="fa-light fa-download fa-lg" />
          </div>
          <div className="text-base">Investor Signed Documents</div>
        </a>
      ))}
    </div>
  ) : (
    <div className="text-base text-content-black">
      No documents yet for this investment.
    </div>
  )
}
