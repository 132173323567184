import { AspectRatio } from '@/components/ui/aspect-ratio'
import { Link } from 'react-router-dom'
import { InvestmentDataTypes } from '../api/schema'
import { FALLBACK_RM_IMAGE } from '@/utils/helpers'

export const DetailsSidebar = ({ data }: { data: InvestmentDataTypes }) => {
  return (
    <div className="flex flex-col w-full max-w-[365px] items-end">
      <div className="w-full mb-4 rounded overflow-hidden">
        <AspectRatio ratio={16 / 9}>
          <img
            src={data.image || FALLBACK_RM_IMAGE}
            alt={data.name}
            className="w-full h-full object-fill"
          />
        </AspectRatio>
      </div>
      {!data.is_reit && (
        <div className="text-sm text-content-black w-full space-y-2 mb-4">
          {data.investment_type && (
            <div className="w-full flex justify-between">
              <div>Investment Type</div>
              <div className="text-right">{data.investment_type}</div>
            </div>
          )}
          {data.investment_entity && (
            <div className="w-full flex justify-between gap-8">
              <div className="whitespace-nowrap">Issuer Name</div>
              <div className="text-right">{data.investment_entity}</div>
            </div>
          )}
        </div>
      )}
      {data.url && (
        <Link to={data.url} className="text-sm self-start underline">
          View investment opportunity
        </Link>
      )}
    </div>
  )
}
