import { Link } from 'react-router-dom'
import { z } from 'zod'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

const activeInvestmentMenuSchema = z.object({
  uuid: z.string().uuid(),
  is_reit: z.boolean().optional(),
})

type ActiveMenuTypes = z.infer<typeof activeInvestmentMenuSchema>

const MenuButton = ({
  text,
  linkLocation = '/dashboard/profile',
}: {
  text: string
  linkLocation?: string
}) => {
  return (
    <Link
      to={linkLocation}
      className="w-full text-left px-4 py-3 hover:bg-bg-light"
    >
      {text}
    </Link>
  )
}

export const ActiveInvestmentMenu = ({
  uuid,
  is_reit = false,
}: ActiveMenuTypes) => {
  return (
    <Popover>
      <PopoverTrigger>
        <i className="px-4 md:px-6 py-4 fa-regular fa-ellipsis-vertical"></i>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="end"
        sideOffset={-55}
        avoidCollisions={false}
        className="w-[187px] text-base px-0 py-2 rounded flex flex-col"
      >
        <MenuButton
          text="Details"
          linkLocation={`/dashboard/investments/details/${uuid}`}
        />
        <MenuButton
          text="Bank Account"
          linkLocation={`/dashboard/bank-accounts`}
        />
        {is_reit && (
          <>
            <MenuButton
              text="DRIP"
              linkLocation={`/dashboard/investments/details/${uuid}`}
            />
          </>
        )}
        <MenuButton
          text="Transactions"
          linkLocation={`/dashboard/transactions?page=1&io_uuid=${uuid}`}
        />
        <MenuButton
          text="Messages"
          linkLocation={`/dashboard/messages?page=1&io_uuid=${uuid}`}
        />
        <MenuButton
          text="Tax Documents"
          linkLocation={`/dashboard/taxes?page=1&io_uuid=${uuid}`}
        />
      </PopoverContent>
    </Popover>
  )
}
