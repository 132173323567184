import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  uploadThirdPartyForm,
  uploadThirdPartyData,
  getDrupalUrl,
} from '../../shared/_api'
import { useInvestDataState } from '../_investDataState'
import { useTenThirtyOneDataState } from '../_tenThirtyOneDataState'
import { useMutateEAV } from '../_eavDataState'

import ErrorAlert from '../../../components/ErrorAlert'
import FormFooter from '../../shared/FormFooter'
import TextInput from '../../../components/TextInput'
import FileUpload from '../../../components/FileUpload'
import Stepper from '../../../components/Stepper'
import Radio from '../../../components/Radio'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'
import { useRoute } from '../../../rules/find-route'
import { useFeatureFlags } from '@/rules/get-feature-flags'

interface ThirdPartyFormTypes {
  preferredMethod: string
  additional_info?: string
  file: File
  third_party_type?: 'cpa_accountant' | 'attorney'
  name?: string
  email_address?: string
  phone_number?: string
  comments?: string
}

const ThirdParty = () => {
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const [screen, setScreen] = useState('preferredMethod')
  const { data: investData } = useInvestDataState()
  const { data: tenThirtyOneData } = useTenThirtyOneDataState()
  const { isLoading, mutateAsync: updateEAV } = useMutateEAV()
  const { t } = useTranslation()
  const { findRoute } = useRoute()
  const { data: featureFlagsData } = useFeatureFlags()

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<ThirdPartyFormTypes>({
    defaultValues: {
      third_party_type: 'cpa_accountant',
    },
  })

  const handleMethodSelect = () => {
    setScreen(
      (
        document.querySelector(
          '[name="preferredMethod"]:checked'
        ) as HTMLInputElement
      )?.value
    )
  }

  // const backLocation = `/invest/${investData?.investment.id}/verification`

  const handleThirdPartyUpload = (file: File) => {
    setValue('file', file)
  }

  const onSubmit = handleSubmit(async (data) => {
    uploadThirdPartyForm(investData.investment?.id, data.file).finally(() => {
      updateEAV({
        ...data,
        verification_type: 'third_party',
        additional_info: data.additional_info,
      })
        .then(() => {
          if (investData.investment?.status === 'pledge') {
            window.location.href = `${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`
          } else {
            findRoute({
              ...investData,
              investment_state: {
                ...investData.investment_state,
                eav: 'submitted',
              },
            }).then((route) => {
              nav(`/invest/${investData.opportunity.id}/${route.type}`)
            })
          }
        })
        .catch((error) => {
          console.error(error.message)
          setServerError(error.message)
        })
    })
  })

  const onSubmitThirdPartyData = handleSubmit(async (data) => {
    uploadThirdPartyData(investData.investment?.id, {
      ...data,
      verification_type: 'third_party',
      additional_info: data.additional_info,
    })
      .then(() => {
        if (
          investData.investment?.status === 'pledge' &&
          !tenThirtyOneData?.investment_uuid
        ) {
          window.location.href = `${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`
        } else {
          nav(
            tenThirtyOneData?.investment_uuid
              ? `/invest/${investData.opportunity.id}/1031complete`
              : `/invest/${investData.opportunity.id}/review`
          )
        }
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={2} currentStep={4} totalStepCount={5} />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            {t('verification:eav')}
          </h1>
          <p className="mb-6 text-base italic text-content-light">
            {t('verification:authorizedThirdParty')}
          </p>
          {screen === 'preferredMethod' && (
            <>
              <p className="mb-6 text-xl font-medium">
                {t('verification:thirdPartyMethod')}
              </p>
              <p className="mb-6 text-16 text-content-black">
                {t('verification:thirdPartyMethodCopy')}
              </p>
              <form className="xl:">
                <div className="mb-5">
                  <p className="mb-2 text-16 text-content-black">
                    {t('verification:preferredMethod')}
                  </p>
                  <div className="flex flex-col gap-px">
                    <Radio
                      name="preferredMethod"
                      value="submitForm"
                      label="Submit a form from a third party."
                    />
                    <Radio
                      name="preferredMethod"
                      value="verificationRequest"
                      label="Send a verification request to a third party."
                    />
                  </div>
                </div>
                <FormFooter
                  submitFunc={handleMethodSelect}
                  disabled={isLoading}
                />
              </form>
            </>
          )}
          {screen == 'submitForm' && (
            <>
              <p className="mb-6 text-xl font-medium">
                {t('verification:thirdPartyForm')}
              </p>
              <p className="mb-6 text-16 text-content-black">
                {t('verification:thirdPartyFormCopy')}
              </p>
              <form className="xl:">
                <div className="mb-6">
                  {Object.keys(errors).length > 0 && <ErrorAlert />}
                  <Controller
                    name="file"
                    control={control}
                    rules={{ required: 'File is required' }}
                    render={({ field }) => (
                      <FileUpload
                        {...field}
                        accept=".pdf"
                        label={t('common:uploadDocument')}
                        uploadFileFunction={handleThirdPartyUpload}
                      />
                    )}
                  />
                  {errors?.file?.message && (
                    <span className="mb-1 text-xs text-alert">
                      {errors.file.message}
                    </span>
                  )}
                </div>
                <p className="mb-6 text-xl font-medium">
                  {t('verification:additionalInformation')}
                </p>
                <p className="mb-6 text-16 text-content-black">
                  {t('verification:additionalInformationCopy')}
                </p>
                <p className="mb-2 text-16 text-content-black">
                  {t('verification:additionalInformation')}
                </p>
                <textarea
                  className="mb-6 h-[100px] w-full rounded border border-[#A4C2D5] p-3"
                  {...register('additional_info')}
                />
                <FormFooter submitFunc={onSubmit} disabled={isLoading} />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </form>
            </>
          )}
          {screen == 'verificationRequest' && (
            <>
              <p className="mb-6 text-xl font-medium">
                {t('verification:thirdPartyVerification')}
              </p>
              <p className="mb-6 text-16 text-content-black">
                {t('verification:thirdPartyVerificationCopy')}
              </p>
              <form onSubmit={onSubmit} className="xl:">
                {Object.keys(errors).length > 0 && <ErrorAlert />}
                <div className="mb-5">
                  <p className="mb-2 text-16 text-content-black">
                    {t('verification:thirdPartyType')}
                  </p>
                  <div className="mb-6">
                    <Controller
                      name="third_party_type"
                      control={control}
                      rules={{ required: 'is required' }}
                      render={({ field }) => (
                        <div
                          className={`${
                            errors?.third_party_type && 'border border-alert'
                          } flex flex-col gap-px`}
                        >
                          <Radio
                            {...field}
                            value="cpa_accountant"
                            label="CPA/Accountant"
                          />
                          <Radio {...field} value="attorney" label="Attorney" />
                        </div>
                      )}
                    />
                    {errors?.third_party_type?.message && (
                      <span className="mb-1 text-xs text-alert">
                        {errors.third_party_type.message}
                      </span>
                    )}
                  </div>
                  <div className="mb-6">
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: 'is required' }}
                      render={({ field, fieldState }) => (
                        <TextInput
                          label="Name"
                          errorMessage={fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-6">
                    <Controller
                      name="email_address"
                      control={control}
                      rules={{
                        required: 'is required',
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: 'Invalid email address',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextInput
                          label="Email"
                          errorMessage={fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-6">
                    <span
                      className={`mb-2 block text-base font-normal ${
                        errors?.phone_number?.message
                          ? 'text-alert'
                          : 'text-content-black'
                      }`}
                    >
                      Phone Number
                    </span>
                    <PhoneInput
                      name="phone_number"
                      className={`phone-input-wrapper mb-2 h-[42px] w-full rounded border ${
                        errors?.phone_number ? 'border-alert' : 'border-input'
                      } px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary`}
                      defaultCountry="US"
                      countryOptionsOrder={['US']}
                      control={control}
                      initialValueFormat="national"
                      limitMaxLength={true}
                      rules={{
                        required: 'is required',
                        validate: {
                          isValidPhoneNumber: (value: string) =>
                            isValidPhoneNumber(value) ||
                            'Please enter a valid phone number.',
                        },
                      }}
                    />
                    {errors?.phone_number?.message && (
                      <p className="mb-1 text-xs text-alert">
                        {errors?.phone_number?.message}
                      </p>
                    )}
                  </div>
                  <p className="mb-2 block text-base font-normal text-content-black">
                    {t('verification:additionalInformation')}
                  </p>
                  <textarea
                    className="h-[100px] w-full rounded border border-[#A4C2D5] p-3"
                    {...register('comments')}
                  />
                </div>
                <FormFooter
                  submitFunc={onSubmitThirdPartyData}
                  disabled={isLoading}
                />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </form>
            </>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default ThirdParty
