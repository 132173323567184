import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { usePatchProfile } from '../api/patch-profile'
import { PhoneTypes } from '../api/schema'
import { toast } from '@/hooks/use-toast'

import { Button } from '@/components/ui/button'
import ErrorAlert from '@/components/ErrorAlert'
import PhoneInput from 'react-phone-number-input/react-hook-form'

export const UpdatePhone = ({
  data,
  callback,
}: {
  data: PhoneTypes
  callback: (screen: string) => void
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<PhoneTypes>({
    defaultValues: {
      phone_number: data?.phone_number || '',
    },
  })

  const { mutateAsync: patchProfile, isLoading } = usePatchProfile()

  const onFormSubmit = handleSubmit((data: PhoneTypes) => {
    if (!data) return
    patchProfile(data).then(() => {
      toast({
        variant: 'success',
        description: 'Phone number updated.',
        position: 'top',
      })
      callback('')
    })
  })

  return (
    <div className="mt-10">
      <div className="bg-[#FFF3B9] text-base text-[#897C00] px-4 py-2 mb-10 w-fit">
        Updates to your contact information below will update your Individual
        Ownership Type and tax documents for the upcoming calendar year.
      </div>
      <div className="max-w-[312px]">
        <form
          onSubmit={onFormSubmit}
          autoComplete="on"
          className="w-full mb-10"
        >
          {Object.keys(errors).length > 0 && (
            <ErrorAlert text="Please fix the error(s) below:" />
          )}
          <div className="mb-10">
            <span
              className={`mb-2 block text-base font-normal ${
                Object.keys(errors).length > 0
                  ? 'text-alert'
                  : 'text-content-black'
              }`}
            >
              Phone Number
            </span>
            <PhoneInput
              name="phone_number"
              className={`phone-input-wrapper mb-2 h-[42px] w-full rounded border ${
                errors?.phone_number ? 'border-alert' : 'border-input'
              } px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary`}
              defaultCountry="US"
              countryOptionsOrder={['US']}
              control={control}
              initialValueFormat="national"
              limitMaxLength={true}
              rules={{
                required: 'is required',
                validate: {
                  isValidPhoneNumber: (value: string) =>
                    isValidPhoneNumber(value) ||
                    'Please enter a valid phone number.',
                },
              }}
            />
            {errors?.phone_number?.message && (
              <p className="mb-1 text-xs text-alert">
                {errors?.phone_number?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <Button
              variant="RM"
              size="RM"
              disabled={isLoading || !isDirty}
              onClick={onFormSubmit}
            >
              Update
            </Button>
            <Button
              variant="RMSecondary"
              size="RMSecondary"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault()
                callback('')
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
