import { forwardRef, useEffect, useState, useRef } from 'react'
import { cn } from '@/utils/utils'

interface FileUploadProps {
  name: string
  accept: string
  label: string
  uploadFileFunction: (file: File) => void
}

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (props, _ref) => {
    const [currentFile, setCurrentFile] = useState<File>()
    const [progress, setProgress] = useState<number>(0)
    const [error, setError] = useState<string>()
    const [isUploading, setIsUploading] = useState(false)
    const uploadBarRef = useRef<HTMLSpanElement>(null)

    let timer: ReturnType<typeof setTimeout>

    const fakeUploadBar = () => {
      timer = setInterval(() => {
        setProgress((progress) => (progress >= 100 ? 100 : progress + 5))
      }, 50)
    }

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      const { files } = event.target
      const selectedFiles = files as FileList
      setProgress(0)
      setIsUploading(true)
      fakeUploadBar()
      try {
        await props.uploadFileFunction(selectedFiles?.[0])
        setCurrentFile(selectedFiles?.[0])
      } catch (e: any) {
        setIsUploading(false)
        setCurrentFile(undefined)
        if (!e?.response?.data.detail)
          setError(
            e?.response?.data?.includes('validation error for GenericFileIn')
              ? 'Invalid file type'
              : e?.message
          )
      }
    }

    const onInputClick = (
      event: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
      const element = event.target as HTMLInputElement
      element.value = ''
      setError('')
    }

    useEffect(() => {
      if (uploadBarRef && uploadBarRef.current) {
        uploadBarRef.current.style.width = `${progress}%`
      }
      if (progress >= 100) {
        clearInterval(timer)
        setIsUploading(false)
      }
    }, [progress])

    return (
      <div>
        {/* Filename/location we send back to the form */}
        <input type="hidden" id="fileName" />
        <input
          type="file"
          name={`${props.name}-internal-file`}
          id={`${props.name}-internal-file`}
          accept={props.accept}
          className="sr-only"
          onClick={onInputClick}
          onChange={handleUpload}
        />
        {!currentFile && !isUploading && (
          <>
            <label
              htmlFor={`${props.name}-internal-file`}
              className={cn(
                'relative flex h-10 cursor-pointer items-center justify-center rounded border border-primary bg-white text-primary hover:text-primary-hover hover:border-primary-hover px-4 py-2 text-center',
                error && 'border-alert'
              )}
            >
              <div>
                <i
                  className="fa-regular fa-upload"
                  style={{ color: '#1D89FF' }}
                ></i>
                <span className="ml-2 text-[14px] font-medium">
                  {props.label}
                </span>
              </div>
            </label>
          </>
        )}

        {isUploading && (
          <div className="relative flex h-10 w-full items-center rounded bg-bg-lighter px-4 py-2 text-center">
            <span className="mr-2">Uploading</span>
            <span className="relative mr-3 h-3 w-full overflow-hidden rounded bg-white">
              <span
                className="fill absolute left-0 top-0 h-full bg-success"
                ref={uploadBarRef}
              ></span>
            </span>
            <button
              onClick={(e) => {
                e.preventDefault()
                setError('')
                setIsUploading(false)
                setCurrentFile(undefined)
              }}
            >
              <i className="fa-regular fa-circle-xmark w-full text-primary"></i>
            </button>
          </div>
        )}
        {currentFile && !isUploading && (
          <div className="relative flex h-10 w-full items-center justify-between rounded bg-bg-lighter px-4 py-2 text-center">
            <div className="flex truncate">
              <span>
                {currentFile.type === 'application/pdf' && (
                  <i className="fa-regular fa-file-pdf" />
                )}
                {currentFile.type.includes('image') && (
                  <i className="fa-regular fa-image" />
                )}
              </span>
              <span className="truncate px-2">{currentFile.name}</span>
            </div>
            <button
              className="justify-self-end"
              onClick={(e) => {
                e.preventDefault()
                setCurrentFile(undefined)
              }}
            >
              <i className="fa-solid fa-trash w-full text-primary"></i>
            </button>
          </div>
        )}
        {error && <div className="text-sm text-alert mt-1">{error}</div>}
      </div>
    )
  }
)

export default FileUpload
