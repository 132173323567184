import { useMutation } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'

export const useDeleteBankAccount = () => {
  return useMutation({
    mutationKey: ['bank-accounts'],
    mutationFn: async ({
      uuid,
      investment_account_uuid,
    }: {
      uuid: string
      investment_account_uuid: string
    }) => {
      api.delete(
        `/dashboard/bank-accounts?bank_account_uuid=${uuid}&investment_account_uuid=${investment_account_uuid}`
      )
    },
  })
}
