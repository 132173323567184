import { Trans, useTranslation } from 'react-i18next'
import FormFooter from '../../shared/FormFooter'
import { useInvestDataState } from '../_investDataState'
import { useAuthDataState } from '../../shared/_authDataState'
import { getDrupalUrl } from '../../shared/_api'
import { useBankAccountsDataState } from '../_bankAccountsDataState'
import { useFeatureFlags } from '@/rules/get-feature-flags'

const CompletedScreen = () => {
  const { data: investData } = useInvestDataState()
  const { data: authData } = useAuthDataState()
  const { data: bankAccountsData } = useBankAccountsDataState()
  const { t } = useTranslation()
  const { data: featureFlagsData } = useFeatureFlags()

  return (
    authData &&
    bankAccountsData && (
      <div className="h-full w-full border-t-4 border-success bg-white p-6 pb-20">
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-6 text-2xl font-bold text-content-black">
              <i
                className="fa-solid fa-circle-check mr-3"
                style={{ color: '#01B6A1' }}
              ></i>
              {t('completed:received')}
            </h1>
            <p className="mb-6 text-16 text-content-black">
              <span className="font-medium">
                {authData?.signInUserSession?.idToken?.payload?.given_name}
              </span>
              {t('completed:congrats')}
            </p>
            {((!bankAccountsData.delayed_funding &&
              investData.investment?.distribution_account_uuid &&
              !investData.investment?.funding_account_uuid) ||
              (bankAccountsData.delayed_funding &&
                bankAccountsData.funding_status ===
                  'wire_instructions_provided')) && (
              <>
                <p className="mb-6 text-[19px] font-medium text-content-black">
                  Wire Instructions
                </p>
                <div className="mb-6 bg-bg-lighter p-3 text-base whitespace-pre-line prose">
                  {bankAccountsData.wire_instructions}
                </div>
              </>
            )}
            <p className="mb-6 text-[19px] font-medium text-content-black">
              {t('completed:next')}
            </p>
            {investData.opportunity.io_type === 'reit' ? (
              <ul className="mb-6 ml-3 list-disc text-base">
                {investData.investment?.distribution_account_uuid &&
                !investData.investment?.funding_account_uuid ? (
                  <>
                    <li className="mb-3">
                      {bankAccountsData.delayed_funding &&
                      bankAccountsData.funding_status ===
                        'wire_instructions_provided' ? (
                        <Trans
                          i18nKey="completed:delayedFundingWire1"
                          values={{
                            userEmail:
                              authData?.signInUserSession?.idToken?.payload
                                ?.email,
                            operationsEmail: 'operations@realtymogul.com',
                          }}
                          components={{
                            a: <a />,
                          }}
                        />
                      ) : (
                        <Trans
                          i18nKey="completed:reitWire2"
                          values={{
                            userEmail:
                              authData?.signInUserSession?.idToken?.payload
                                ?.email,
                            operationsEmail: 'operations@realtymogul.com',
                          }}
                          components={{
                            a: <a />,
                          }}
                        />
                      )}
                    </li>
                    <li className="mb-3">
                      {t('completed:reitWire3')}{' '}
                      <a className="text-primary" href="tel:+14243204205">
                        424-320-4205
                      </a>
                      .
                    </li>
                  </>
                ) : (
                  <>
                    <li className="mb-3">{t('completed:reitAch1')}</li>
                    <li className="mb-3">{t('completed:reitAch2')}</li>
                    <li className="mb-3">{t('completed:reitAch3')}</li>
                  </>
                )}
                <li>
                  {t('completed:questionsCopy')}{' '}
                  <a className="text-primary" href="tel:+14243204205">
                    424-320-4205
                  </a>{' '}
                  or email us at{' '}
                  <a
                    className="text-primary"
                    href="mailto:investor-help@realtymogul.com"
                  >
                    investor-help@realtymogul.com
                  </a>
                  .
                </li>
              </ul>
            ) : (
              <ul className="mb-6 ml-3 list-disc text-base">
                {investData.investment?.distribution_account_uuid &&
                !investData.investment?.funding_account_uuid ? (
                  <>
                    <li className="mb-3">
                      {bankAccountsData.delayed_funding &&
                      bankAccountsData.funding_status ===
                        'wire_instructions_provided' ? (
                        <Trans
                          i18nKey="completed:delayedFundingWire1"
                          values={{
                            userEmail:
                              authData?.signInUserSession?.idToken?.payload
                                ?.email,
                            operationsEmail: 'operations@realtymogul.com',
                          }}
                          components={{
                            a: <a />,
                          }}
                        />
                      ) : (
                        <Trans
                          i18nKey="completed:reitWire2"
                          values={{
                            userEmail:
                              authData?.signInUserSession?.idToken?.payload
                                ?.email,
                            operationsEmail: 'operations@realtymogul.com',
                          }}
                          components={{
                            a: <a />,
                          }}
                        />
                      )}
                    </li>
                    <li className="mb-3">
                      {t('completed:reitWire3')}{' '}
                      <a className="text-primary" href="tel:+14243204205">
                        424-320-4205
                      </a>
                      .
                    </li>
                  </>
                ) : (
                  <>
                    <li className="mb-3">
                      {t('completed:ach1', {
                        ioName: investData.opportunity.name,
                      })}
                    </li>
                    <li className="mb-3">{t('completed:ach2')}</li>
                  </>
                )}

                <li>
                  {t('completed:questionsCopy')}{' '}
                  <a className="text-primary" href="tel:+14243204205">
                    424-320-4205
                  </a>{' '}
                  or email us at{' '}
                  <a
                    className="text-primary"
                    href="mailto:investor-help@realtymogul.com"
                  >
                    investor-help@realtymogul.com
                  </a>
                  .
                </li>
              </ul>
            )}
            <FormFooter
              submitFunc={() =>
                (window.location.href = `${featureFlagsData?.ff_dashboard_redirect ? window.location.origin + '/dashboard' : getDrupalUrl() + 'investor-dashboard/active-investments'}`)
              }
              secondaryLink
              buttonText="Go to Dashboard"
            />
          </div>
        </div>
      </div>
    )
  )
}

export default CompletedScreen
