import axios, { InternalAxiosRequestConfig } from 'axios'
import { Auth } from 'aws-amplify'

import { toast } from '@/hooks/use-toast'

export const getAPIUrl = () => {
  if (window.Cypress) return 'https://api.qa.realtymogul.com/'
  switch (window.location.hostname) {
    case 'my.realtymogul.com':
      return 'https://api.realtymogul.com/'
    case 'my.qa.realtymogul.com':
      return 'https://api.qa.realtymogul.com/'
    case 'my.develop.realtymogul.com':
      return 'https://api.develop.realtymogul.com/'
    case 'my.sandbox.realtymogul.com':
      return 'https://api.sandbox.realtymogul.com/'
    default:
      switch (import.meta.env.VITE_ENV) {
        case 'develop':
          return 'https://api.develop.realtymogul.com/'
        case 'qa':
          return 'https://api.qa.realtymogul.com/'
        case 'local':
          if (window.location.origin.includes('.rtml.localhost')) {
            return 'http://api.rtml.localhost/'
          }
          return 'http://localhost:8000/'
        default:
          return 'https://api.sandbox.realtymogul.com/'
      }
  }
}

export const getDrupalUrl = () => {
  switch (window.location.hostname) {
    case 'my.realtymogul.com':
      return 'https://app.realtymogul.com/'
    case 'my.qa.realtymogul.com':
      return 'https://stg.realtymogul.com/'
    case 'my.develop.realtymogul.com':
      return 'https://int.realtymogul.com/'
    case 'my.sandbox.realtymogul.com':
      return 'https://sandbox.uat.realtymogul.com/'
    default:
      switch (import.meta.env.VITE_ENV) {
        case 'develop':
          return 'https://int.realtymogul.com/'
        case 'qa':
          return 'https://stg.realtymogul.com/'
        case 'local':
          return 'http://app.rtml.localhost/'
        default:
          return 'https://sandbox.uat.realtymogul.com/'
      }
  }
}

export const getBrightspotUrl = () => {
  switch (window.location.hostname) {
    case 'my.realtymogul.com':
      return 'https://www.realtymogul.com/'
    default:
      return 'https://realtymogul-qa.k1.go.brightspot.cloud/'
  }
}

const BASE_API_URL = getAPIUrl()
const BASE_DRUPAL_URL = getDrupalUrl()

function wrapWithAuth(config: InternalAxiosRequestConfig) {
  return Auth.currentSession().then(async (res) => {
    const idToken = res.getIdToken()
    const jwt = idToken.getJwtToken()
    if (config.headers) {
      config.headers.Authorization = 'Bearer ' + jwt
    }
    return config
  })
}

export const api = axios.create({
  baseURL: BASE_API_URL,
})

api.interceptors.request.use(wrapWithAuth)
api.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    const message = error.response?.data?.message || error.message
    toast({
      title: 'Error',
      description: message,
      variant: 'error',
    })
    return Promise.reject(error)
  }
)

const callAPI = (
  api: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH',
  data?: any
) =>
  // Wrap API call with auth token, return the data after the API call
  Auth.currentSession().then(async (res) => {
    if (api.includes('undefined')) return
    const idToken = res.getIdToken()
    const jwt = idToken.getJwtToken()
    return axios({
      method,
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      data,
      url: BASE_API_URL + api,
    }).then((resp) => resp.data)
  })

export const getSignedS3Url = (data?: any, isDashboard?: boolean) =>
  // get signed S3 url for file upload, will return s3_obj_id and presigned S3 data, use this to upload a file with uploadToSignedS3 function
  Auth.currentSession().then(async (res) => {
    const idToken = res.getIdToken()
    const jwt = idToken.getJwtToken()
    return axios({
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      data,
      url: isDashboard
        ? `${BASE_API_URL}dashboard/files/presigned-upload-url`
        : `${BASE_API_URL}files/presigned-upload-url`,
    })
  })

export const uploadToSignedS3 = (file: File, url: string, data: any) => {
  // get signedS3 data with the getSignedS3Url function
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  formData.append('file', file)
  return axios({
    method: 'POST',
    url: url,
    data: formData,
  })
}

export const getStrings = () =>
  Auth.currentSession().then(async (res) => {
    const idToken = res.getIdToken()
    const jwt = idToken.getJwtToken()
    return axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      url: BASE_API_URL + 'i18n/investflow',
    }).then((resp) => resp.data)
  })

export const getIO = (ioId: string) => callAPI('investflow/' + ioId, 'GET')

export const getTokens = () =>
  axios.get(BASE_API_URL + 'webtokens').then((res) => res.data)

export const createAndLoginTestUser = () =>
  axios
    .get(
      `${BASE_DRUPAL_URL}api/v1.0/testaccrediteduser?userValidated=true&userVerified=true&netWorth=1&income=1&ssn=1&dob=1975-02-28`
    )
    .then((res) => {
      return Auth.signIn(res.data.mail, res.data.password)
    })

export const createAndLoginUnverifiedTestUser = () =>
  axios
    .get(
      `${BASE_DRUPAL_URL}api/v1.0/testaccrediteduser?userValidated=0&userVerified=0&netWorth=1&income=1&ssn=1&dob=1975-02-28`
    )
    .then((res) => {
      return Auth.signIn(res.data.mail, res.data.password)
    })

export const loginAsUser = (id: string) =>
  axios.get(`${BASE_DRUPAL_URL}api/v1.0/testuserlogin/${id}`).then((res) => {
    return res.data
  })

export const getDrupalSessionTokens = () =>
  axios
    .get(`${BASE_DRUPAL_URL}api/v1.0/session`, {
      withCredentials: true,
    })
    .then((res) => {
      return res.data
    })

export const getEmailVerifiedStatus = () =>
  callAPI('investflow/user/email-verification', 'GET')

export const getPersonalInfo = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/personal-info', 'GET')

export const updatePersonalInfo = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/personal-info', 'POST', data)

export const updateIdentity = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/identity', 'POST', data)

export const getAccounts = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/investment-account', 'GET')

export const getCustodians = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/eligible-custodians', 'GET')

export const updateAccount = (
  investmentId: string,
  accountId: string,
  data: any
) =>
  callAPI(
    'investflow/' + investmentId + '/investment-account/' + accountId,
    'POST',
    data
  )

export const addAccount = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/investment-account', 'POST', data)

export const cancelInvestment = (investmentId: string) =>
  callAPI('investflow/' + investmentId, 'DELETE')

export const getAmount = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/amount', 'GET')

export const setAmount = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/amount', 'POST', data)

export const getAccredQuestions = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/self_accred/questions', 'GET')

export const postAccredQuestions = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/self_accred', 'POST', data)

export const getISQQuestions = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/qualification', 'GET')

export const updateISQ = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/qualification', 'POST', data)

export const getConcentration = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/concentration', 'GET')

export const updateConcentration = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/concentration', 'POST', data)

export const getEmployment = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/employment', 'GET')

export const updateEmployment = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/employment', 'POST', data)

export const getIdentity = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/identity', 'GET')

export const getBankAccounts = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/bank_accounts', 'GET')

export const getWireInstructions = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/wire_instructions', 'GET')

export const getAgreements = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/agreements', 'GET')

export const getDocusign = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/agreements/docusign', 'GET')

export const checkDocusign = (
  investmentId: string,
  envelopeId: string,
  status: string
) =>
  callAPI('investflow/' + investmentId + '/agreements/docusign', 'POST', {
    envelopeId,
    status,
  })

export const getPlaidToken = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/plaid/public_token', 'POST', data)

export const addManualBankAccount = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/bank_accounts', 'POST', data)

export const updateBankAccount = (investmentId: string, data: any) =>
  callAPI('investments/' + investmentId + '/bank_accounts', 'PATCH', data)

export const getPlaidEndpoint = (investmentId: string) => {
  if (!investmentId) return
  return `${BASE_API_URL}investflow/${investmentId}/plaid/link_token`
}

export const getEAV = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/eav', 'GET')

export const updateVerificationMethod = (investmentId: string, data: any) =>
  callAPI(
    'investflow/' + investmentId + '/eav/verification_method',
    'POST',
    data
  )

export const postUploadSignature = (investmentId: string, data: any) =>
  callAPI(
    `investflow/${investmentId}/agreements/upload_signature`,
    'POST',
    data
  )

export const uploadThirdPartyData = (investmentId: string, data: any) =>
  callAPI(
    'investflow/' + investmentId + '/eav/third_party_request',
    'POST',
    data
  )

export const uploadThirdPartyForm = async (
  investmentId: string,
  file: File,
  additionalData?: any
) => {
  const data = await callAPI(
    `investflow/${investmentId}/eav/third_party_form`,
    'POST',
    {
      file_name: file.name,
      extension: file.name.split('.').pop(),
      ...additionalData,
    }
  )
  return uploadToSignedS3(file, data.url, data.fields)
}

export const uploadIncomeForm = async (
  investmentId: string,
  file: File,
  additionalData?: any
) => {
  const data = await callAPI(
    `investflow/${investmentId}/eav/income_verification`,
    'POST',
    {
      file_name: file.name,
      extension: file.name.split('.').pop(),
      ...additionalData,
    }
  )
  return uploadToSignedS3(file, data.url, data.fields)
}

export const uploadNetWorthForm = async (
  investmentId: string,
  file: File,
  additionalData?: any
) => {
  const data = await callAPI(
    `investflow/${investmentId}/eav/net_worth_verification`,
    'POST',
    {
      file_name: file.name,
      extension: file.name.split('.').pop(),
      ...additionalData,
    }
  )
  return uploadToSignedS3(file, data.url, data.fields)
}

export const get1031 = (investmentId: string) =>
  callAPI('investflow/' + investmentId + '/1031', 'GET')

export const update1031 = (investmentId: string, data: any) =>
  callAPI('investflow/' + investmentId + '/1031', 'POST', data)

export const postVeriCheck = (investmentId: string, data: any) =>
  callAPI(
    'investflow/' + investmentId + '/investment/vericheck_disclosure',
    'POST',
    data
  )

export const patchMessageRead = (message_uuid: string) =>
  callAPI('dashboard/messages/' + message_uuid, 'PATCH', {
    unread: false,
  })
