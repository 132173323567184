import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { differenceInYears, isValid, parse } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useAccountDataState } from '../_accountDataState'
import { useIdentityDataState, useMutateIdentity } from '../_identityDataState'
import { dateIn, dateOut } from '../../../utils/helpers'

import DateInput from '../../../components/DateInput'
import FormFooter from '../../shared/FormFooter'
import SSNInput from '../../../components/SSNInput'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Loader from '../../../components/Loader'
import { useRoute } from '../../../rules/find-route'

interface FormTypes {
  individual_dob: string
  individual_ssn: string
  joint_dob: string
  joint_ssn: string
}

const IdentityScreen = () => {
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: accountData } = useAccountDataState()
  const { data: identityData } = useIdentityDataState()
  const { isLoading, mutateAsync: mutateIdentity } = useMutateIdentity()
  const { t } = useTranslation()
  const { findRoute } = useRoute()

  const { control, handleSubmit, setValue } = useForm<FormTypes>({
    defaultValues: {
      individual_dob: '',
      individual_ssn: '',
    },
  })

  useEffect(() => {
    if (!identityData) return
    if (identityData.individual_dob) {
      setValue('individual_dob', dateIn(identityData.individual_dob))
    }
    if (
      identityData.individual_ssn &&
      identityData.individual_has_approved_investments
    ) {
      setValue('individual_ssn', identityData.individual_ssn)
    }
    if (identityData.joint_dob) {
      setValue('joint_dob', dateIn(identityData.joint_dob))
    }
    if (identityData.joint_ssn && identityData.joint_has_approved_investments) {
      setValue('joint_ssn', identityData.joint_ssn)
    }
  }, [identityData, setValue])

  const onSubmit = handleSubmit((data) => {
    mutateIdentity({
      ...data,
      individual_dob: data.individual_dob ? dateOut(data.individual_dob) : null,
      joint_dob: data.joint_dob ? dateOut(data.joint_dob) : null,
    })
      .then(() => {
        findRoute({
          ...investData,
          investment_state: {
            ...investData?.investment_state,
            identity_state: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={1} currentStep={9} totalStepCount={9} />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('identity:identityVerification')}
          </h1>
          {accountData && identityData ? (
            <>
              <p className="mb-6 text-16 text-content-black">
                <i className="fa-solid fa-shield mr-2 text-primary" />
                {t('identity:encryptionCopy')}
              </p>
              <p className="mb-6 text-16 text-content-black">
                {t('identity:requiredText')}
              </p>
              <form onSubmit={onSubmit} className="xl:">
                <div className="flex flex-wrap items-start justify-between">
                  <div className="mb-2 w-full">
                    {t('identity:individualInvestor')}
                  </div>
                  <div className="mb-6 w-[48%]">
                    <Controller
                      name="individual_dob"
                      control={control}
                      disabled={
                        identityData.individual_has_approved_investments
                      }
                      rules={{
                        required: 'is required',
                        validate: {
                          isValidDate: (value) =>
                            isValid(parse(value, 'MM/dd/yyyy', new Date())) ||
                            'is not a valid date',
                          isEighteenOrOlder: (value) => {
                            const birthDate = parse(
                              value,
                              'MM/dd/yyyy',
                              new Date()
                            )
                            const today = new Date()
                            const age = differenceInYears(today, birthDate)
                            return (
                              age >= 18 || 'You must be at least 18 years old.'
                            )
                          },
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <DateInput
                          label="Date of Birth"
                          placeholder="mm/dd/yyyy"
                          errorMessage={fieldState.error}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-6 w-[48%]">
                    <Controller
                      name="individual_ssn"
                      control={control}
                      disabled={
                        identityData.individual_has_approved_investments
                      }
                      rules={{
                        required: 'is required',
                        validate: {
                          isLongEnough: (value) => {
                            const ssn = value.replace(/-/g, '')
                            return ssn.length === 9 || 'is not a valid SSN'
                          },
                          isValidSSN: (value) => {
                            const ssnArr = value.split('-')
                            return (
                              (ssnArr[0] !== '000' &&
                                ssnArr[0] !== '666' &&
                                ssnArr[1] !== '00' &&
                                ssnArr[2] !== '0000' &&
                                Array.from(ssnArr[0])[0] !== '9') ||
                              'is not a valid SSN'
                            )
                          },
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <SSNInput
                          {...field}
                          label="Social Security No."
                          placeholder="___-__-____"
                          errorMessage={fieldState.error}
                        />
                      )}
                    />
                  </div>
                </div>
                {accountData?.current_account?.type === 'joint_ownership' && (
                  <div className="flex flex-wrap items-start justify-between">
                    <div className="mb-2 w-full">Joint Investor</div>
                    <div className="mb-6 w-[48%]">
                      <Controller
                        name="joint_dob"
                        control={control}
                        disabled={identityData.joint_has_approved_investments}
                        rules={{
                          required: 'is required',
                          validate: {
                            isValidDate: (value) =>
                              isValid(parse(value, 'MM/dd/yyyy', new Date())) ||
                              'is not a valid date',
                            isEighteenOrOlder: (value) => {
                              const birthDate = parse(
                                value,
                                'MM/dd/yyyy',
                                new Date()
                              )
                              const today = new Date()
                              const age = differenceInYears(today, birthDate)
                              return (
                                age >= 18 ||
                                'Joint account holder must be at least 18 years old.'
                              )
                            },
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <DateInput
                            label="Date of Birth"
                            placeholder="mm/dd/yyyy"
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-6 w-[48%]">
                      <Controller
                        name="joint_ssn"
                        control={control}
                        disabled={identityData.joint_has_approved_investments}
                        rules={{
                          required: 'is required',
                          validate: {
                            isLongEnough: (value) => {
                              const ssn = value.replace(/-/g, '')
                              return ssn.length === 9 || 'is not a valid SSN'
                            },
                            isValidSSN: (value) => {
                              const ssnArr = value.split('-')
                              return (
                                (ssnArr[0] !== '000' &&
                                  ssnArr[0] !== '666' &&
                                  ssnArr[1] !== '00' &&
                                  ssnArr[2] !== '0000' &&
                                  Array.from(ssnArr[0])[0] !== '9') ||
                                'is not a valid SSN'
                              )
                            },
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <SSNInput
                            {...field}
                            label="Social Security No."
                            placeholder="___-__-____"
                            errorMessage={fieldState.error}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
                <FormFooter submitFunc={onSubmit} disabled={isLoading} />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </form>
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default IdentityScreen
