import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { cn } from '@/utils/utils'
import { mapParams } from '../shared/map-params'
import { moneyMask, amountRegexDecimal } from '@/utils/helpers'
import { useInvestments } from './api/get-investments'
import { useInvestmentsFilters } from './api/get-investments-filters'
import { useNotificationsStore } from './api/use-notifications-store'
import { useUnreadDataQuery } from '../api/get-unread-messages'

import { EmptyState } from '../components/empty-state'
import { Filter } from './components/filter'
import { InvestmentsSubNav } from './components/investments-subnav'
import Loader from '@/components/Loader'
import { Pagination } from './components/pagination'
import { ActiveInvestmentCard } from './components/active-investment-card'
import { CompletedInvestmentCard } from './components/completed-investment-card'
import { PendingInvestmentCard } from './components/pending-investment-card'
import { WaitlistInvestmentCard } from './components/waitlist-investment-card'
import { PledgedInvestmentCard } from './components/pledged-investment-card'
import { SkeletonInvestmentCard } from './components/skeleton-investment-card'
import { useQueryClient } from '@tanstack/react-query'
import { hasSpecificFilters } from '../shared/has-specific-filters'
import { Skeleton } from '@/components/ui/skeleton'
import { InfoTooltip } from '../components/info-tooltip'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

export const Investments = () => {
  const { type } = useParams() // pathname: dashboard/investments/:type
  const [searchParams, setSearchParams] = useSearchParams()
  const showAll = searchParams.get('showAll')
  const nav = useNavigate()
  const { data: unreadData } = useUnreadDataQuery()
  const hideNewMessages = useNotificationsStore(
    (state) => state.hideNewMessages
  )
  const hideTodos = useNotificationsStore((state) => state.hideTodos)
  const pendingActionItems = useNotificationsStore(
    (state) => state.pendingActionItems
  )
  const pledgedActionItems = useNotificationsStore(
    (state) => state.pledgedActionItems
  )
  const removeNewMessagesNotification = useNotificationsStore(
    (state) => state.removeNewMessagesNotification
  )
  const removeTodosNotification = useNotificationsStore(
    (state) => state.removeTodosNotification
  )
  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  useEffect(() => updateMobileNav(true), [updateMobileNav])

  const { data, isLoading, isError, isFetching } = useInvestments()
  const { data: filtersData } = useInvestmentsFilters()

  useEffect(() => {
    if (!data) return
    if (
      !type ||
      (type !== 'active' &&
        type !== 'completed' &&
        type !== 'pending' &&
        type !== 'waitlist' &&
        type !== 'pledged')
    ) {
      nav('/dashboard/investments/active')
      return
    }
  }, [data, type, nav])

  useEffect(() => {
    const page = parseInt(searchParams.get('page') ?? '0') || 1
    setPagination((pagination) => {
      return { ...pagination, pageIndex: page - 1 }
    })
  }, [searchParams])

  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 5,
  })

  const totalInvested = useMemo(() => {
    if (type !== 'active' && type !== 'completed') return 0
    return (
      data?.[type]?.reduce((total: number, investment) => {
        return total + Number(investment.total_invested)
      }, 0) ?? 0
    )
  }, [data, type])

  const totalDistributed = useMemo(() => {
    if (type !== 'active' && type !== 'completed') return 0
    return (
      data?.[type]?.reduce((total: number, investment) => {
        return total + Number(investment.total_distributions)
      }, 0) ?? 0
    )
  }, [data, type])

  const totalIRR = useMemo(() => {
    if (type !== 'completed') return 0
    const number =
      data?.completed?.reduce((total: number, investment) => {
        return (
          total +
          Number(investment.return_calculated) *
            Number(investment.total_invested)
        )
      }, 0) ?? 0
    return number ? (number / totalInvested).toFixed(2) : '-.-'
  }, [data, type, totalInvested])

  const totalPendingIncomplete = useMemo(() => {
    if (type !== 'pending') return 0
    return data?.pending?.filter(
      (investment) =>
        investment.route !== 'funded' &&
        (investment.route !== 'completed' ||
          investment.investment?.wire_instructions)
    ).length
  }, [data, type])

  const totalPledgedIncomplete = useMemo(() => {
    if (type !== 'pledged') return 0
    return data?.pledged?.filter(
      (investment) =>
        investment.route !== 'pledgecomplete' &&
        investment.route !== '1031submitted'
    ).length
  }, [data, type])

  const queryClient = useQueryClient()

  const handleFilterUpdate = (name: string, uuid: string) => {
    const paramName = mapParams(name)
    const nextSearchParams = new URLSearchParams(searchParams)
    uuid === 'all'
      ? nextSearchParams.delete(paramName)
      : nextSearchParams.set(paramName, uuid)
    setSearchParams(nextSearchParams)
    setPagination({ ...pagination, pageIndex: 0 })
    queryClient.invalidateQueries(['investments', uuid])
  }

  const handleSectionFilterUpdate = (_: string, page: string) => {
    nav(`/dashboard/investments/${page}`)
  }

  return data && filtersData ? (
    <div className="w-full h-full md:mb-10 p-6 md:p-0">
      <div className="w-full flex flex-col h-full">
        <div className="w-full flex flex-col-reverse md:flex-row items-start justify-between md:items-center md:flex-wrap">
          <div className="w-full flex flex-col gap-2 md:flex-row md:justify-between mb-4">
            <h1 className="text-27 md:text-31 mb-2 font-bold self-start">
              Investments
            </h1>
            <Filter
              name="ownership_types"
              data={filtersData.investment_account_filters}
              updateCallback={handleFilterUpdate}
              defaultValue={
                searchParams.get('investment_account_uuid') || 'all'
              }
              className="w-full md:w-fit"
            />
            <Filter
              data={[
                { name: 'Active', uuid: 'active' },
                { name: 'Completed', uuid: 'completed' },
                { name: 'Pending', uuid: 'pending' },
                { name: 'Waitlist', uuid: 'waitlist' },
                { name: 'Pledged', uuid: 'pledged' },
              ]}
              updateCallback={handleSectionFilterUpdate}
              defaultValue={type || 'active'}
              className="w-full md:hidden"
            />
          </div>

          {Object.keys(data).length > 0 ? (
            <div>
              {(unreadData ?? 0) > 0 && !hideNewMessages && (
                <div
                  className={cn(
                    data.pending?.length > 0 || data.pledged?.length > 0
                      ? 'mb-4'
                      : 'mb-10',
                    hideTodos && 'mb-10'
                  )}
                >
                  <div className="w-full md:w-fit h-10 pl-2 pr-3 bg-[#1D89FF0D] rounded-lg md:rounded-[40px] justify-between items-center gap-3 inline-flex">
                    <div className="flex flex-row gap-2">
                      <div className="px-3 py-0.5 bg-[#1d89ff] rounded-[43px] justify-start items-center gap-1 hidden md:flex w-fit">
                        <div className="text-white text-base font-medium">
                          New
                        </div>
                      </div>
                      <div className="justify-start items-center gap-1.5 flex">
                        <div className="text-content-black text-base font-normal">
                          You have
                        </div>
                        <div className="justify-start items-center gap-2 flex">
                          <Link
                            to="/dashboard/messages?unread=true&page=1"
                            className="text-content-black text-sm font-medium underline"
                          >
                            {unreadData} new message
                            {unreadData ?? 0 > 1 ? 's' : ''}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <button
                      className="text-center p-1 text-[#1d89ff] text-base font-normal"
                      onClick={() => removeNewMessagesNotification()}
                    >
                      <i className="fa-solid fa-lg fa-xmark" />
                    </button>
                  </div>
                </div>
              )}
              {(pendingActionItems > 0 || pledgedActionItems > 0) &&
                !hideTodos && (
                  <div className="mb-8">
                    <div className="w-full md:w-fit h-auto md:h-10 pl-2 pr-3 bg-[#fffcef] rounded-lg md:rounded-[40px] justify-between items-center gap-3 flex py-2">
                      <div className="flex flex-row gap-2">
                        <div className="px-3 py-0.5 bg-[#fff3b9] rounded-[43px] justify-start items-center gap-1 hidden md:flex w-fit">
                          <div className="text-[#826600] text-base font-medium ">
                            To-do
                          </div>
                        </div>
                        <div className="justify-start items-center text-base font-normal gap-1.5 inline-flex flex-wrap">
                          <span className="text-content-black">You have</span>
                          {pendingActionItems > 0 ? (
                            <span className="justify-start items-center gap-2 inline-flex">
                              <Link
                                to="/dashboard/investments/pending"
                                className="text-content-black text-sm font-medium underline"
                              >
                                {pendingActionItems} pending investment
                                {pendingActionItems > 1 ? 's' : ''}
                              </Link>
                            </span>
                          ) : null}
                          {pendingActionItems > 0 && pledgedActionItems > 0 ? (
                            <span> and </span>
                          ) : null}
                          {pledgedActionItems > 0 ? (
                            <div className="justify-start items-center gap-2 flex">
                              <Link
                                to="/dashboard/investments/pledged"
                                className="text-content-black text-sm font-medium underline"
                              >
                                {pledgedActionItems} pledged investment
                                {pledgedActionItems > 1 ? 's' : ''}
                              </Link>
                            </div>
                          ) : null}
                          <span>
                            {`that ${
                              pendingActionItems + pledgedActionItems > 1
                                ? 'require'
                                : 'requires'
                            } action.`}
                          </span>
                        </div>
                      </div>
                      <button
                        className="text-center p-1 text-[#826600] text-base font-normal"
                        onClick={() => removeTodosNotification()}
                      >
                        <i className="fa-solid fa-lg fa-xmark" />
                      </button>
                    </div>
                  </div>
                )}
            </div>
          ) : null}
        </div>

        {Object.keys(data).length > 0 ? (
          <>
            <div className="md:mb-10">
              <InvestmentsSubNav />
            </div>
            {data[type ?? '']?.length > 0 ? (
              <>
                <div className="flex flex-col md:flex-row justify-between md:items-center gap-4 mb-4">
                  {!isFetching ? (
                    <div className="text-16">
                      <span className="font-medium">
                        {data[type ?? '']?.length || 0} {type}
                      </span>
                      <span>
                        {' '}
                        investment{data[type ?? '']?.length > 1 ? 's' : ''}
                        {type === 'pending' && totalPendingIncomplete ? (
                          <span className="italic">{` - ${totalPendingIncomplete} incomplete`}</span>
                        ) : null}
                        {type === 'pledged' && totalPledgedIncomplete ? (
                          <span className="italic">{` - ${totalPledgedIncomplete} incomplete`}</span>
                        ) : null}
                      </span>
                      {hasSpecificFilters(['investment_account_uuid']) ? (
                        <span>
                          {' - '}
                          <span
                            className="underline cursor-pointer"
                            onClick={() => {
                              const nextSearchParams = new URLSearchParams({
                                page: '1',
                              })
                              setSearchParams(nextSearchParams)
                            }}
                          >
                            reset all filters
                          </span>
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <Skeleton className="w-[420px] h-[22px]" />
                  )}
                  {(type === 'active' || type === 'completed') && (
                    <div className="flex flex-col md:flex-row gap-4">
                      {type === 'completed' && (
                        <div className="flex w-full md:w-[200px] justify-between flex-row md:flex-col items-center md:items-end gap-2">
                          <div className="text-16 md:text-base">
                            Weighted Average IRR
                            <InfoTooltip className="hidden md:inline-block ml-1">
                              Internal Rate of Return (IRR) represents an
                              offering's annualized return. The Weighted Average
                              IRR for your completed investments is determined
                              by summing the products of each investment's IRR
                              by its principal balance, and dividing the sum by
                              the total amount originally invested across all
                              completed opportunities. This value only includes
                              private placements and does not include REITs.
                            </InfoTooltip>
                          </div>
                          <div className="text-17 md:text-2xl font-medium">
                            {totalIRR}%
                          </div>
                        </div>
                      )}
                      <div
                        className={cn(
                          'flex w-full flex-row justify-between md:flex-col items-end gap-2',
                          type === 'completed' ? 'md:w-[200px]' : 'md:w-[170px]'
                        )}
                      >
                        <div className="text-16 md:text-base">
                          <span className="mr-1">Total Invested</span>
                          <InfoTooltip className="hidden md:inline-block ml-1">
                            Total amount invested by you in transactions that
                            are completed.
                          </InfoTooltip>
                        </div>
                        <div className="text-17 md:text-2xl font-medium">
                          ${moneyMask(totalInvested, amountRegexDecimal)}
                        </div>
                      </div>
                      <div
                        className={cn(
                          'flex w-full flex-row justify-between md:flex-col items-end gap-2',
                          type === 'completed' ? 'md:w-[200px]' : 'md:w-[170px]'
                        )}
                      >
                        <div className="text-16 md:text-base">
                          <span className="mr-1">Total Distributed</span>
                          <InfoTooltip className="hidden md:inline-block ml-1">
                            All amounts distributed to you on transactions that
                            are completed.
                          </InfoTooltip>
                        </div>
                        <div className="text-17 md:text-2xl font-medium">
                          ${moneyMask(totalDistributed, amountRegexDecimal)}
                        </div>
                      </div>
                      <div className="w-[52px]"></div>
                    </div>
                  )}
                </div>
                <div className="w-full flex flex-col gap-6 mb-6">
                  {!isFetching ? (
                    (showAll
                      ? data[type ?? ''].slice(0, data[type ?? ''].length ?? 0)
                      : data[type ?? ''].slice(
                          pagination.pageIndex * 5,
                          pagination.pageIndex * 5 + 5
                        )
                    ).map((investment) => {
                      return (
                        <div
                          key={investment.name}
                          className="w-full border border-bg-normal rounded overflow-hidden"
                        >
                          {type === 'active' && (
                            <ActiveInvestmentCard investment={investment} />
                          )}
                          {type === 'completed' && (
                            <CompletedInvestmentCard investment={investment} />
                          )}
                          {type === 'pending' && (
                            <PendingInvestmentCard investment={investment} />
                          )}
                          {type === 'waitlist' && (
                            <WaitlistInvestmentCard investment={investment} />
                          )}
                          {type === 'pledged' && (
                            <PledgedInvestmentCard investment={investment} />
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <div className="flex flex-col gap-6 mb-6">
                      <SkeletonInvestmentCard />
                      <SkeletonInvestmentCard />
                      <SkeletonInvestmentCard />
                      <SkeletonInvestmentCard />
                      <SkeletonInvestmentCard />
                    </div>
                  )}
                </div>
                {type === 'pending' && (
                  <div className="mt-4 mb-10 text-base text-content-light">
                    To cancel a pending investment, please{' '}
                    <a
                      className="underline cursor-pointer"
                      href="mailto:investor-help@realtymogul.com"
                    >
                      contact us
                    </a>
                    .
                  </div>
                )}
                {data[type ?? '']?.length > 4 ? (
                  <>
                    <div className="hidden md:block">
                      <Pagination
                        pagination={pagination}
                        totalPages={
                          Math.ceil(data[type ?? '']?.length / 5) || 0
                        }
                        totalCount={data[type ?? '']?.length || 0}
                        changePage={(newPage) => {
                          const newSearchParams = new URLSearchParams(
                            searchParams
                          )
                          newSearchParams.set('page', (newPage + 1).toString())
                          setSearchParams(newSearchParams)
                        }}
                      />
                    </div>
                    {!showAll && (
                      <div className="md:hidden w-full flex flex-row-reverse">
                        <button
                          className="underline font-medium text-base"
                          onClick={() => {
                            const newSearchParams = new URLSearchParams(
                              searchParams
                            )
                            newSearchParams.set('showAll', 'true')
                            setSearchParams(newSearchParams)
                          }}
                        >
                          Show All
                        </button>
                      </div>
                    )}
                  </>
                ) : null}
              </>
            ) : hasSpecificFilters(['investment_account_uuid']) ? (
              <div>
                <span className="font-medium">0 {type}</span> investments -{` `}
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    const nextSearchParams = new URLSearchParams({
                      page: '1',
                    })
                    setPagination({ ...pagination, pageIndex: 0 })
                    setSearchParams(nextSearchParams)
                  }}
                >
                  reset all filters
                </span>
              </div>
            ) : isFetching ? (
              <div className="flex flex-col gap-6 mb-6">
                <SkeletonInvestmentCard />
                <SkeletonInvestmentCard />
                <SkeletonInvestmentCard />
                <SkeletonInvestmentCard />
                <SkeletonInvestmentCard />
              </div>
            ) : (
              <div className="py-40">
                <EmptyState
                  iconClasses="fa-regular fa-chart-line fa-3x text-primary opacity-20"
                  copy={`You have no ${type} investments.`}
                  linkCopy="Browse Current Investments"
                  linkTo="https://www.realtymogul.com/investment-opportunities"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div>
              <span className="font-medium">0</span> investments
            </div>
            <div className="flex flex-auto h-full justify-center items-center">
              <EmptyState
                iconClasses="fa-regular fa-chart-line fa-3x text-primary opacity-20"
                copy="You have no investments."
                linkCopy="Browse Current Investments"
                linkTo="https://www.realtymogul.com/investment-opportunities"
              />
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="h-[75vh] flex justify-center items-center">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your investments."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default Investments
