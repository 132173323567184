import { useQuery } from '@tanstack/react-query'

import { getEmailVerifiedStatus } from '../shared/_api'

export const useEmailVerifiedDataState = () => {
  return useQuery({
    queryKey: ['emailVerified'],
    queryFn: getEmailVerifiedStatus,
    staleTime: Infinity,
  })
}
