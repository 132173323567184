import { datadogLogs } from '@datadog/browser-logs'
import { allAccountSchema } from './schema'
import { api } from '@/routes/shared/_api'
import { z } from 'zod'

export const getAccount = async (uuid: string) => {
  const response = await api.get(`dashboard/investment-accounts/${uuid}`)
  const parsedData = allAccountSchema.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-ownership-types',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof allAccountSchema
    >
    return unsafeUnparsedData
  } else {
    return parsedData.data
  }
}
