import { datadogLogs } from '@datadog/browser-logs'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { api } from '@/routes/shared/_api'
import { messagesFiltersSchema, MessagesFilterTypes } from './schema'

export const getMessagesFilters = async () => {
  const response = await api.get('/dashboard/messages/filters')
  const parsedData = messagesFiltersSchema.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-messages-filters',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof messagesFiltersSchema
    >
    return unsafeUnparsedData
  } else {
    return parsedData.data
  }
}

export const useMessagesFilters = () => {
  return useQuery<MessagesFilterTypes>({
    queryKey: ['messages-filters'],
    queryFn: getMessagesFilters,
    staleTime: Infinity,
  })
}
