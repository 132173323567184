import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { BankAccountsTypes, bankAccountsSchema } from './schema'
import { datadogLogs } from '@datadog/browser-logs'
import { z } from 'zod'

const ORDER = ['individual', 'joint', 'foe_trust', 'foe_entity', 'sdira']

export const getAccounts = async () => {
  const response = await api.get('/dashboard/bank-accounts')
  const parsedData = bankAccountsSchema?.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-bank-accounts',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof bankAccountsSchema
    >
    return unsafeUnparsedData?.sort((a, b) => {
      if (!a?.investment_account_type || !b?.investment_account_type) return 0
      return (
        ORDER.indexOf(a.investment_account_type) -
        ORDER.indexOf(b.investment_account_type)
      )
    })
  } else {
    return parsedData.data?.sort((a, b) => {
      if (!a?.investment_account_type || !b?.investment_account_type) return 0
      return (
        ORDER.indexOf(a.investment_account_type) -
        ORDER.indexOf(b.investment_account_type)
      )
    })
  }
}

export const useBankAccounts = () => {
  return useQuery<BankAccountsTypes>({
    queryKey: ['bank-accounts'],
    queryFn: getAccounts,
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
