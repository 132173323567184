type UnreadFilterTypes = {
  count: number
  unreadCount?: number
  handleUnreadClick: () => void
  handleViewAllClick: () => void
  unreadFilterOn?: string
}

export function UnreadFilter({
  count,
  unreadCount,
  handleUnreadClick,
  handleViewAllClick,
  unreadFilterOn = 'false',
}: UnreadFilterTypes) {
  const handleClick = () => {
    if (unreadFilterOn === 'true') handleViewAllClick()
    else handleUnreadClick()
  }

  return unreadFilterOn === 'true' ? (
    <div className="text-black text-base">
      {unreadCount ? (
        <>
          <span>{`${unreadCount.toLocaleString()} unread - `}</span>
          <span className="cursor-pointer underline" onClick={handleClick}>
            view all
          </span>
        </>
      ) : (
        <span>No unread messages</span>
      )}
    </div>
  ) : (
    <div>
      {count.toLocaleString()} messages
      {unreadCount ? ', ' : ''}
      {unreadCount ? (
        <span className="underline cursor-pointer" onClick={handleClick}>
          {unreadCount.toLocaleString()} unread
        </span>
      ) : null}
    </div>
  )
}
