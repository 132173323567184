import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { api } from '@/routes/shared/_api'
import { datadogLogs } from '@datadog/browser-logs'

const investmentFiltersSchema = z.object({
  investment_account_filters: z.array(
    z.object({ name: z.string(), uuid: z.string().uuid() })
  ),
})

export type InvestmentFilters = z.infer<typeof investmentFiltersSchema>

export const getInvestmentsFilters = async () => {
  const response = await api.get('/dashboard/investments/filters')
  const parsedData = investmentFiltersSchema.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-investments-filters',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof investmentFiltersSchema
    >
    return unsafeUnparsedData
  } else {
    return parsedData.data
  }
}

export const useInvestmentsFilters = () => {
  return useQuery<InvestmentFilters>({
    queryKey: ['investments-filters'],
    queryFn: getInvestmentsFilters,
    staleTime: Infinity,
  })
}
