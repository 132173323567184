import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useAccredDataState, useAccredMutation } from '../_accredDataState'
import { useAccountDataState } from '../_accountDataState'

import AccredForm from '../../shared/Forms/AccredForm'
import FormFooter from '../../shared/FormFooter'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Loader from '../../../components/Loader'
import { useRoute } from '../../../rules/find-route'
import { useQueryClient } from '@tanstack/react-query'

const AccredScreen = () => {
  const nav = useNavigate()
  const [serverError, setServerError] = useState('')
  const { data: investData } = useInvestDataState()
  const { data: accountData } = useAccountDataState()
  const { data: accredData } = useAccredDataState()
  const { isLoading, mutateAsync: mutateAccred } = useAccredMutation()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { findRoute } = useRoute()

  const {
    control,
    watch,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm()

  const noneQuestionId =
    accredData?.sections[accredData.sections.length - 1].questions[0].uuid

  const watchAll = watch()
  const isNothingSelected = Object.values(watchAll).every((value) => !value)

  useEffect(() => {
    if (isSubmitted && !isNothingSelected) {
      clearErrors('hiddenInput')
    }
  }, [isNothingSelected, clearErrors, isSubmitted])

  const onSubmit = handleSubmit(async (data) => {
    console.log(errors, data)
    if (isNothingSelected) {
      setError('hiddenInput', {
        type: 'hiddenInput',
        message: 'Please check at least one checkbox',
      })
      window.scrollTo(0, 0)
      return
    }
    if (
      data[noneQuestionId] === true &&
      investData?.opportunity?.io_type !== 'reit'
    ) {
      mutateAccred({
        question_list: [
          {
            investment: investData?.investment?.id,
            question: noneQuestionId,
            value: true,
          },
        ],
      })
        .then(() => {
          nav(`/invest/${investData.opportunity.id}/accred/failed`)
        })
        .catch((error) => {
          console.error(error.message)
          setServerError(error.message)
        })
      return
    }

    const postData = Object.keys(data).map((key) => {
      return {
        investment: investData?.investment?.id,
        question: key,
        value: data[key],
      }
    })
    mutateAccred({
      question_list: postData,
    })
      .then(() => {
        findRoute({
          ...investData,
          investment_state: {
            ...investData?.investment_state,
            self_accreditation: 'submitted',
          },
        }).then((route) => {
          queryClient.invalidateQueries(['amount'])
          queryClient.invalidateQueries(['invest'])
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  return (
    <div className="p-6 xl:p-0">
      {investData?.opportunity?.io_type === 'reit' ? (
        <Stepper currentSection={1} currentStep={1} totalStepCount={10} />
      ) : investData.investment?.status === 'pledge' ? (
        <Stepper
          currentSection={1}
          currentStep={1}
          totalStepCount={1}
          isPledge
        />
      ) : (
        <Stepper currentSection={1} currentStep={1} totalStepCount={9} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('accred:accreditation')}
          </h1>
          {accountData && accredData ? (
            <>
              {accountData.current_account?.subtype !== 'irrevocable' && (
                <p className="mb-6 text-16 text-content-black">
                  {t('accred:checkAll')}
                </p>
              )}
              <div className="mb-6">
                <AccredForm
                  control={control}
                  errors={errors}
                  accredData={accredData.sections}
                  isNoneChecked={watchAll[noneQuestionId]}
                  onSubmit={onSubmit}
                />
              </div>
              <FormFooter submitFunc={onSubmit} disabled={isLoading} />
              {serverError && (
                <ServerError
                  serverError={serverError}
                  id={investData?.investment?.id}
                />
              )}
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default AccredScreen
