import { Controller, useForm } from 'react-hook-form'
import { differenceInYears, isValid, parse } from 'date-fns'

import { usePatchProfile } from '../api/patch-profile'
import { BirthDateTypes } from '../api/schema'
import { dateIn, dateOut } from '@/utils/helpers'
import { toast } from '@/hooks/use-toast'

import { Button } from '@/components/ui/button'
import DateInput from '@/components/DateInput'
import ErrorAlert from '@/components/ErrorAlert'

export const UpdateBirthdate = ({
  dobData,
  callback,
}: {
  dobData: BirthDateTypes
  callback: (screen: string) => void
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<BirthDateTypes>({
    defaultValues: {
      birthdate: dobData?.birthdate ? dateIn(dobData?.birthdate) : '',
    },
  })

  const { mutateAsync: patchProfile, isLoading } = usePatchProfile()

  const onFormSubmit = handleSubmit((data: BirthDateTypes) => {
    if (!data.birthdate) return
    const cleanData = { birthdate: dateOut(data.birthdate) }
    patchProfile(cleanData).then(() => {
      toast({
        variant: 'success',
        description: 'Date of birth updated.',
        position: 'top',
      })
      callback('')
    })
  })

  return (
    <div className="mt-10">
      <div className="max-w-[312px]">
        <form
          onSubmit={onFormSubmit}
          autoComplete="on"
          className="w-full mb-10"
        >
          {Object.keys(errors).length > 0 && (
            <ErrorAlert text="Please fix the error(s) below:" />
          )}
          <div className="mb-10">
            <Controller
              name="birthdate"
              control={control}
              rules={{
                required: 'is required',
                validate: {
                  isValidDate: (value) => {
                    if (!value) return true
                    return (
                      isValid(parse(value, 'MM/dd/yyyy', new Date())) ||
                      'is not a valid date'
                    )
                  },
                  isEighteenOrOlder: (value) => {
                    if (!value) return true
                    const birthDate = parse(value, 'MM/dd/yyyy', new Date())
                    const today = new Date()
                    const age = differenceInYears(today, birthDate)
                    return age >= 18 || 'You must be at least 18 years old.'
                  },
                },
              }}
              render={({ field, fieldState }) => (
                <DateInput
                  label="Date of Birth"
                  placeholder="mm/dd/yyyy"
                  errorMessage={fieldState.error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <Button
              variant="RM"
              size="RM"
              disabled={isLoading || !isDirty}
              onClick={onFormSubmit}
            >
              Update
            </Button>
            <Button
              variant="RMSecondary"
              size="RMSecondary"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault()
                callback('')
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
