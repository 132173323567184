import { useEffect, useState } from 'react'
import { z } from 'zod'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select-filter'

import { messagesFilterSchema } from '../api/schema'
import { cn } from '@/utils/utils'

const filterSchema = z.object({
  name: z.string(),
  data: messagesFilterSchema.optional(),
  updateCallback: z.function().args(z.string(), z.string()).returns(z.void()),
  defaultValue: z.string().nullable(),
})

type FilterTypes = z.infer<typeof filterSchema>

export function Filter({
  name,
  data,
  defaultValue,
  updateCallback,
}: FilterTypes) {
  const [value, setValue] = useState('all')

  const handleFilterSelect = (newValue: string) => {
    setValue(newValue)
    updateCallback(name, newValue)
  }

  useEffect(() => {
    setValue(defaultValue || 'all')
  }, [defaultValue])

  return (
    data && (
      <Select value={value} onValueChange={handleFilterSelect}>
        <SelectTrigger
          className={cn(
            'w-fit h-[38px]',
            value !== 'all' && 'border-primary font-medium'
          )}
        >
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">
            <span className="capitalize">{`All ${name.split('_').join(' ')}`}</span>
          </SelectItem>
          {data.map((item) => (
            <SelectItem value={item.uuid} key={item.uuid}>
              {item.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  )
}
