import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FileUpload from '../../components/FileUpload'
import { getSignedS3Url, uploadToSignedS3 } from '../shared/_api'
import { useToast } from '@/hooks/use-toast'

interface FormType {
  file: string
}

const UploadScreen = () => {
  const nav = useNavigate()
  const { toast } = useToast()

  const { control } = useForm<FormType>({
    defaultValues: {
      file: '',
    },
  })

  return (
    <div>
      <h1 className="mb-6 text-2xl font-bold text-content-black">
        Upload Your Documents
      </h1>
      <p className="mb-6 text-16 text-content-black">
        Use the form below to securely upload your documents.
      </p>

      <div className="w-full md:max-w-[500px]">
        <div className="mb-6 text-16">Secure File Upload</div>
        <div className="mb-6">
          <Controller
            name="file"
            control={control}
            rules={{ required: 'File is required' }}
            render={({ field }) => (
              <FileUpload
                {...field}
                accept=".pdf, .doc, .docx, .jpg, .png, .heic, .jpeg"
                label="Upload Document"
                uploadFileFunction={async (file: File) => {
                  getSignedS3Url(
                    {
                      fileName: file.name,
                      type: 'other',
                    },
                    true
                  )
                    .then((signedS3Resp) => {
                      const { fields, url } = signedS3Resp.data
                      uploadToSignedS3(file, url, fields).then(async () => {
                        await new Promise((resolve) =>
                          setTimeout(resolve, 2500)
                        )
                        toast({
                          variant: 'success',
                          description: 'File securely uploaded.',
                          position: 'top',
                        })
                        nav('/dashboard')
                      })
                    })
                    .catch((error) => {
                      console.error('error uploading to signed s3', error)
                      toast({
                        variant: 'error',
                        description:
                          'Error uploading file.  Please check file type and try again.',
                        position: 'top',
                      })
                    })
                }}
              />
            )}
          />
          <p className="mt-4 text-center text-[13px]">
            Allowed file types: .pdf, .doc, .docx, .jpg, .jpeg, .png, .heic
          </p>
        </div>
      </div>
    </div>
  )
}

export default UploadScreen
