import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { getBrightspotUrl } from '@/routes/shared/_api'
import { InvestmentDataTypes } from '../api/schema'
import { Link } from 'react-router-dom'
import { WaitlistInvestmentMenu } from './edit-menu-waitlist'
import { FALLBACK_RM_IMAGE } from '@/utils/helpers'

const WaitlistButton = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => (
  <div className="flex flex-grow text-base gap-2 flex-col justify-start text-right">
    {!investment.investment?.funding_status && (
      <Link
        to={`${getBrightspotUrl()}investment-opportunity/${investment.io_drupal_id}`}
      >
        <Button className="w-full" variant="RM" size="RM">
          View Investment <i className="ml-2 fa-regular fa-angle-right" />
        </Button>
      </Link>
    )}
  </div>
)

export const WaitlistInvestmentCard = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => {
  return (
    <div className="w-full flex flex-wrap md:flex-nowrap md:gap-8 overflow-hidden md:h-[106px]">
      <div className="w-full h-[89px] md:h-[106px] md:w-[180px]">
        <img
          className="w-full h-full object-cover md:object-fill"
          src={investment.image ?? FALLBACK_RM_IMAGE}
        />
      </div>
      <div className="w-[85%] hidden md:flex gap-6 py-4">
        <div className="flex w-[50%] flex-col h-full gap-1 justify-center">
          <div>
            <p className="text-[17px] font-medium line-clamp-2">
              {investment.name}
            </p>
          </div>
          <div className="text-16">{investment.subhead}</div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        <div className="flex flex-col min-w-[170px] justify-start items-center">
          <Badge variant="active">On Waitlist</Badge>
        </div>
        <WaitlistButton investment={investment} />
        <div onClick={(e) => e.stopPropagation()} className="-mt-3">
          <WaitlistInvestmentMenu
            url={investment?.waitlist_details?.url ?? ''}
          />
        </div>
      </div>
      <div className="md:hidden w-full p-4 flex flex-col gap-4">
        <div className="w-full flex justify-between">
          <div className="flex flex-col justify-start items-center">
            <Badge variant="active">On Waitlist</Badge>
          </div>
          <WaitlistInvestmentMenu
            url={investment?.waitlist_details?.url ?? ''}
          />
        </div>
        <div className="w-full flex flex-col gap-[2px]">
          <div>
            <p className="text-[17px] font-medium line-clamp-2">
              {investment.name}
            </p>
          </div>
          <div className="text-16">{investment.subhead}</div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
      </div>
      <div className="md:hidden w-full p-4 flex flex-col justify-center border-t border-border-normal">
        <WaitlistButton investment={investment} />
      </div>
    </div>
  )
}
