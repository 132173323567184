import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { cn } from '@/utils/utils'
import { patchMessageRead } from '@/routes/shared/_api'
import { mapParams } from '../shared/map-params'
import { useMessagesFilters } from './api/get-messages-filters'
import { MessageType, MessagesDataTypes } from './api/schema'
import { useMessages } from './api/get-messages'
import { useUnreadDataQuery } from '../api/get-unread-messages'

import { DateFilter } from '../components/date-filter'
import { EmptyState } from '../components/empty-state'
import { Filter } from './components/filter'
import { Loader } from '@/components/Loader/index'
import { MessageItem } from './components/message-item'
import { Pagination } from './components/pagination'
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable'
import { ScrollArea } from '@/components/ui/scroll-area'
import { SelectedMessageArea } from './components/selected-message-area'
import { SkeletonItems } from './components/skeleton-item'
import { UnreadFilter } from './components/unread-filter'
import { hasSpecificFilters } from '../shared/has-specific-filters'

export const Messages = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFilters, setShowFilters] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState<
    MessageType | undefined
  >()
  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 10,
  })

  const queryClient = useQueryClient()
  const { data: unreadCount } = useUnreadDataQuery()
  const { data, isError, isFetching, isLoading } = useMessages()
  const { data: filtersData } = useMessagesFilters()

  useEffect(() => {
    const page = parseInt(searchParams.get('page') ?? '0') || 1
    setPagination((pagination) => {
      return { ...pagination, pageIndex: page - 1 }
    })
  }, [searchParams])

  const markMessageRead = () => {
    if (selectedMessage?.unread) {
      queryClient.setQueryData(
        [
          'messages',
          searchParams.get('page'),
          searchParams.get('investments'),
          searchParams.get('investment_accounts'),
          searchParams.get('date_from'),
          searchParams.get('date_to'),
          searchParams.get('unread'),
        ],
        (oldData: MessagesDataTypes | undefined) => {
          if (!oldData) return oldData
          const newData = { ...oldData }
          const newMessages = newData.messages.map((item) => {
            if (item.uuid === selectedMessage.uuid) {
              return { ...item, unread: false }
            } else return item
          })
          return { ...newData, messages: newMessages }
        }
      )
      queryClient.setQueryData(
        ['unreadMessages'],
        (oldData: number | undefined) => (oldData ? oldData - 1 : oldData)
      )
    }
  }

  const handleMessageClick = (newSelectedMessage: MessageType) => {
    markMessageRead()
    setSelectedMessage(newSelectedMessage)
    if (newSelectedMessage.unread) patchMessageRead(newSelectedMessage.uuid)
  }

  const handleFilterUpdate = (name: string, uuid: string) => {
    const paramName = mapParams(name)
    markMessageRead()
    setSelectedMessage(undefined)
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.delete('unread')
    uuid === 'all'
      ? nextSearchParams.delete(paramName)
      : nextSearchParams.set(paramName, uuid)
    setSearchParams(nextSearchParams)
    setPagination({ ...pagination, pageIndex: 0 })
  }

  const handleUnreadClick = () => {
    const nextSearchParams = new URLSearchParams({ unread: 'true' })
    markMessageRead()
    setSelectedMessage(undefined)
    setSearchParams(nextSearchParams)
    setPagination({ ...pagination, pageIndex: 0 })
  }

  const handleViewAllClick = () => {
    markMessageRead()
    setSelectedMessage(undefined)
    queryClient.invalidateQueries([
      'messages',
      '1',
      null,
      null,
      null,
      null,
      'true',
    ])
    const nextSearchParams = new URLSearchParams({ page: '1' })
    setSearchParams(nextSearchParams)
    queryClient.invalidateQueries([
      'messages',
      '1',
      null,
      null,
      null,
      null,
      null,
    ])
  }

  const handleDateFilterSelect = (date_from: string, date_to: string) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.delete('unread')
    date_from
      ? nextSearchParams.set('date_from', date_from)
      : nextSearchParams.delete('date_from')
    date_to
      ? nextSearchParams.set('date_to', date_to)
      : nextSearchParams.delete('date_to')
    setSearchParams(nextSearchParams)
  }

  const isSearchParamsEmpty =
    Array.from(searchParams.entries()).filter(
      ([key, value]) => !(key === 'page' && value === '1')
    ).length === 0

  return data ? (
    <>
      <div className="hidden md:block w-full h-full">
        <div className="w-full flex flex-col">
          <div className="w-full mb-8 flex justify-between items-center">
            <h1 className="text-27 md:text-31 font-bold self-start">
              Messages
            </h1>
            <div className="w-3/4 justify-end flex flex-wrap flex-row gap-2">
              {filtersData &&
                Object.entries(filtersData)
                  .sort((item) => {
                    return item[0] === 'investments' ? -1 : 1
                  })
                  .map((entry) => (
                    <Filter
                      key={entry[0]}
                      name={
                        entry[0] === 'investment_accounts'
                          ? 'ownership_types'
                          : entry[0]
                      }
                      data={Array.isArray(entry[1]) ? entry[1] : [entry[1]]}
                      updateCallback={handleFilterUpdate}
                      defaultValue={searchParams.get(
                        entry[0] === 'investment_accounts'
                          ? 'investment_account_uuid'
                          : mapParams(entry[0])
                      )}
                    />
                  ))}
              <DateFilter
                handleDateFilterSelect={handleDateFilterSelect}
                defaultValues={[
                  searchParams.get('date_from') ?? '',
                  searchParams.get('date_to') ?? '',
                ]}
              />
            </div>
          </div>
          {data.count > 0 || !isSearchParamsEmpty ? (
            <div>
              <div className="pb-10 min-h-[65vh]">
                <ResizablePanelGroup
                  direction="horizontal"
                  onLayout={(sizes: number[]) => {
                    document.cookie = `react-resizable-panels:messages-layout=${JSON.stringify(
                      sizes
                    )}`
                  }}
                  className="w-full h-full flex gap-4 items-stretch"
                >
                  <ResizablePanel
                    defaultSize={40}
                    minSize={30}
                    maxSize={50}
                    className="w-full flex flex-col h-screen overflow-hidden border rounded-md max-h-[800px]"
                  >
                    <div className="w-full flex justify-between text-content-black font-normal px-4 py-3 text-left text-base align-middle bg-[#F9FAFB] border-b">
                      <span>Inbox</span>
                      <UnreadFilter
                        handleUnreadClick={handleUnreadClick}
                        handleViewAllClick={handleViewAllClick}
                        count={data.count}
                        unreadCount={unreadCount}
                        unreadFilterOn={searchParams.get('unread') ?? 'false'}
                      />
                    </div>
                    <ScrollArea className="h-screen">
                      <div
                        className={cn(
                          isFetching && 'hidden',
                          'border-collapse'
                        )}
                      >
                        {data.messages.length > 0 ? (
                          data.messages.map((item) => (
                            <MessageItem
                              key={item.uuid}
                              selectedMessage={selectedMessage}
                              item={item}
                              handleMessageClick={handleMessageClick}
                            />
                          ))
                        ) : (
                          <div className="p-4 text-base">
                            No messages found -{' '}
                            <span
                              className="underline cursor-pointer"
                              onClick={() => {
                                const nextSearchParams = new URLSearchParams({
                                  page: '1',
                                })
                                setSelectedMessage(undefined)
                                setPagination({ ...pagination, pageIndex: 0 })
                                setSearchParams(nextSearchParams)
                              }}
                            >
                              reset all filters
                            </span>
                            .
                          </div>
                        )}
                      </div>
                      <div className={cn(!isFetching && 'hidden')}>
                        <SkeletonItems count={6} />
                      </div>
                    </ScrollArea>
                    <Pagination
                      pagination={pagination}
                      totalPages={data.pages}
                      totalCount={data.count}
                      changePage={(newPage) => {
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        )
                        newSearchParams.set('page', (newPage + 1).toString())
                        setSearchParams(newSearchParams)
                      }}
                    />
                  </ResizablePanel>
                  <ResizableHandle withHandle />
                  <ResizablePanel className="w-full flex flex-col h-screen overflow-hidden border rounded-md max-h-[800px]">
                    {selectedMessage ? (
                      <SelectedMessageArea selectedMessage={selectedMessage} />
                    ) : (
                      <div className="w-full h-full flex-col gap-4 flex justify-center items-center bg-bg-lighter text-base text-input">
                        <div>
                          <i className="fa-regular fa-envelope fa-3x opacity-20" />
                        </div>
                        <span>No message selected.</span>
                      </div>
                    )}
                  </ResizablePanel>
                </ResizablePanelGroup>
              </div>
            </div>
          ) : (
            <div className="h-[50vh] flex justify-center items-center">
              {isFetching || isLoading ? (
                <Loader />
              ) : (
                <EmptyState
                  iconClasses="fa-regular fa-envelope fa-3x text-primary opacity-20"
                  copy="You have no messages."
                  linkCopy="Browse Current Investments"
                  linkTo="https://www.realtymogul.com/investment-opportunities"
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="md:hidden w-full">
        <div className="w-full flex flex-col">
          {selectedMessage ? (
            <SelectedMessageArea
              selectedMessage={selectedMessage}
              unselectMessage={() => setSelectedMessage(undefined)}
            />
          ) : (
            <div className="p-6">
              <div className="w-full mb-8 flex justify-between items-center relative">
                <h1 className="text-27 md:text-31 font-bold self-start">
                  Messages
                </h1>
                <div className="w-3/4 justify-end flex flex-wrap flex-row gap-2">
                  <button
                    className={cn(
                      'w-10 h-10 text-[20px]',
                      hasSpecificFilters([
                        'io_uuid',
                        'investment_account_uuid',
                        'date_from',
                        'date_to',
                      ]) && 'text-primary'
                    )}
                    onClick={() => setShowFilters(true)}
                  >
                    <i className="fa-light fa-sliders" />
                  </button>
                  {showFilters && (
                    <div className="absolute top-1 -left-6 w-screen h-screen bg-white z-50 pb-40 rounded shadow">
                      <div className="p-4 flex justify-between items-center">
                        <div>Filter Messages</div>
                        <button className="w-10 h-10 text-[20px]">
                          <i
                            className="fa-regular fa-times"
                            onClick={() => setShowFilters(false)}
                          />
                        </button>
                      </div>
                      <div className="p-4 flex flex-col gap-4">
                        {filtersData &&
                          Object.entries(filtersData)
                            .sort((item) => {
                              return item[0] === 'investments' ? -1 : 1
                            })
                            .map((entry) => (
                              <Filter
                                key={entry[0]}
                                name={
                                  entry[0] === 'investment_accounts'
                                    ? 'ownership_types'
                                    : entry[0]
                                }
                                data={
                                  Array.isArray(entry[1])
                                    ? entry[1]
                                    : [entry[1]]
                                }
                                updateCallback={handleFilterUpdate}
                                defaultValue={searchParams.get(
                                  entry[0] === 'investment_accounts'
                                    ? 'investment_account_uuid'
                                    : mapParams(entry[0])
                                )}
                              />
                            ))}
                        <DateFilter
                          handleDateFilterSelect={handleDateFilterSelect}
                          defaultValues={[
                            searchParams.get('date_from') ?? '',
                            searchParams.get('date_to') ?? '',
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {data.count > 0 || !isSearchParamsEmpty ? (
                <div>
                  <div className="pb-10 min-h-[65vh]">
                    <div className="w-full flex flex-col h-screen overflow-hidden border rounded-md max-h-[800px]">
                      <div className="w-full flex justify-between text-content-black font-normal px-4 py-3 text-left text-base align-middle bg-[#F9FAFB] border-b">
                        <span>Inbox</span>
                        <UnreadFilter
                          handleUnreadClick={handleUnreadClick}
                          handleViewAllClick={handleViewAllClick}
                          count={data.count}
                          unreadCount={unreadCount}
                          unreadFilterOn={searchParams.get('unread') ?? 'false'}
                        />
                      </div>
                      <ScrollArea className="h-screen">
                        <div className={cn(isFetching && 'hidden')}>
                          {data.messages.length > 0 ? (
                            data.messages.map((item) => (
                              <MessageItem
                                key={item.uuid}
                                selectedMessage={selectedMessage}
                                item={item}
                                handleMessageClick={handleMessageClick}
                              />
                            ))
                          ) : (
                            <div className="p-4 text-base">
                              No messages found -{' '}
                              <span
                                className="underline cursor-pointer"
                                onClick={() => {
                                  const nextSearchParams = new URLSearchParams({
                                    page: '1',
                                  })
                                  setSelectedMessage(undefined)
                                  setPagination({ ...pagination, pageIndex: 0 })
                                  setSearchParams(nextSearchParams)
                                }}
                              >
                                reset all filters
                              </span>
                              .
                            </div>
                          )}
                        </div>
                        <div className={cn(!isFetching && 'hidden')}>
                          <SkeletonItems count={6} />
                        </div>
                      </ScrollArea>
                      <Pagination
                        pagination={pagination}
                        totalPages={data.pages}
                        totalCount={data.count}
                        changePage={(newPage) => {
                          const newSearchParams = new URLSearchParams(
                            searchParams
                          )
                          newSearchParams.set('page', (newPage + 1).toString())
                          setSearchParams(newSearchParams)
                          window.scrollTo(0, 0)
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {isFetching && data ? (
                    <Loader />
                  ) : (
                    <EmptyState
                      iconClasses="fa-regular fa-envelope fa-3x text-primary opacity-20"
                      copy="You have no messages."
                      linkCopy="Browse Current Investments"
                      linkTo="https://www.realtymogul.com/investment-opportunities"
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="flex justify-center items-center">
      {isLoading && (
        <div className="md:py-40">
          <Loader />
        </div>
      )}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your messages."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default Messages
