import { Link } from 'react-router-dom'
import { z } from 'zod'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

const ownershipTypeMenuSchema = z.object({
  uuid: z.string().uuid(),
  showExpandedMenu: z.boolean(),
})

type OwnershipMenuTypes = z.infer<typeof ownershipTypeMenuSchema>

const MenuButton = ({
  text,
  linkLocation = '/dashboard/profile',
  uuid,
}: {
  text: string
  linkLocation: string
  uuid: string
}) => {
  const newSearchParams = new URLSearchParams()
  newSearchParams.set('investment_account_uuid', uuid)
  return (
    <Link
      to={{ pathname: linkLocation, search: newSearchParams.toString() }}
      className="w-full text-left px-4 py-3 hover:bg-bg-light"
    >
      {text}
    </Link>
  )
}

export const OwnershipTypeMenu = ({
  uuid,
  showExpandedMenu = false,
}: OwnershipMenuTypes) => {
  return (
    <Popover>
      <PopoverTrigger>
        <i className="px-6 py-4 fa-regular fa-ellipsis-vertical"></i>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="end"
        sideOffset={-55}
        avoidCollisions={false}
        className="w-[187px] text-base px-0 py-2 rounded flex flex-col"
      >
        <Link
          className="w-full text-left px-4 py-3 hover:bg-bg-light"
          to={`/dashboard/ownership-types/edit/${uuid}`}
        >
          Edit
        </Link>
        {showExpandedMenu ? (
          <>
            <MenuButton
              text="Investments"
              linkLocation={`/dashboard/investments/active`}
              uuid={uuid}
            />
            <MenuButton
              text="Transactions"
              linkLocation={`/dashboard/transactions`}
              uuid={uuid}
            />
            <MenuButton
              text="Messages"
              linkLocation={`/dashboard/messages`}
              uuid={uuid}
            />
            <MenuButton
              text="Tax Documents"
              linkLocation={`/dashboard/taxes`}
              uuid={uuid}
            />
          </>
        ) : null}
      </PopoverContent>
    </Popover>
  )
}
