import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import { useToast } from '@/hooks/use-toast'
import { changePassword } from '../api/change-password'

import { Button } from '@/components/ui/button'
import ErrorAlert from '@/components/ErrorAlert'
import PasswordInput from '@/components/PasswordInput'

// Define the schema using zod
const schema = z
  .object({
    oldPassword: z.string().min(1, { message: 'Current password is required' }),
    newPassword: z.string().min(1, { message: 'New password is required' }),
    confirmNewPassword: z
      .string()
      .min(1, { message: 'Confirm new password is required' }),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "Passwords don't match",
    path: ['confirmNewPassword'],
  })

export const UpdatePassword = ({
  callback,
}: {
  callback: (screen: string) => void
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  })

  const { toast } = useToast()

  const onFormSubmit = handleSubmit((data) => {
    if (!data) return
    changePassword(data.oldPassword, data.newPassword)
      .then(() => {
        toast({
          variant: 'success',
          description: 'Password updated successfully',
          position: 'top',
        })
        callback('')
      })
      .catch((err) => {
        console.error(err.code)
        toast({
          variant: 'error',
          title: 'Error',
          description: err.message,
        })
      })
  })

  return (
    <div className="mt-10">
      <form onSubmit={onFormSubmit} autoComplete="on" className="w-full mb-10">
        {Object.keys(errors).length > 0 && (
          <ErrorAlert text="Please fix the error(s) below:" />
        )}
        <div className="mb-6">
          For security, please confirm your current password.
        </div>
        <div className="max-w-[400px]">
          <div className="mb-10">
            <Controller
              name="oldPassword"
              control={control}
              rules={{ required: 'is required' }}
              render={({ field, fieldState }) => (
                <PasswordInput
                  label="Current password"
                  errorMessage={fieldState.error?.message}
                  autoComplete="current-password"
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-6">Create a new password.</div>
          <div className="mb-6">
            <Controller
              name="newPassword"
              control={control}
              rules={{ required: 'is required' }}
              render={({ field, fieldState }) => (
                <PasswordInput
                  label="New Password"
                  errorMessage={fieldState.error?.message}
                  autoComplete="new-password"
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-6">
            <Controller
              name="confirmNewPassword"
              control={control}
              rules={{ required: 'is required' }}
              render={({ field, fieldState }) => (
                <PasswordInput
                  label="Confirm New Password"
                  errorMessage={fieldState.error?.message}
                  autoComplete="new-password"
                  helpText="Must be 8 characters or more with an uppercase and lowercase character, a number, and a symbol."
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-6">
          <Button variant="RM" size="RM" onClick={onFormSubmit}>
            Update
          </Button>
          <Button
            variant="RMSecondary"
            size="RMSecondary"
            onClick={(e) => {
              e.preventDefault()
              callback('')
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}
