import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import Zendesk, { ZendeskAPI } from 'react-zendesk'

import { cn } from '@/utils/utils'
import { useAuthDataState } from '@/routes/shared/_authDataState'
import { useFeatureFlags } from '@/rules/get-feature-flags'
import { useUnreadDataQuery } from './api/get-unread-messages'

import ContactUs from './components/contact-us'
import { FallbackError } from './error'
import Footer from '../shared/Footer'
import Login from '../shared/Login'
import { Header } from './components/header'
import { Nav } from './components/nav'
import { TooltipProvider } from '@/components/ui/tooltip'

interface DashboardProps {
  defaultLayout?: number[] | undefined
  defaultCollapsed?: boolean
}

const Dashboard = ({ defaultCollapsed = false }: DashboardProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
  const { isLoading: userIsLoading, data: authData } = useAuthDataState()
  const { data: featureFlagsData } = useFeatureFlags()

  const location = useLocation()

  const { data: unreadData } = useUnreadDataQuery()

  useEffect(() => {
    scrollTo(0, 0)
  }, [location])

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  if (userIsLoading) return null
  if (!authData) return <Login />

  return (
    <ErrorBoundary FallbackComponent={FallbackError}>
      {featureFlagsData && (
        <TooltipProvider delayDuration={0}>
          <div className="h-full w-full flex-col items-center overflow-hidden">
            <Header />
            <div className="w-full box-border md:pt-6 pb-10 md:px-8 overflow-hidden xl:max-w-[1800px] flex flex-col mx-auto md:flex-row md:gap-6">
              <div
                className={cn(
                  'md:h-[70vh] md:pr-2 transition-all duration-300 ease-in-out',
                  isCollapsed && 'min-w-[75px] items-start justify-start'
                )}
              >
                <Nav
                  unreadCount={unreadData}
                  isCollapsed={isCollapsed}
                  toggleCollapse={toggleCollapse}
                  links={[
                    {
                      title: 'Investments',
                      icon: 'chart-line',
                      location: '/dashboard/investments/active',
                      active: location.pathname.includes('investments'),
                    },
                    {
                      title: 'Messages',
                      icon: 'envelope',
                      location: '/dashboard/messages',
                      active: location.pathname === '/dashboard/messages',
                    },
                    {
                      title: 'Tax Center',
                      icon: 'files',
                      location: '/dashboard/taxes',
                      active: location.pathname.includes('taxes'),
                    },
                    {
                      title: 'Transactions',
                      icon: 'rectangle-list',
                      location: '/dashboard/transactions',
                      active: location.pathname.includes('transactions'),
                    },
                  ]}
                  links2={[
                    {
                      title: 'Bank Accounts',
                      icon: 'bank',
                      location: '/dashboard/bank-accounts',
                      active: location.pathname.includes('bank-accounts'),
                    },
                    {
                      title: 'Ownership Types',
                      icon: 'user-group',
                      location: '/dashboard/ownership-types',
                      active: location.pathname.includes('ownership-types'),
                    },
                    {
                      title: 'Profile',
                      icon: 'user',
                      location: '/dashboard/profile',
                      active: location.pathname.includes('profile'),
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col w-full md:px-[20px]">
                <div className="flex-grow md:mb-10">
                  <Outlet />
                </div>
                <ContactUs
                  links={[
                    {
                      label: 'Questions? Send a message',
                      to: () => ZendeskAPI('webWidget', 'open'),
                    },
                    {
                      label: 'Speak with Investor Relations',
                      to: 'https://calendly.com/realtymogul-investor-relations/realty-mogul-representative-call',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="w-full bg-black">
              <Footer />
            </div>
          </div>
          <Zendesk defer zendeskKey="f0fb7231-d555-4b6c-b382-06ff3c2efb4e" />
        </TooltipProvider>
      )}
    </ErrorBoundary>
  )
}

export default Dashboard
