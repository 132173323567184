import { Auth, CookieStorage } from '@aws-amplify/auth'
declare global {
  interface Window {
    Cypress?: Cypress.Cypress
  }
}

export const cookieStorage = new CookieStorage({
  domain: (() => {
    if (window.location.origin.includes('rtml.localhost')) {
      return '.rtml.localhost'
    }
    if (window.location.origin.includes('localhost')) {
      return '.localhost'
    }
    return '.realtymogul.com'
  })(),
  path: '/',
  expires: 1,
  sameSite: 'strict',
  secure: !window.location.origin.includes('localhost'),
})

export const awsConfig = {
  Auth: {
    region: 'us-west-2',
    userPoolId:
      window.location.origin === 'https://my.realtymogul.com'
        ? `us-west-2_Z1cMSCXWl`
        : `us-west-2_iOmHSc6io`,
    userPoolWebClientId:
      window.location.origin === 'https://my.realtymogul.com'
        ? `715inci7q6uq2kpv3fdv9opknj`
        : `2iivupb66i0h6ng72dt0im61gp`,
    storage: cookieStorage,
  },
}

export const getUser = () =>
  Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => user)

export const cognitoLogout = () => {
  Auth.signOut()
}
