import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { updateAccount } from '@/routes/shared/_api'
import { useRoute } from '@/rules/find-route'
import { useInvestDataState } from '../_investDataState'
import {
  useAccountDataState,
  useCustodiansDataState,
} from '../_accountDataState'
import { RetirementAccountTypes } from '../../shared/_types'

import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'

interface AccountFormTypes {
  type: string
  id: string
}

const RetirementScreen = () => {
  const nav = useNavigate()
  const queryClient = useQueryClient()

  const { data: investData } = useInvestDataState()
  const { data: accountData } = useAccountDataState()
  const { data: custodiansData } = useCustodiansDataState()
  const { findRoute } = useRoute()

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<AccountFormTypes>({
    defaultValues: {
      type: investData?.account?.type || '',
      id: investData?.account?.id || '',
    },
  })

  const backLocation = `/invest/${investData?.investment?.id}/account`

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    if (data.type === 'newAccount') {
      nav(`/invest/${investData.opportunity.id}/account/retirement/selection`)
    } else {
      const matchedRetirementAccount = accountData.ia_options?.find(
        (account: RetirementAccountTypes) => {
          return account.uuid === data.type
        }
      )
      updateAccount(investData?.investment?.id, matchedRetirementAccount.uuid, {
        type: 'foe_sdira',
      })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['account'],
          })
          queryClient.invalidateQueries({
            queryKey: ['invest'],
          })
          findRoute({
            ...investData,
            investment_state: {
              ...investData?.investment_state,
              investment_account: 'submitted',
            },
          }).then((route) => {
            nav(`/invest/${investData.opportunity.id}/${route.type}`)
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  })

  return (
    custodiansData && (
      <div className="p-6 xl:p-0">
        {investData.opportunity.io_type === 'reit' ? (
          <Stepper currentSection={0} currentStep={3} totalStepCount={3} />
        ) : (
          <Stepper
            currentSection={0}
            currentStep={3}
            totalStepCount={4}
            isPledge={
              investData.opportunity.status.toLowerCase() ===
              'open for pledging'
            }
          />
        )}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-2 text-2xl font-bold text-content-black">
              Investor Account
            </h1>
            <p className="mb-6 text-base italic text-content-light">
              Retirement
            </p>
            <p className="mb-6 text-16 text-content-black">
              Select your retirement account below, or create a new one.
            </p>
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <p className="mb-2 text-16 text-content-black">
                  Retirement Account
                </p>
                <Controller
                  name="type"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div
                      className={`flex flex-col gap-px ${
                        fieldState.error && 'border border-alert'
                      }`}
                    >
                      {accountData.ia_options &&
                        accountData.ia_options.map(
                          (account: RetirementAccountTypes, index: number) =>
                            account.type === 'foe_sdira' && (
                              <Radio
                                key={index}
                                {...field}
                                value={account.uuid || `${index}`}
                                label={`${account.custodian_other ? account.custodian_other : account.custodian?.name} - ${account.account_name}`}
                              />
                            )
                        )}
                      <Radio
                        {...field}
                        value="newAccount"
                        label="New Retirement Account"
                        checked={field.value === 'newAccount'}
                      />
                    </div>
                  )}
                />
              </div>
              <FormFooter
                submitFunc={onSubmit}
                backLocation={backLocation}
                disabled={!isDirty || !isValid || isSubmitting}
              />
            </form>
          </div>
          <div className="hidden xl:block">
            <Sidebar />
          </div>
        </div>
      </div>
    )
  )
}

export default RetirementScreen
