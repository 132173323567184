import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { useSearchParams } from 'react-router-dom'
import { taxesDataSchema } from './schema'
import { datadogLogs } from '@datadog/browser-logs'
import { z } from 'zod'

export const getTaxDocuments = async (params: URLSearchParams) => {
  const response = await api.get('/dashboard/tax-documents', {
    params,
  })
  const parsedData = taxesDataSchema.safeParse(response)

  if (!parsedData.success) {
    datadogLogs.logger.error(
      'Zod schema Error - get-tax-documents',
      parsedData.error
    )
    const unsafeUnparsedData = response as unknown as z.infer<
      typeof taxesDataSchema
    >
    return unsafeUnparsedData
  } else {
    return parsedData.data
  }
}

export const useTaxDocuments = () => {
  const [searchParams, _setSearchParams] = useSearchParams()
  return useQuery({
    queryKey: [
      'tax-documents',
      searchParams.get('page'),
      searchParams.get('io_uuid'),
      searchParams.get('investment_account_uuid'),
      searchParams.get('tax_year'),
      searchParams.get('document_type'),
    ],
    queryFn: () => getTaxDocuments(searchParams),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
