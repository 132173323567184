import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { getTransactions, useTransactions } from './api/get-transactions'
import { mapParams } from '../shared/map-params'
import { useTransactionFilters } from './api/get-transaction-filters'
import { hasSpecificFilters } from '../shared/has-specific-filters'

import { columns } from './components/columns'
import { EmptyState } from '../components/empty-state'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

import Loader from '@/components/Loader/index'
import { Filter } from './components/filter'
import { Pagination } from './components/pagination'
import { DateFilter } from '../components/date-filter'
import { cn } from '@/utils/utils'
import { dateIn, formatCurrency } from '@/utils/helpers'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Skeleton } from '@/components/ui/skeleton'

const Transactions = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFilters, setShowFilters] = useState(false)
  const { data, isLoading, isFetching, isError } = useTransactions()
  const { data: filtersData } = useTransactionFilters()
  const { pathname, search } = useLocation()

  const [columnVisibility] = useState<VisibilityState>({
    shares: false,
    purchasePrice: false,
    investmentLink: false,
    investment_account_type: false,
    issuer_name: false,
    io_uuid: false,
  })

  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 10,
  })
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'transaction_date', desc: true },
  ])

  const queryClient = useQueryClient()

  useEffect(() => {
    const page = parseInt(searchParams.get('page') ?? '0') || 1
    setPagination((pagination) => {
      return { ...pagination, pageIndex: page - 1 }
    })
  }, [searchParams])

  const table = useReactTable({
    data: data?.transactions ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    rowCount: data?.count ?? 0,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const handleFilterUpdate = (name: string, uuid: string) => {
    const paramName = mapParams(name)
    const nextSearchParams = new URLSearchParams(searchParams)
    uuid === 'all'
      ? nextSearchParams.delete(paramName)
      : nextSearchParams.set(
          paramName,
          paramName === 'transaction_types' ? mapParams(uuid) : uuid
        )
    setSearchParams(nextSearchParams)
    setPagination({ ...pagination, pageIndex: 0 })
  }

  const prefetchNextPage = (newPage: number) => {
    queryClient.prefetchQuery({
      queryKey: [
        'tax-documents',
        (Number(searchParams.get('page') || 0) + 2).toString(),
        searchParams.get('io_uuid'),
        searchParams.get('investment_account_uuid'),
        searchParams.get('tax_year'),
        searchParams.get('document_type'),
      ],
      queryFn: () => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('page', (newPage + 2).toString())
        return getTransactions(newSearchParams)
      },
    })
  }

  const handleDateFilterSelect = (date_from: string, date_to: string) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.delete('unread')
    date_from
      ? nextSearchParams.set('date_from', date_from)
      : nextSearchParams.delete('date_from')
    date_to
      ? nextSearchParams.set('date_to', date_to)
      : nextSearchParams.delete('date_to')
    setSearchParams(nextSearchParams)
  }

  return data ? (
    <div className="w-full h-full">
      <div className="w-full md:mb-10 flex justify-between items-center p-6 md:p-0 relative">
        <h1 className="text-27 md:text-31 font-bold self-start">
          Transactions
        </h1>
        <Dialog open={showFilters} onOpenChange={setShowFilters}>
          <DialogTrigger asChild>
            <div className="w-3/4 justify-end flex flex-wrap flex-row gap-2 md:hidden">
              <button
                className={cn(
                  'w-10 h-10 text-[20px] md:hidden',
                  hasSpecificFilters([
                    'transaction_type',
                    'io_uuid',
                    'investment_account_uuid',
                    'date_from',
                    'date_to',
                  ]) && 'text-primary'
                )}
                onClick={() => setShowFilters(true)}
              >
                <i className="fa-light fa-sliders" />
              </button>
            </div>
          </DialogTrigger>
          <DialogContent
            aria-describedby={undefined}
            className="top-72 w-[90%] rounded"
          >
            <div className="flex flex-col gap-4 overflow-hidden">
              <DialogTitle className="text-content-light font-normal">
                Filter Transactions
              </DialogTitle>
              {filtersData &&
                Object.entries(filtersData)
                  .sort((item) => {
                    return item[0] === 'transaction_types'
                      ? -1
                      : item[0] === 'io_filters'
                        ? -1
                        : 1
                  })
                  .map((entry) => {
                    return (
                      <Filter
                        key={entry[0]}
                        name={
                          entry[0] === 'investment_accounts'
                            ? 'ownership_types'
                            : entry[0]
                        }
                        data={Array.isArray(entry[1]) ? entry[1] : [entry[1]]}
                        updateCallback={handleFilterUpdate}
                        defaultValue={searchParams.get(
                          entry[0] === 'investment_accounts'
                            ? 'investment_account_uuid'
                            : mapParams(entry[0])
                        )}
                      />
                    )
                  })}
              <DateFilter
                handleDateFilterSelect={handleDateFilterSelect}
                defaultValues={[
                  searchParams.get('date_from') ?? '',
                  searchParams.get('date_to') ?? '',
                ]}
              />
            </div>
          </DialogContent>
        </Dialog>
        <div className="hidden md:flex">
          <div className="flex flex-row gap-2 flex-wrap justify-end">
            {filtersData &&
              Object.entries(filtersData)
                .sort((item) => {
                  return item[0] === 'transaction_types'
                    ? -1
                    : item[0] === 'io_filters'
                      ? -1
                      : 1
                })
                .map((entry) => {
                  return (
                    <Filter
                      key={entry[0]}
                      name={
                        entry[0] === 'investment_accounts'
                          ? 'ownership_types'
                          : entry[0]
                      }
                      data={Array.isArray(entry[1]) ? entry[1] : [entry[1]]}
                      updateCallback={handleFilterUpdate}
                      defaultValue={searchParams.get(
                        entry[0] === 'investment_accounts'
                          ? 'investment_account_uuid'
                          : mapParams(entry[0])
                      )}
                    />
                  )
                })}
            <DateFilter
              handleDateFilterSelect={handleDateFilterSelect}
              defaultValues={[
                searchParams.get('date_from') ?? '',
                searchParams.get('date_to') ?? '',
              ]}
            />
          </div>
        </div>
      </div>
      {data.count > 0 && (
        <div className="px-6 md:px-0 mb-6">
          {data.count.toLocaleString()} transactions
        </div>
      )}

      {data.transactions.length ||
      hasSpecificFilters([
        'transaction_types',
        'io_uuid',
        'investment_account_uuid',
        'date_from',
        'date_to',
      ]) ? (
        <div className="mb-10">
          <div className="hidden md:block">
            <div className="rounded-t-md border overflow-hidden">
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    <>
                      {!isFetching ? (
                        table.getRowModel().rows.map((row) => (
                          <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && 'selected'}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <>
                          {[...Array(pagination.pageSize)].map(() => (
                            <TableRow key={Math.random()}>
                              <TableCell className="h-[75px] text-center">
                                <Skeleton className="w-full h-3" />
                              </TableCell>
                              <TableCell className="h-[75px] text-center">
                                <Skeleton className="w-full h-3" />
                              </TableCell>
                              <TableCell className="h-[75px] text-center">
                                <Skeleton className="w-full h-3" />
                              </TableCell>
                              <TableCell className="h-[75px] text-center">
                                <Skeleton className="w-full h-3" />
                              </TableCell>
                              <TableCell className="h-[75px] text-center">
                                <Skeleton className="w-full h-3" />
                              </TableCell>
                              <TableCell className="h-[75px] text-center">
                                <Skeleton className="w-full h-3" />
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                        {hasSpecificFilters([
                          'transaction_type',
                          'io_uuid',
                          'investment_account_uuid',
                          'date_from',
                          'date_to',
                        ]) && (
                          <span>
                            {' '}
                            -{' '}
                            <span
                              className="underline cursor-pointer"
                              onClick={() => {
                                const nextSearchParams = new URLSearchParams({
                                  page: '1',
                                })
                                setPagination({
                                  ...pagination,
                                  pageIndex: 0,
                                  pageSize: 5,
                                })
                                setSearchParams(nextSearchParams)
                              }}
                            >
                              reset all filters
                            </span>
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <Pagination
              pagination={pagination}
              totalPages={data.pages}
              totalCount={data.count}
              changePage={(newPage) => {
                const newSearchParams = new URLSearchParams(searchParams)
                newSearchParams.set('page', (newPage + 1).toString())
                setSearchParams(newSearchParams)
                if (
                  newPage + 2 < data.pages &&
                  newPage > pagination.pageIndex
                ) {
                  prefetchNextPage(newPage)
                }
              }}
            />
          </div>
          <div className="md:hidden px-6">
            {data.transactions.length > 0 ? (
              <div>
                {!isFetching ? (
                  <>
                    {data.transactions.map((transaction) => (
                      <div
                        className="p-3 border border-t-0 first-of-type:border-t border-border-normal first-of-type:rounded-t text-base"
                        key={transaction.uuid}
                      >
                        <div className="mb-1 font-medium">
                          {dateIn(transaction?.transaction_date ?? '')}
                        </div>
                        <div className="font-medium mb-2">
                          {mapParams(transaction.transaction_type)}
                        </div>
                        <Link
                          to={`/dashboard/investments/details/${transaction.io_uuid}`}
                          className="block underline mb-2"
                          state={{ previousLocation: pathname + search }}
                        >
                          {transaction.io_name}
                        </Link>
                        <div className="mb-2">
                          {Number(transaction.amount)?.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          {transaction.shares &&
                            transaction.price_per_share && (
                              <span className="text-content-light">
                                {' '}
                                {transaction.shares} shares @
                                {formatCurrency(transaction.price_per_share)}{' '}
                                per share
                              </span>
                            )}
                        </div>
                        <div className="text-sm text-content-light">
                          {mapParams(transaction.investment_account_type ?? '')}{' '}
                          - {transaction?.investment_account_name}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {[...Array(pagination.pageSize)].map(() => (
                      <div
                        className="p-3 flex flex-col gap-4 border border-t-0 first-of-type:border-t border-border-normal first-of-type:rounded-t text-base"
                        key={Math.random()}
                      >
                        <Skeleton className="w-[100px] h-4" />
                        <Skeleton className="w-[200px] h-4" />
                        <Skeleton className="w-[250px] h-4" />
                        <Skeleton className="w-[70px] h-4" />
                        <Skeleton className="w-[275px] h-4" />
                      </div>
                    ))}
                  </>
                )}
              </div>
            ) : (
              <div className="p-3 border border-t-0 first-of-type:border-t border-border-normal first-of-type:rounded-t ">
                No results.
                {hasSpecificFilters([
                  'transaction_type',
                  'io_uuid',
                  'investment_account_uuid',
                  'date_from',
                  'date_to',
                ]) && (
                  <span>
                    {' '}
                    -{' '}
                    <span
                      className="underline"
                      onClick={() => {
                        const nextSearchParams = new URLSearchParams({
                          page: '1',
                        })
                        setPagination({
                          ...pagination,
                          pageIndex: 0,
                          pageSize: 5,
                        })
                        setSearchParams(nextSearchParams)
                      }}
                    >
                      reset all filters
                    </span>
                  </span>
                )}
              </div>
            )}
            <Pagination
              pagination={pagination}
              totalPages={data.pages}
              totalCount={data.count}
              changePage={(newPage) => {
                const newSearchParams = new URLSearchParams(searchParams)
                newSearchParams.set('page', (newPage + 1).toString())
                setSearchParams(newSearchParams)
                if (
                  newPage + 2 < data.pages &&
                  newPage > pagination.pageIndex
                ) {
                  prefetchNextPage(newPage)
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div className="md:py-40">
          <EmptyState
            iconClasses="fa-regular fa-rectangle-list fa-3x text-primary opacity-20"
            copy="You have no transactions."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  ) : (
    <div className="flex justify-center items-center w-full h-[35vh]">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your transactions."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default Transactions
