import { Engine } from 'json-rules-engine'
import InvestflowRules from './investflow.json'

import { useFeatureFlags } from './get-feature-flags'

const processEngine = (inputs: any, decisions: any) => {
  const engine = new Engine(decisions, { allowUndefinedFacts: true })
  return engine.run(inputs).then((results) => {
    console.debug('Results: ', results)
    const steps = results.events.map((event) => event.type)
    console.debug('Steps: ', steps)
    return results.events?.[0]
  })
}

// Transform data into facts
const getFacts = async (data: any, featureFlags: any) => {
  return {
    has_investment: data?.investment ? true : false,
    investment_status: data?.investment?.status,
    member_email_verified: data?.member?.member_email_verified || true, // todo: Need to map this from the API.
    investment_opportunity_type: data?.opportunity?.io_type,
    is_funded: data?.opportunity?.is_funded,
    is_fully_pledged: data?.opportunity?.fully_pledged,
    personal_info_status: data?.investment_state?.personal_info,
    investment_account_type: data?.investment?.investment_account_type,
    investment_account_status: data?.investment_state?.investment_account,
    amount: data?.investment?.amount,
    amount_status: data?.investment_state?.amount,
    has_1031_record: data?.investment?.ten_31_record?.investment_uuid
      ? true
      : false,
    record_1031_status: !data?.opportunity?.eligible_1031
      ? 'n/a'
      : data?.investment_state?.step_1031,
    self_accred_status: data?.investment_state?.self_accreditation,
    qualification_status: data?.investment_state?.qualification,
    concentration_status: data?.investment_state?.concentration,
    employment_status: data?.investment_state?.employment,
    identity_status: data?.investment_state?.identity_state,
    agreement_type: data?.investment?.agreement_type,
    agreement_status: data?.investment_state?.agreement,
    funding_status: data?.investment_state?.funding,
    has_distribution_account: data?.investment?.distribution_account_uuid
      ? true
      : false,
    eav_status: data?.investment_state?.eav,
    review_status: data?.investment_state?.review,
    is_reserved: data?.investment?.has_reservation,
    completed_step: true,
    is_wire_only: data?.opportunity?.wire_only,
    account_type: data?.investment?.investment_account_type,

    // Feature flags.
    ...featureFlags,
  }
}

export const useRoute = () => {
  const { data: featureFlagData } = useFeatureFlags()

  const findRoute = async (data: any, featureFlagOverrides?: any) => {
    console.log('data: ', data)
    console.log('featureflags: ', {
      ...featureFlagData,
      ...featureFlagOverrides,
    })
    const facts = await getFacts(data, {
      ...featureFlagData,
      ...featureFlagOverrides,
    })
    console.log('Facts: ', facts)
    const route_event = await processEngine(
      facts,
      InvestflowRules.decisions.map((decision, index) => {
        return {
          ...decision,
          priority: 999 - index, // make sure decisions run in the order of the JSON
        }
      })
    )
    if (!route_event) {
      console.error('Route event error: ', data, facts)
    } else {
      console.log('Route Event: ', route_event)
    }
    return route_event
  }

  return { findRoute }
}
