import { useEffect, useState } from 'react'
import { z } from 'zod'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select-filter'
import { cn } from '@/utils/utils'

const filterSchema = z.object({
  name: z.string().nullish(),
  data: z
    .array(
      z
        .object({
          name: z.string().nullish(),
          uuid: z.string().nullish(),
        })
        .optional()
    )
    .optional(),
  updateCallback: z.function().args(z.string()).returns(z.void()),
  defaultValue: z.string().optional(),
})

type FilterTypes = z.infer<typeof filterSchema>

export function Filter({
  name,
  data,
  updateCallback,
  defaultValue,
}: FilterTypes) {
  const [value, setValue] = useState(data?.[0]?.uuid ?? '')

  useEffect(() => setValue(defaultValue ?? 'all'), [defaultValue])

  const handleFilterSelect = (newValue: string) => {
    setValue(newValue)
    updateCallback(newValue)
  }

  return (
    data && (
      <Select value={value} onValueChange={handleFilterSelect}>
        <SelectTrigger
          className={cn(
            'w-fit h-[38px]',
            value !== 'all' && 'border-primary font-medium'
          )}
        >
          <SelectValue />
        </SelectTrigger>
        <SelectContent
          ref={(ref) => {
            if (!ref) return
            ref.ontouchstart = (e) => {
              e.preventDefault()
            }
          }}
        >
          {name && (
            <SelectItem value="all">
              <span className="capitalize">{`Select ${name.split('_').join(' ')}`}</span>
            </SelectItem>
          )}
          {data.map((item) => (
            <SelectItem value={item?.uuid ?? 'all'} key={item?.uuid}>
              {item?.name || 'N/A'}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  )
}
