import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import { api } from '@/routes/shared/_api'
import { cn } from '@/utils/utils'
import { InvestmentDataTypes } from '../api/schema'
import { useShareRepurchase } from '../api/get-share-repurchase'
import { usePostShareRepurchase } from '../api/post-share-repurchase'

import { useToast } from '@/hooks/use-toast'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { useQueryClient } from '@tanstack/react-query'
import { InfoTooltip } from '../../components/info-tooltip'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Filter } from './filter'
import Radio from '@/components/Radio'

export const DetailsSettings = ({ data }: { data: InvestmentDataTypes }) => {
  const nav = useNavigate()
  const [showDialog, setShowDialog] = useState(false)
  const [showPending, setShowPending] = useState(false)
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const [dripEnabled, setDripEnabled] = useState(
    data?.drip_details?.status === 'ENABLED'
  )
  const [isSubmitting, setSubmitting] = useState(false)

  const { data: shareRepurchaseData } = useShareRepurchase({
    io_uuid: data.io_uuid,
  })

  const {
    mutateAsync: postShareRepurchaseMutate,
    isLoading: isPostShareRepurchaseMutateLoading,
  } = usePostShareRepurchase({
    io_uuid: data.io_uuid,
  })

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      uuid: 'all',
      share_amount: 'twenty-five',
      other_amount: '',
    },
  })

  const onSubmit = handleSubmit((submitData) => {
    console.log(submitData)
    let sharePercent = 0
    if (submitData.share_amount === 'twenty-five') {
      sharePercent = 0.25
    } else if (submitData.share_amount === 'one-hundred') {
      sharePercent = 1
    } else {
      sharePercent = parseFloat(submitData.other_amount) / 100
    }
    postShareRepurchaseMutate({
      investment_account_uuid: submitData.uuid,
      io_uuid: data.io_uuid,
      percent: sharePercent,
    }).then(() => {
      setShowPending(true)
    })
  })

  const watchOther = watch('share_amount') === 'other'
  const watchUuid = watch('uuid')

  const hasEligibleShares =
    watchUuid !== 'all' &&
    shareRepurchaseData?.eligible_investments.find(
      (item) => item.investment_account_uuid === watchUuid
    )?.eligible_date_in_past &&
    !shareRepurchaseData?.eligible_investments.find(
      (item) => item.investment_account_uuid === watchUuid
    )?.has_active_share_repurchase_request

  const handleFilterUpdate = (_name: string, uuid: string) => {
    setValue('uuid', uuid)
  }

  const handleCheckedChange = async (checked: boolean) => {
    setDripEnabled(checked)
    if (data.drip_details?.turn_on_url && data.drip_details?.turn_off_url) {
      setSubmitting(true)
      api
        .get(
          checked
            ? data.drip_details?.turn_on_url
            : data.drip_details?.turn_off_url
        )
        .then(() => {
          toast({
            variant: 'success',
            description: `DRIP turned ${checked ? 'on' : 'off'}`,
            position: 'top',
          })
          queryClient.invalidateQueries(['investment-details', data.io_uuid])
          setSubmitting(false)
        })
    }
  }

  return data.is_reit ? (
    <div className="flex flex-col [&>div]:-mt-px">
      <div
        className={cn(
          'w-full px-6 py-4 min-h-[78px] flex justify-between items-center group border border-border-normal rounded-t'
        )}
      >
        <div className="flex flex-col text-base gap-2 pr-4">
          <div className="text-sm">DRIP</div>
          <div className="text-base font-medium">
            Reinvest Distributions for your REIT
          </div>
          <div className="text-sm text-content-light">
            {data.drip_details?.status !== 'LOCKED' && (
              <div>
                <InfoTooltip
                  target={<span className="underline">Learn More</span>}
                >
                  <>
                    <p>
                      All funds sent to investors during the year will initially
                      be classified as distributions. After year end, earnings
                      and profits calculations will be performed to determine
                      the break out between income and return of capital and
                      will be reported through a Form 1099.
                    </p>
                    <p className="mt-2">
                      By selecting to participate in RealtyMogul.com's
                      Distribution Reinvestment Plan (DRIP), you are agreeing to
                      monitor and notify RealtyMogul.com in advance of the event
                      that your continued participation in the DRIP would cause
                      you to exceed applicable qualified purchaser limits as set
                      forth in Regulation A of the Securities Act, and that you
                      will terminate your participation in the DRIP.
                    </p>
                  </>
                </InfoTooltip>{' '}
                about RealtyMogul's distribution reinvestment plan.
              </div>
            )}
            {data.drip_details?.status === 'LOCKED' &&
              (data.drip_details.status_reason === 'DISABLED_BY_OPERATIONS' ? (
                <span>
                  DRIP has been disabled for your account. Please contact
                  Operations at{' '}
                  <a
                    className="underline"
                    href="mailto:investor-help@realtymogul.com"
                  >
                    investor-help@realtymogul.com
                  </a>{' '}
                  to re-enable.
                </span>
              ) : (
                <span>
                  You currently have an active share repurchase request. Please
                  contact Operations at{' '}
                  <a
                    className="underline"
                    href="mailto:investor-help@realtymogul.com"
                  >
                    investor-help@realtymogul.com
                  </a>{' '}
                  to change your DRIP settings.
                </span>
              ))}
          </div>
        </div>
        <div className="inline-flex gap-3">
          <Switch
            onCheckedChange={handleCheckedChange}
            defaultChecked={dripEnabled}
            disabled={isSubmitting || data.drip_details?.status === 'LOCKED'}
          />
          <div
            className={cn(
              'text-base',
              data.drip_details?.status === 'LOCKED' && 'text-[#BFBFBF]'
            )}
          >
            {dripEnabled ? 'On' : 'Off'}
          </div>
        </div>
      </div>
      <div
        className={cn(
          'w-full px-6 py-4 min-h-[78px] flex flex-col gap-4 md:flex-row justify-between md:items-center group border border-border-normal'
        )}
      >
        <div className="flex flex-col text-base gap-2">
          <div className="text-sm">Distribution Bank Account</div>
          <div className="text-base font-medium">
            Manage your distribution settings
          </div>
          <Link
            to={`/dashboard/transactions?page=1&io_uuid=${data.io_uuid}`}
            className="text-sm text-content-light underline"
          >
            View all transactions
          </Link>
        </div>
        <div className="h-[36px] [&>button]:h-full [&>button]:w-full">
          <Button
            variant="RMSecondary"
            onClick={() => nav('/dashboard/bank-accounts/distributions')}
          >
            Edit
          </Button>
        </div>
      </div>
      {shareRepurchaseData && (
        <div
          className={cn(
            'w-full px-6 py-4 min-h-[78px] flex flex-col gap-4 md:flex-row justify-between md:items-center group border border-border-normal rounded-b'
          )}
        >
          <div className="flex flex-col text-base gap-2">
            <div className="text-sm">Share Repurchase Request</div>
            <div className="text-base font-medium">
              Submit a Share Repurchase Request
            </div>
            {shareRepurchaseData.eligible_investments.some(
              (item) => !!item.has_active_share_repurchase_request
            ) && (
              <div className="text-sm text-content-light">
                You currently have an active share repurchase request. Please
                email{' '}
                <a
                  className="underline"
                  href="mailto:sharerepurchaserequests@realtymogul.com"
                >
                  sharerepurchaserequests@realtymogul.com
                </a>{' '}
                for any updates.
              </div>
            )}
          </div>
          <div className="h-[36px] [&>button]:h-full [&>button]:w-full">
            <Dialog
              open={showDialog}
              onOpenChange={() => {
                reset({
                  uuid: 'all',
                  share_amount: 'twenty-five',
                  other_amount: '',
                })
                setShowPending(false)
                setShowDialog((prev) => !prev)
              }}
            >
              <DialogTrigger asChild>
                <Button variant="RMSecondary">Submit Request</Button>
              </DialogTrigger>
              <DialogContent
                aria-describedby={undefined}
                className="max-w-[602px] p-10 max-h-screen overflow-auto block break-words"
              >
                <DialogTitle className="text-content-black text-left font-bold text-2xl mb-6">
                  Share Repurchase Request
                </DialogTitle>
                {!showPending ? (
                  <>
                    {shareRepurchaseData.count > 0 ? (
                      <form onSubmit={onSubmit} aria-describedby="">
                        <p className="mb-4 text-16">
                          Please note you are able to request repurchase of 25%
                          of your shares on a quarterly basis after your one
                          year hold period on your investment. Dependent on the
                          length of time you have held your shares, the
                          effective repurchase rate may vary. We intend to{' '}
                          <InfoTooltip
                            className="underline"
                            target="limit the number of shares"
                          >
                            While we designed our share repurchase program to
                            allow shareholders to request share repurchases on a
                            quarterly basis (subject to the holding period), we
                            need to impose limitations on the total amount of
                            net repurchase per quarter in order to maintain
                            sufficient sources of liquidity to satisfy share
                            repurchase requests without impacting our ability to
                            invest in commercial real estate assets and maximize
                            investor returns.
                          </InfoTooltip>{' '}
                          to be repurchased during any calendar year to 5.0% of
                          the weighted average number of common shares
                          outstanding during the prior calendar year (or 1.25%
                          per quarter, with excess capacity carried over to
                          later quarters in the calendar year). In the event
                          that we do not have sufficient funds available to
                          repurchase all of the common shares for which share
                          repurchase requests have been submitted in any
                          quarter, such pending requests will be honored on a
                          pro rata basis.
                        </p>
                        <p className="mb-8">
                          If you've already made a repurchase request, please
                          email{' '}
                          <a
                            href="mailto:sharerepurchaserequests@realtymogul.com"
                            className="underline"
                          >
                            sharerepurchaserequests@realtymogul.com
                          </a>{' '}
                          for any updates.
                        </p>
                        <p className="mb-1 text-base">Investment</p>
                        <div className="font-medium mb-8">{data.name}</div>
                        <p className="mb-1 text-base">Ownership Type</p>
                        <Filter
                          name="ownership_types"
                          data={shareRepurchaseData.eligible_investments.map(
                            (item) => {
                              return {
                                name: item.investment_account_name,
                                uuid: item.investment_account_uuid,
                              }
                            }
                          )}
                          updateCallback={handleFilterUpdate}
                          defaultValue="all"
                          className="w-full overflow-hidden max-w-[calc(100vw-82px)] md:max-w-unset mb-8"
                        />
                        {watchUuid !== 'all' && (
                          <>
                            {hasEligibleShares ||
                            isPostShareRepurchaseMutateLoading ? (
                              <>
                                <p className="mb-2 text-base">
                                  Eligible Shares
                                </p>
                                <div className="font-medium mb-8">
                                  {
                                    shareRepurchaseData.eligible_investments.find(
                                      (item) =>
                                        item.investment_account_uuid ===
                                        watchUuid
                                    )?.eligible_shares
                                  }
                                </div>
                                <Controller
                                  name="share_amount"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="mb-6 flex flex-col gap-px first:bg-red-50">
                                      <Radio
                                        {...field}
                                        value="twenty-five"
                                        label="25% - one time repurchase of shares"
                                        checked={field.value === 'twenty-five'}
                                      />
                                      <Radio
                                        {...field}
                                        value="one-hundred"
                                        label="100% - recurring quarterly repurchase of shares"
                                      />
                                      <Radio
                                        {...field}
                                        value="other"
                                        label="Other"
                                      />
                                    </div>
                                  )}
                                />
                                {watchOther && (
                                  <>
                                    <p className="text-base mb-2">
                                      Other Amount
                                    </p>
                                    <Controller
                                      name="other_amount"
                                      control={control}
                                      rules={{
                                        required: 'This field is required.',
                                      }}
                                      render={({
                                        field,
                                        fieldState: { error },
                                      }) => (
                                        <>
                                          <NumericFormat
                                            className={`mb-2 h-[42px] w-full rounded border focus:outline-none focus:ring-1 focus:border-primary ${
                                              error
                                                ? 'border-alert'
                                                : 'border-input'
                                            } px-3 py-1.5 text-16`}
                                            suffix="%"
                                            allowNegative={true}
                                            placeholder="10%"
                                            isAllowed={(values) => {
                                              console.log(values)
                                              const { floatValue } = values
                                              return (
                                                !floatValue || floatValue <= 100
                                              )
                                            }}
                                            data-cy="other-share-amount"
                                            {...field}
                                          />
                                          {error && (
                                            <p className="text-alert text-sm">
                                              {error.message}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              <div className="mb-6">
                                <span className="font-bold">
                                  You have no eligible shares available for
                                  repurchase.
                                </span>{' '}
                                Shares held for one year may be eligible for
                                repurchase. Please note that repurchase requests
                                must be made 30 days prior to the end of the
                                applicable quarter.
                              </div>
                            )}
                          </>
                        )}
                      </form>
                    ) : (
                      <p className="text-16">
                        <span className="font-medium">
                          You have no eligible shares available for repurchase
                          for this investment.
                        </span>{' '}
                        Shares held for one year may be eligible for repurchase.
                        Please note that repurchase requests must be made 30
                        days prior to the end of the applicable quarter. If you
                        have any questions regarding your eligibility, feel free
                        to contact us at{' '}
                        <a
                          href="mailto:sharerepurchaserequests@realtymogul.com"
                          className="underline"
                        >
                          sharerepurchaserequests@realtymogul.com
                        </a>
                        .
                      </p>
                    )}
                  </>
                ) : (
                  <div className="mb-8 text-16">
                    <p className="mb-4">
                      <span className="font-bold">
                        The current status of your request is pending.
                      </span>{' '}
                      Your repurchase request requires a signature from all
                      authorized parties to be completed. Within 3 business
                      days, we will email you a Docusign Repurchase Package for
                      you to complete.
                    </p>
                    <p className="mb-4">
                      Share repurchase requests must be made 30 days prior to
                      the end of the applicable quarter. Within 45 days of the
                      end of the applicable quarter, you will be notified by
                      email if a partial or full repurchase of your requested
                      shares has been approved and processed.
                    </p>
                    <p>
                      If you have questions regarding the Share Repurchase
                      Program, please email{' '}
                      <a
                        href="mailto:sharerepurchaserequests@realtymogul.com"
                        className="underline"
                      >
                        sharerepurchaserequests@realtymogul.com
                      </a>
                      .
                    </p>
                  </div>
                )}
                <div className="mt-2 flex gap-4 [&>button]:w-fit">
                  {!showPending ? (
                    <>
                      {shareRepurchaseData.count > 0 ? (
                        <>
                          {hasEligibleShares || watchUuid === 'all' ? (
                            <Button
                              variant="RM"
                              size="RM"
                              onClick={onSubmit}
                              disabled={watchUuid === 'all'}
                            >
                              Submit
                            </Button>
                          ) : (
                            <DialogClose asChild>
                              <Button variant="RM" size="RM">
                                {isPostShareRepurchaseMutateLoading
                                  ? 'Submitting...'
                                  : 'Close'}
                              </Button>
                            </DialogClose>
                          )}

                          <DialogClose asChild>
                            <Button variant="RMSecondary" size="RMSecondary">
                              Cancel
                            </Button>
                          </DialogClose>
                        </>
                      ) : (
                        <DialogClose asChild>
                          <Button variant="RM" size="RM">
                            Close
                          </Button>
                        </DialogClose>
                      )}
                    </>
                  ) : (
                    <DialogClose asChild>
                      <Button variant="RM" size="RM">
                        Close
                      </Button>
                    </DialogClose>
                  )}
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div
      className={cn(
        'w-full px-6 py-4 min-h-[78px] flex flex-col md:flex-row gap-4 justify-between md:items-center group border border-border-normal rounded'
      )}
    >
      <div className="flex flex-col text-base gap-2">
        <div className="text-sm">Distribution Bank Account</div>
        <div className="text-base font-medium">
          Manage your distribution settings
        </div>
        <Link
          to={`/dashboard/transactions?page=1&io_uuid=${data.io_uuid}`}
          className="text-sm text-content-light underline"
        >
          View all transactions
        </Link>
      </div>
      <div className="h-[36px] [&>button]:h-full [&>button]:w-full">
        <Button
          variant="RMSecondary"
          onClick={() => nav('/dashboard/bank-accounts/distributions')}
        >
          Edit
        </Button>
      </div>
    </div>
  )
}
