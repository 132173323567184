import { FALLBACK_RM_IMAGE, formatCurrency } from '@/utils/helpers'
import { InvestmentDataTypes } from '../api/schema'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { PledgedInvestmentMenu } from './edit-menu-pledged'

const PledgedBadge = ({ investment }: { investment: InvestmentDataTypes }) => (
  <div className="flex flex-col md:min-w-[170px] justify-start items-center">
    {investment.route !== 'pledgecomplete' &&
      investment.route !== '1031submitted' && (
        <Badge variant="incomplete">Incomplete</Badge>
      )}
    {investment.route === 'pledgecomplete' &&
      investment.investment?.investflow_state?.eav === 'required' && (
        <Badge variant="incomplete">Submit Accreditation</Badge>
      )}
    {(investment.route === 'pledgecomplete' ||
      investment.route === '1031submitted') &&
      investment.investment?.investflow_state?.eav !== 'required' && (
        <Badge variant="active">Pledge Received</Badge>
      )}
  </div>
)

const PledgedButton = ({ investment }: { investment: InvestmentDataTypes }) => (
  <div className="flex flex-grow text-base gap-4 md:gap-2 md:w-[25%] flex-col justify-start text-center">
    {((investment.route !== 'pledgecomplete' &&
      investment.route !== '1031submitted') ||
      (investment.route === 'pledgecomplete' &&
        investment.investment?.investflow_state?.eav === 'required')) && (
      <a href={`/invest/${investment.io_uuid}`}>
        <Button className="w-full" variant="RM" size="RM">
          Continue <i className="ml-2 fa-regular fa-angle-right" />
        </Button>
      </a>
    )}
    {investment.route === 'pledgecomplete' &&
      investment.investment?.investflow_state?.eav === 'required' && (
        <div>
          <span className="font-medium text-sm">Information needed - </span>this
          investment requires proof of accreditation.
        </div>
      )}
    {investment.route === '1031submitted' ||
      (investment.route === 'pledgecomplete' &&
        investment.investment?.investflow_state?.eav === 'submitted' && (
          <div className="text-nowrap text-center py-2">
            No action required.
          </div>
        ))}
  </div>
)

export const PledgedInvestmentCard = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => {
  return (
    <div className="w-full flex flex-wrap md:flex-nowrap md:gap-8 overflow-hidden md:h-[106px]">
      <div className="w-full h-[89px] md:h-[106px] md:w-[180px]">
        <img
          className="w-full h-full object-cover md:object-fill"
          src={investment.image ?? FALLBACK_RM_IMAGE}
        />
      </div>
      <div className="w-[85%] hidden md:flex gap-6 p-4 md:px-0">
        <div className="flex w-[30%] flex-col h-full gap-1 justify-center">
          <div>
            <p className="text-[17px] font-medium line-clamp-2">
              {investment.name}
            </p>
          </div>
          <div className="text-16">
            <p className="line-clamp-1">{investment.subhead}</p>
          </div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        <div className="flex w-[20%] text-16 font-medium gap-2 flex-col justify-start text-right">
          {investment.investment_value && (
            <span>{formatCurrency(investment.investment_value ?? '')}</span>
          )}
        </div>
        <PledgedBadge investment={investment} />
        <PledgedButton investment={investment} />
        <div onClick={(e) => e.stopPropagation()} className="-mt-3">
          <PledgedInvestmentMenu
            id={investment.io_drupal_id.toString()}
            investment_uuid={investment.investment?.uuid ?? ''}
          />
        </div>
      </div>
      <div className="md:hidden w-full p-4 flex flex-col gap-4">
        <div className="w-full flex justify-between">
          <PledgedBadge investment={investment} />
          <PledgedInvestmentMenu
            id={investment.io_drupal_id.toString()}
            investment_uuid={investment.investment?.uuid ?? ''}
          />
        </div>
        <div className="w-full flex flex-col gap-[2px]">
          <div>
            <p className="text-[17px] font-medium line-clamp-2">
              {investment.name}
            </p>
          </div>
          <div className="text-16">{investment.subhead}</div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        {investment.investment_value && (
          <div className="w-full flex justify-between text-base">
            <div>Investment Amount</div>
            <div className="font-medium">
              <span>{formatCurrency(investment.investment_value ?? '')}</span>
            </div>
          </div>
        )}
      </div>
      {(investment.route === 'pledgecomplete' ||
        investment.route === '1031submitted') &&
      investment.investment?.investflow_state?.eav !== 'required' ? null : (
        <div className="md:hidden w-full p-4 flex flex-col justify-center border-t border-border-normal">
          <PledgedButton investment={investment} />
        </div>
      )}
    </div>
  )
}
