import { Controller, useForm } from 'react-hook-form'

import { useToast } from '@/hooks/use-toast'
import { changeEmail } from '../api/change-email'
import { useEmailStore } from '../api/use-email-store'

import { Button } from '@/components/ui/button'
import ErrorAlert from '@/components/ErrorAlert'
import TextInput from '@/components/TextInput'

export const UpdateEmail = ({
  email = '',
  callback,
  setScreen,
}: {
  email: string
  callback: (url: string) => void
  setScreen: (screen: string) => void
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      email,
    },
  })

  const updateEmail = useEmailStore((state) => state.updateEmail)

  const { toast } = useToast()

  const onFormSubmit = handleSubmit((data) => {
    if (!data) return
    changeEmail(data.email)
      .then(() => {
        toast({
          variant: 'success',
          description: 'Verification code sent',
          position: 'top',
        })
        updateEmail(data.email)
        callback('/dashboard/profile/email/verify')
      })
      .catch((err) => {
        console.error(err.code)
        toast({
          variant: 'error',
          title: 'Error',
          description: err.message,
        })
      })
  })

  return (
    <div className="mt-10">
      <div className="max-w-[350px]">
        <form
          onSubmit={onFormSubmit}
          autoComplete="on"
          className="w-full mb-10"
        >
          {Object.keys(errors).length > 0 && (
            <ErrorAlert text="Please fix the error(s) below:" />
          )}
          <div className="mb-10">
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address',
                },
              }}
              render={({ field, fieldState }) => (
                <TextInput
                  label="Email Address"
                  errorMessage={fieldState.error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <Button
              variant="RM"
              size="RM"
              disabled={!isDirty}
              onClick={onFormSubmit}
            >
              Update
            </Button>
            <Button
              variant="RMSecondary"
              size="RMSecondary"
              onClick={(e) => {
                e.preventDefault()
                setScreen('')
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
