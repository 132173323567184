import { useEffect, useState } from 'react'
import { z } from 'zod'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select-filter'
import { mapParams } from '../../shared/map-params'
import { cn } from '@/utils/utils'

const filterSchema = z.object({
  name: z.string(),
  data: z.array(
    z.union([
      z.object({ name: z.string(), uuid: z.string() }),
      z.enum([
        'purchase',
        'reinvested_distribution',
        'auto_investment',
        'sale',
        'unknown',
        'transfer_in',
        'transfer_out',
        'distribution_cash_pending',
        'distribution_cash_paid',
      ]),
    ])
  ),
  updateCallback: z.function().args(z.string(), z.string()).returns(z.void()),
  defaultValue: z.string().nullable(),
})

type FilterTypes = z.infer<typeof filterSchema>

export function Filter({
  name,
  data,
  defaultValue,
  updateCallback,
}: FilterTypes) {
  const [value, setValue] = useState('all')

  const handleFilterSelect = (newValue: string) => {
    setValue(newValue)
    updateCallback(name, newValue)
  }

  useEffect(() => {
    setValue(defaultValue || 'all')
  }, [defaultValue])

  const mapFilterName = (machine_name: string) => {
    switch (machine_name.toLowerCase()) {
      case 'investment_account_filters':
        return 'Investment Accounts'
      case 'io_filters':
        return 'Investments'
      case 'transaction_types':
        return 'Transactions'
    }
  }

  return (
    data && (
      <Select value={value} onValueChange={handleFilterSelect}>
        <SelectTrigger
          className={cn(
            'w-full md:w-fit h-[38px]',
            value !== 'all' && 'border-primary font-medium'
          )}
        >
          <SelectValue
            className="overflow-hidden"
            placeholder={`All ${name}`}
          />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">
            <span className="capitalize">{`All ${mapFilterName(name)}`}</span>
          </SelectItem>
          {data.map((item) => (
            <SelectItem
              value={
                typeof item === 'object' && 'uuid' in item
                  ? item.uuid.toString()
                  : item.toString()
              }
              key={
                typeof item === 'object' && 'uuid' in item
                  ? item.uuid.toString()
                  : item.toString()
              }
            >
              {typeof item === 'object' && 'name' in item
                ? item.name.toString()
                : mapParams(item.toString())}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  )
}
