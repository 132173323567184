import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useIsqDataState, useMutateISQ } from '../_isqDataState'
import { cancelInvestment, getIO } from '../../shared/_api'

import ISQForm from '../../shared/Forms/ISQForm'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import FormFooter from '../../shared/FormFooter'
import ServerError from '../../../components/ErrorAlert/ServerError'
import { track } from '../../../utils/analytics'
import Loader from '../../../components/Loader'
import { useRoute } from '../../../rules/find-route'

const AboutYouScreen = () => {
  // const [backLocation, setBackLocation] = useState('')
  const [serverError, setServerError] = useState('')
  const { data: investData } = useInvestDataState()
  const { data: isqData } = useIsqDataState()
  const { isLoading, mutateAsync: mutateISQ } = useMutateISQ()
  const nav = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const { findRoute } = useRoute()

  const handleNext = useCallback(
    async (data: any, shouldCancel: boolean) => {
      setServerError('')
      if (!params.step) {
        nav(`/invest/${investData.opportunity.id}/aboutyou/1`)
        return
      } else {
        const responses = Object.keys(data).map((key) => ({
          question_uuid: key,
          value: data[key].replaceAll(',', '').replaceAll('$', ''),
        }))
        mutateISQ({ responses })
          .then(() => {
            if (shouldCancel) {
              cancelInvestment(investData.investment?.id).then(() => {
                track('Cancel Investment')
                window.location.href = 'https://www.realtymogul.com'
                return
              })
            } else if (Number(params.step) === isqData?.steps.length) {
              getIO(investData?.investment.id).then((res) => {
                findRoute({
                  ...res,
                }).then((route) => {
                  nav(`/invest/${investData.opportunity.id}/${route.type}`)
                })
              })
              return
            } else {
              nav(
                `/invest/${investData?.investment?.id}/aboutyou/${
                  Number(params.step) + 1
                }`
              )
            }
          })
          .catch((error) => {
            console.error(error.message)
            setServerError(error.message)
          })
      }
    },
    [params, investData, isqData, nav, mutateISQ]
  )

  return (
    <div className="p-6 xl:p-0">
      <Stepper
        currentSection={1}
        currentStep={
          (investData.opportunity.io_type === 'reit' ? 2 : 1) +
          (Number(params.step) ?? 0)
        }
        totalStepCount={investData.opportunity.io_type === 'reit' ? 10 : 9}
      />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            {t('aboutYou:header')}
          </h1>
          {isqData ? (
            <>
              <p className="mb-6 text-base text-content-light">
                {!params.step
                  ? `${isqData.steps.length} questions`
                  : `Question ${params.step} of ${isqData.steps.length}`}
              </p>
              {!params.step ? (
                <>
                  <p className="mb-6 text-16 text-content-black">
                    Next, we’ll ask you {isqData.steps.length} questions about
                    your investing experience and preferences to build your
                    investing profile.
                  </p>
                  <p className="mb-6 text-16 text-content-black">
                    Continue below when ready.
                  </p>
                  <FormFooter
                    submitFunc={() =>
                      nav(`/invest/${investData.opportunity.id}/aboutyou/1`)
                    }
                  />
                </>
              ) : (
                <div>
                  {params.step === '1' && (
                    <p className="mb-6 text-16 text-content-black">
                      {t('aboutYou:aboutYouIntro')}
                    </p>
                  )}
                  {isqData.steps.map((step: any, index: number) => (
                    <ISQForm
                      key={index}
                      data={step}
                      step={index}
                      submitFunc={handleNext}
                      showStep={Number(params.step) === index + 1}
                      disabled={isLoading}
                    />
                  ))}
                  {serverError && (
                    <ServerError
                      serverError={serverError}
                      id={investData.investment?.id}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default AboutYouScreen
